<template>
    <div>
         <!--filter bar -->
        <div-filter
         v-if="config.name!='shippingsHistories' && config.name!='stockHistories' && config.name!='statusHistories' && config.name!='sourcingsHistories'  && DivFilter" 
           :name="config.name" 
           :config="config" 
           :filters="filters" 
           :currentUser="currentUser" :idWharhouse="idWharhouse"  @reset="reset"  @filtrer="filtrer" @search="search" @searchSellers="searchSellers" @searchCouriers="searchCouriers" :loading="loading">
        </div-filter>
        <!-- end filter bar -->
        <div :class="config.class">
           <div class="bg-white w-full rounded-lg shadow">
            <!-- <div v-if="(config.name=='expeditions' && (currentUser.type == 'Admin' || currentUser.type == 'CEO')) || config.name=='products'">
               <div @click="openAnalytics = !openAnalytics" class="py-4 cursor-pointer bg-grey-lighter hover:bg-grey-light text-indigo-darker rounded rounded-t-none text-center uppercase font-bold flex items-center justify-center text-green-600"><span class="block border border-gray-400 w-1/3 mr-2"></span>
               <button class="border border-teal-500 text-teal-500 block rounded-sm font-bold py-4 px-6 mr-2 flex items-center hover:bg-teal-500 hover:text-white">
                  {{$t('Show analytics')}}  <i class="material-icons text-green-600 ml-3">timeline</i></button><span class="block border border-gray-400 w-1/3 ml-2"></span></div>
               <chart-expeditions v-if="openAnalytics && config.name=='expeditions'"  :currentUser="currentUser"/>
               <chart-products v-if="openAnalytics && config.name=='products'"  :currentUser="currentUser"/>
            </div> -->
                <div v-if="DivFilter" class="h-20 py-3 flex items-center border-gray-200 p-6">
                    <div class="flex w-1/3">
                        <i v-if="config.icone" class="material-icons p-3 text-green-400">{{ config.icone }}</i>
                        <span class="mx-4 text-2xl font-bold text-green-600">{{ $t(config.title) }}</span>
                        <!-- <span v-if="config.name=='statusHistories' && emailCurrentCall" class="mx-4 text-2xl semi-bold text-black-500">({{ emailCurrentCall }})</span> -->
                        <div v-on-clickaway="awayLimit" v-if="config.name=='shippings' || config.name=='orders' || config.name=='payments' || config.name=='expeditions' || config.name=='products' || config.name=='charges'">
                            <button @click="openLimit = !openLimit" class="vs-con-dropdown parent-dropdown cursor-pointer">
                            <div class="p-2 shadow rounded-lg  cursor-pointer flex items-end justify-center text-sm font-medium">
                              <span class="mr-2">{{paginate.currentpage||1}} - {{data.length}} / {{paginate.total||data.length}} </span>
                                <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down h-4 w-4"><polyline points="6 9 12 15 18 9"></polyline></svg></span></div>
                            </button>
                            <div class="relative">
                                <div v-if="openLimit"  class="absolute focus:ring-2 focus:ring-blue-600 right-0 dropdown-action-limit w-auto mt-3 z-10 origin-top-right rounded-md shadow-lg">
                                    <div class="bg-white rounded-md shadow dark-mode:bg-gray-800 dropdown-limit">
                                        <a @click="Changelimit(50)" class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">50</a>  
                                        <a @click="Changelimit(100)" class="block cursor-pointer px-2  text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">100</a>
                                        <a v-if="currentUser.type !=='Seller'" @click="Changelimit(200)" class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">200</a>
                                         <a v-if="currentUser.type !=='Seller'" @click="Changelimit(300)" class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">300</a> 
                                         <a v-if="currentUser.type !=='Seller'" @click="Changelimit(400)" class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">400</a> 
                                         <!-- <a v-if="currentUser.type !=='Seller'" @click="Changelimit(500)" class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">500</a>  -->
                                    </div>
                                    <div class="vs-dropdown--limit--after"></div>
                                </div>
                            </div>
                       </div>  
                    </div>
                 
                    <div  class="w-4/6 flex relative  justify-end space-x-4">
                       <div v-if="config.options.actions.isActive && (!config.options.actions.method || config.options.actions.link) && getAccess('create',null,null,true)" class="w-1/2 flex justify-end space-x-4">
                           <router-link v-for="(item, index) in config.options.actions.buttons" :key="index" v-if="item.link" :to="item.link" class="rounded flex focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"><i class="material-icons mr-2">{{ item.icon }}</i> {{ $t(item.text) }}</router-link>
                       </div>
                      
                       
                        
                      <div v-if="config.name=='shippings' && getAccess('create',null,null,true) && getAccess('update',null,null,true) && currentUser.type !=='Seller'" @click="OpenClosePopupModal('open','dauchette','dauchete')" class="bg-dauchete ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">
                         <svg class="fill-current mr-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 19.586l-2.832-2.832c.524-.79.832-1.735.832-2.754 0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5c1.019 0 1.964-.308 2.754-.832l2.832 2.832 1.414-1.414zm-7-2.586c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm7-2v-12h1v12h-1zm-4-7.315v-4.685h2v6.11c-.574-.586-1.251-1.068-2-1.425zm-10-4.685v12h-1v-12h1zm-5 0h2v12h-2v-12zm-4 0h2v12h-2v-12zm17 4.08c-.328-.047-.66-.08-1-.08v-4h1v4.08zm-6 2.03v-6.11h3v4.295c-1.152.344-2.18.977-3 1.815z"/></svg>
                           Douchette
                      </div>

                      <div v-if="config.name=='shippings' && getAccess('create',null,null,true) && getAccess('update',null,null,true) && currentUser.type !=='Seller'" @click="OpenClosePopupModal('open','Refund')" class="bg-refund ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">
                        <img style="height: 24px;" class="material-icons mr-1" src="https://img.icons8.com/ios/100/FFFFFF/refund-2.png"/>  Remboursement
                      </div>
                      <div v-if="config.name=='shippings' && (currentUser.type =='CEO' || currentUser.role.name === 'Tracking agent')" @click="OpenClosePopupModal('open','dauchette','previous')" class="bg-previous ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">
                        <svg class="fill-current mr-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.222 18.917c5.666-5.905-.629-10.828-5.011-7.706l1.789 1.789h-6v-6l1.832 1.832c7.846-6.07 16.212 4.479 7.39 10.085z"/></svg> Previous status
                      </div>
                      <div v-if="((config.name=='orders' || config.name=='shippings') && currentUser.type !='Administrateur') || ((config.name=='payments' || config.name=='expeditions' || config.name=='products' || config.name=='charges') && getAccess('create',null,null,true))" @click="downloadExcel" class="ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300  loidingconfirm relative">
                            <span v-if="config.name !== 'payments' && config.name !== 'expeditions' && config.name !== 'products' && config.name !== 'charges'"> <i class="material-icons align-bottom">file_download</i> Excel </span>
                            <span v-else><loading :active.sync="dataExcel.loading" :is-full-page="fullPage"></loading> <i style="vertical-align: sub;"  class="align-sub material-icons">file_download</i>  <span v-if="dataExcel.loading" class="inline-block loading-excel"> Exporting ({{timer}}/ {{paginate.total}}) <span>.</span><span>.</span><span>.</span> </span><span v-else>Excel</span></span> 
                        </div>
                       <!-- <div v-if="config.name=='shippings' && (currentUser.fullName =='system')" @click="OpenClosePopupModal('open','dauchette','previous')" class="ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">
                        <svg class="fill-current mr-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.222 18.917c5.666-5.905-.629-10.828-5.011-7.706l1.789 1.789h-6v-6l1.832 1.832c7.846-6.07 16.212 4.479 7.39 10.085z"/></svg> Previous status
                      </div> -->
                      <div v-on-clickaway="away" v-if="config.options.actions.isActive && config.options.actions.method && (config.name=='shippings' ||  config.name=='orders' || config.name=='payments' || config.name=='products') && getAccessLinks(null)">
                        <button @click="open = !open" class="vs-con-dropdown parent-dropdown cursor-pointer">
                         <div class="p-3 shadow rounded-lg  cursor-pointer flex items-end justify-center text-lg font-medium"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical h-4 w-4"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg></span></div>
                        </button>
                         <div v-if="open" x-transition:enter="transition ease-out duration-100" x-transition:enter-start="transform opacity-0 scale-95" x-transition:enter-end="transform opacity-100 scale-100" x-transition:leave="transition ease-in duration-75" x-transition:leave-start="transform opacity-100 scale-100" x-transition:leave-end="transform opacity-0 scale-95" class="absolute focus:ring-2 focus:ring-green-600 right-0 dropdown-action  w-full mt-5 z-10 origin-top-right rounded-md shadow-lg">
                            <div class="bg-white rounded-md shadow dark-mode:bg-gray-800">
                               <a  v-for="(item, index) in config.options.actions.buttons" :key="index" v-show="getAccessLinks(item.action) && !item.link" @click="EmitMethodAction(item.action)" class="block cursor-pointer px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline relative loidingconfirm" :class="loadingpdf?'noselect':''" :disabled="loadingpdf"><loading v-if="loadingpdf" :active.sync="loadingpdf" :is-full-page="fullPage"></loading><i class="material-icons align-bottom mr-2">{{ item.icon }}</i> {{ $t(item.text) }}</a>   
                            </div>
                         </div>
                       </div>  
                    </div>
                    <div v-if="config.options.actions.isActive && config.options.actions.method && config.name=='statusHistories'" class="w-1/2 flex justify-end space-x-4">
                       <button @click="downloadAnalytics" v-for="(item, index) in config.options.actions.buttons" :key="index"  class="rounded flex focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"><i class="material-icons mr-2">{{ item.icon }}</i> {{ $t(item.text) }}</button>
                    </div>
                </div>
                 <div v-if="isFilterCourier && config.name=='shippings'"  class="flex justify-center border-gray-200">
                      <div  class="w-5/12 bg-green-100 border-t-4 border-green-600 rounded-b text-teal-darkest px-4 py-3 shadow-md my-2" role="alert">
                        <div class="flex">
                            <i class="large material-icons mr-5">account_balance_wallet</i>   
                            <div>
                            <p v-if="CourierInfo.courier" class="font-bold">{{$t('courier')}} : <span>{{CourierInfo.courier}}</span></p>
                            <p class="font-bold">{{$t('total_orders')}} <span>{{CourierInfo.count}}</span></p>
                            <p class="font-bold">{{$t('total')}} : <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup> {{CourierInfo.total}}  </p>
                            </div>  
                        </div>
                    </div>
                </div>
              <div style="overflow-x:auto;">  
                <table id="loading" class="min-w-full w-full"> 
                    <thead class="relative">
                        <tr class="bg-gray-400 text-gray-600 uppercase text-sm leading-normal">
                            <th v-for="(item, key) in config.columns" :key="key" :class="item.class" class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                                <label v-if="item.type && config.name!='products'" class="text-lg text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                                    <input type="checkbox" @click="selectAllCheckbox($event);" class="form-checkbox checkallbbox focus:outline-none focus:shadow-outline" >
                                </label>
                                <span v-else>
                                      {{ $t(item.name)}}
                                </span>     
                            </th>
                            <th v-if="config.actions && config.actions.elements && config.actions.elements.length != 0 " class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{{ config.actions.name }}</th>
                        </tr>
                    </thead>
                     <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                    <tbody v-for="(item, index) in data" :key="index" :class="[config.name === 'products'?data[index].type:'',['orders','shippings','products'].includes(config.name) && (data[index].affiliate && data[index].affiliate.type || data[index].type && data[index].type === 'affiliate') ? 'affiliate-order' : '']">
                        <tr :class="[index%2 !=0 ?'bg-gray-100':'',config.name === 'warehouses'?(data[index].parent ? 'ware-parentt': 'ware-primary'):'',config.name === 'expeditions'?(data[index].type && data[index].type === 'transfer' ? 'exp-transfer': 'exp-expedition'):'']" class="border-b border-gray-200 hover:bg-gray-100">
                            <td v-for="(col, ind) in config.columns" :key="ind" class="px-5 py-2 whitespace-no-wrap border-b border-gray-300">
                              <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                                <div v-for="(element,indElm) in col.elements" :key="indElm" >
                                    <div v-for="(itemst,itemIndex) in element.items" :key="itemIndex" :class="itemst.name=='status'?'text-center':''">
                                        <div v-if="!itemst.elements"> <!-- Status -->
                                            <div v-if="(itemst.name == 'status' || itemst.name == 'previousStatus') && !itemst.selects">
                                                    <div v-if="!itemst.data"> <!-- status expedition -->
                                                      <div v-if="config.name!='shippingsHistories'"> 
                                                          <button v-if="typeof itemst.options === 'function'" @click="ShowPopUpInformation(index,data[index][itemst.name],item)" :class="itemst.class" :style="itemst.options(data[index][itemst.name])">{{ data[index][itemst.name] }}</button> 
                                                          <div class="flex call-post" v-if="config.name==='shippings' && currentUser.type != 'Seller' && data[index].orderObjet && data[index].orderObjet.teleConsultantConfirm && data[index].orderObjet.teleConsultantConfirm.fullName">
                                                            <svg class="fill-current mr-2" width="16" height="16" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path d="M3.848 19h-.848c-.796 0-1.559-.316-2.121-.879-.563-.562-.879-1.325-.879-2.121v-3c0-7.175 5.377-13 12-13s12 5.825 12 13v3c0 .796-.316 1.559-.879 2.121-.562.563-1.325.879-2.121.879h-.848c-2.69 4.633-6.904 5-8.152 5-1.248 0-5.462-.367-8.152-5zm16.152-5.876c-.601.236-1.269-.18-1.269-.797 0-.304-.022-.61-.053-.915-1.761-.254-3.618-1.926-3.699-3.723-1.315 2.005-4.525 4.17-7.044 4.17 1.086-.699 1.839-2.773 1.903-3.508-.581 1.092-2.898 3.136-4.551 3.487l-.018.489c0 .619-.669 1.032-1.269.797v3.771c.287.256.632.464 1.041.594.225.072.412.224.521.424 2.206 4.046 5.426 4.087 6.438 4.087.929 0 3.719-.035 5.877-3.169-1.071.433-2.265.604-3.759.653-.37.6-1.18 1.016-2.118 1.016-1.288 0-2.333-.784-2.333-1.75s1.045-1.75 2.333-1.75c.933 0 1.738.411 2.112 1.005 1.9-.026 4.336-.334 5.888-2.645v-2.236zm-11-.624c.686 0 1.243.672 1.243 1.5s-.557 1.5-1.243 1.5-1.243-.672-1.243-1.5.557-1.5 1.243-1.5zm6 0c.686 0 1.243.672 1.243 1.5s-.557 1.5-1.243 1.5-1.243-.672-1.243-1.5.557-1.5 1.243-1.5zm5.478-1.5h1.357c-.856-5.118-4.937-9-9.835-9-4.898 0-8.979 3.882-9.835 9h1.357c.52-4.023 3.411-7.722 8.478-7.722s7.958 3.699 8.478 7.722z"/></svg>
                                                            <span>{{ data[index].orderObjet.teleConsultantConfirm.fullName }}</span>
                                                          </div>
                                                          <div v-if="config.name==='shippings' && data[index][itemst.name] && (data[index][itemst.name]==='paid' || data[index][itemst.name]==='processed')">
                                                            <span :class="data[index][itemst.name]==='paid'?'label-paid':'label-accept'"  class="jvectormap-tip text-white">{{data[index][itemst.name]==='paid'?'PaidAt':'ProcessedAt'}} ({{formattedDate(data[index][itemst.name]==='paid'?data[index]['paidAt']:data[index]['updatedAt'],'yyyy-MM-DD')}})</span>
                                                          </div>
                                                          <div v-if="(config.name==='shippings' || config.name==='expeditions') && data[index][itemst.name] && data[index]['isProcessed']">
                                                             <span class="jvectormap-tip text-white label-accept">ProcessedAt ({{formattedDate(data[index]['isProcessed'],'yyyy-MM-DD')}})</span>
                                                          </div>
                                                          
                                                      </div>
                                                      <div v-else>
                                                          <button :class="itemst.class" :style="itemst.options(data[index][itemst.name])">{{ data[index][itemst.name] }}</button> 
                                                      </div>
                                                    </div>
                                                    <div v-else> <!-- status orders -->
                                                        <button :disabled="(!currentUser.type=='Admin' || !currentUser.type=='CEO' || !currentUser.type=='TeleConsultant' || !currentUser.type=='NRP')" @click="ShowPopUpInformation(index,null,item)"  :class="itemst.class" v-bind:style="{color: data[index][itemst.name]?data[index][itemst.name][itemst.champs.color]:'',borderColor: data[index][itemst.name]?data[index][itemst.name][itemst.champs.color]:''}">{{ data[index][itemst.name]?data[index][itemst.name][itemst.champs.name]:'' }}</button> 
                                                       
                                                        
                                                        <template v-if="config.name==='orders' && data[index][itemst.name] && data[index][itemst.name][itemst.champs.name]">
                                                            <span v-if="data[index]['sameOrderUnreached'] && data[index]['sameOrderUnreached'].length>0" class="flex items-center justify-center sous-s">
                                                                <svg class="fill-current mr-3" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path d="M18.48 22.926l-1.193.658c-6.979 3.621-19.082-17.494-12.279-21.484l1.145-.637 3.714 6.467-1.139.632c-2.067 1.245 2.76 9.707 4.879 8.545l1.162-.642 3.711 6.461zm-9.808-22.926l-1.68.975 3.714 6.466 1.681-.975-3.715-6.466zm8.613 14.997l-1.68.975 3.714 6.467 1.681-.975-3.715-6.467z"/></svg> <span>Unreached ({{ data[index]['unreachedBySize'] }})</span>
                                                                <span v-if="data[index][itemst.name][itemst.champs.name]==='Pending' && data[index]['lastUnreachedDate']"><svg  class="fill-current ml-2 align-sub inline-block"  xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path d="M12 12h3v2h-5v-6h2v4zm9-1h-1.07c-.251-2.006-1.218-3.792-2.647-5.093-1.283-1.166-1.228-2.247-1.856-5.907h-7.854c-.628 3.66-.573 4.741-1.856 5.907-1.67 1.52-2.717 3.701-2.717 6.124 0 2.424 1.048 4.606 2.72 6.126 1.28 1.164 1.232 2.267 1.853 5.843h7.854c.622-3.576.573-4.679 1.854-5.843 1.445-1.314 2.421-3.124 2.66-5.157h1.059v-2zm-9.5 7.5c-3.589 0-6.5-2.911-6.5-6.5s2.911-6.5 6.5-6.5 6.5 2.911 6.5 6.5-2.911 6.5-6.5 6.5z"/></svg> {{formattedDate(data[index]['lastUnreachedDate'],'yyyy-MM-DD HH:mm')}}</span>
                                                            </span>
                                                            <span v-if="data[index]['remind'] && (data[index][itemst.name][itemst.champs.name]==='Pending' || data[index][itemst.name][itemst.champs.name]==='OutOfStock')" class="flex items-center justify-center sous-s">
                                                               <svg class="fill-current mr-3" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z"/></svg> <span>Reminded (To: {{$moment(data[index]['remind']).format('yyyy-MM-DD HH:mm') }})</span>
                                                            </span>
                                                        </template>
                                                         <template v-if="config.name==='orders' && data[index][itemst.name] && data[index][itemst.name][itemst.champs.name]==='Cancelled'">
                                                            <span v-if="data[index]['validated_status']" class="jvectormap-tip text-white label-accept">Validated ({{formattedDate(data[index]['validated_status'],'yyyy-MM-DD')}})</span>
                                                            <span v-if="data[index]['comment']" class="jvectormap-tip text-white label-accept">{{ data[index]['comment'] }}</span>
                                                         </template>
                                                         <template v-if="config.name==='orders' && data[index][itemst.name] && data[index][itemst.name][itemst.champs.name]==='Orange money'">
                                                            <span v-if="data[index]['is_transfere']>1" class="jvectormap-tip text-white label-accept">À transférer plusieur fois ({{ data[index].is_transfere }})</span>
                                                         </template>
                                                        
                                                    </div>     
                                            </div>
                                            <div v-else-if="itemst.name=='type' && config.name!='stockHistories'">
                                                <span :style="itemst.options(data[index][itemst.name])">{{ data[index][itemst.name] }}</span>
                                            </div>    
                                            <div v-else :class="itemst.class">
                                                    <div v-if="itemst.array"><!--zones(number of cities type array)-->
                                                        {{ data[index][itemst.name].length }}
                                                    </div>
                                                     <div v-else-if="itemst.date"><!--filed type date-->
                                                        {{  formattedDate(data[index][itemst.name]) }}
                                                    </div>
                                                    <div v-else-if="itemst.history">
                                                        <span :class="data[index]['status']=='in'? 'text-green-600':'text-red-600'"><span v-if="data[index]['status']=='in'"> <i class="material-icons">arrow_drop_up</i></span><span v-else> <i class="material-icons">arrow_drop_down</i></span> {{ data[index][itemst.name] }}</span>
                                                    </div> 
                                                    <div v-else-if="itemst.type=='checkbox'">
                                                       <label  class="text-lg text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                                                           <input type="checkbox" :class="'rowCheckboxProduct-'+item._id" :status="item.status?item.status.name||item.status:''"  :courier="item.courier?item.courier._id||item.courier:''" :orderObjet="item.orderObjet?JSON.stringify(item.orderObjet):''"  :upsel="item.upsel?item.upsel:'false'" :order="item.order?item.order._id:''" :trackingNumber="item.trackingNumber||''" :sellerid="item.seller?item.seller._id||item.seller:''"  :statusid="item.status?item.status._id||item.status:''" :seller="(item.seller && item.seller.source)?item.seller.source:'nosource'" :id="item._id" @click="getRowDetail(item,item._id)" class="form-checkbox rowCheckbox focus:outline-none focus:shadow-outline">
                                                        </label>
                                                    </div> 
                                                    <div v-else> 
                                                        <div v-if="itemst.name=='country' && config.name=='warehouses'">
                                                               <img v-if="data[index].is_primary" :src="$f.getCountryCode(data[index][itemst.name])" class="h-4 w-5 blob inline-block mr-2" :alt="data[index][itemst.name]"> 
                                                            </div>
                                                        <div v-else-if="itemst.name=='country' || itemst.name=='country_to'" class="flex">
                                                            <span v-if="Array.isArray(data[index][itemst.name])" class="flex">
                                                                <img v-for="(countr, r) in data[index][itemst.name]" :key="r" :src="$f.getCountryCode(countr)" class="w-4 h-4 rounded-full mr-2"> 
                                                            </span>
                                                            <span v-else>{{$f.getCountryNameByCode(dataCountry,data[index][itemst.name])}} <img :src="$f.getCountryCode(data[index][itemst.name])" class="w-4 h-4 rounded-full ml-2 inline"> </span>  
                                                           
                                                        </div>
                                                        <div
                                                            v-else-if="itemst.name == 'address' || itemst.name == 'name' || itemst.name == 'source'"
                                                            :class="[itemst.name == 'name' && config.name == 'warehouses' && data[index]['parent'] ? 'parent-warehouse' : '']">

                                                            <div v-if="['products','affiliates'].includes(config.name) && itemst.name == 'name'" :class="config.name" class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 me-2">
                                                                <expandable-image class="expandable-img" :src="data[index].product?data[index].product.picture||'https://storeno.b-cdn.net/codinafrica/6-2023/1685627850049.png':data[index].picture||'https://storeno.b-cdn.net/codinafrica/6-2023/1685627850049.png'" alt="img-pr" />
                                                            </div>
                                                            <div class="flex-grow-1 text-reset font-semibold">
                                                                <span  v-if="config.name === 'affiliates'" class="mr-2 marque-top"  style="color: rgb(255, 255, 255); background-color: rgb(246, 90, 99);"> Top </span>
                                                                <span class="font-bold">  {{ data[index].product?data[index].product[itemst.name]:data[index][itemst.name] }} </span>
                                                                <small v-if="config.name === 'affiliates'" class="flex pt-1 items-center text-gray-700"><img src="https://img.icons8.com/color-glass/48/price-tag.png" class="inf-icon mt-0 me-3 tag-img"><b class="ml-1">{{ data[index].product?data[index].product.category:data[index].category }}</b></small> 
                                                                <span v-if="config.name === 'affiliates'" class="flex items-center">
                                                                <b class="text-gray-900"><img src="https://img.icons8.com/external-flaticons-flat-flat-icons/64/external-africa-vacation-planning-trip-abroad-flaticons-flat-flat-icons-3.png" class="inf-icon mt-0 me-3 africimg"></b>
                                                                <span v-for="(detialPr, r) in data[index].product?$f.removeCountryByTwoArray(data[index].product.details,currentUser.countries):$f.removeCountryByTwoArray(data[index].details,currentUser.countries)" :key="r">
                                                                    <img :src="'https://static.dwcdn.net/css/flag-icons/flags/4x3/' + detialPr.country.toLowerCase() + '.svg'" class="w-4 h-4 rounded-full ml-2">
                                                                </span>
                                                                
                                                            
                                                                </span>
                                                                <span v-if="config.name === 'affiliates'" class="flex items-center">
                                                                <span> <img src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-commission-influencer-marketing-flaticons-lineal-color-flat-icons-3.png" class="inf-icon mt-0 me-3 tag-img mr-1"></span>
                                                                <span class="font-bold"> 
                                                                    <span style="font-size: 19px;">≃</span>
                                                                    {{ data[index].product?data[index].product.details.length>0 && data[index].product.details[0].commission?(data[index].product.details[0].commission/currentSettings.info.currencyUSD).toFixed():0:data[index].details.length>0 && data[index].details[0].commission?(data[index].details[0].commission/currentSettings.info.currencyUSD).toFixed():0 }} <sup>$</sup>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            </div>
                                                            <div v-else>{{ exceptText(data[index][itemst.name]) }} </div>

                                                        </div>
                                                        <div v-else>
                                                             <!--Producs quatite-->
                                                             <div v-if="itemst.arrayD" class="text-center">
                                                                 <span v-if="itemst.name!='outOfStock' && itemst.name!='price' && itemst.name!='upsells'" class="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs">Total:  {{ data[index][itemst.name] }}</span>

                                                                 <div v-for="(war, i) in data[index][itemst.arrayD].filter(item => idWharhouse && item.warehouse && item.warehouse.country == idWharhouse.country)" :key="i"  :class="data[index][itemst.arrayD].filter(item => item.warehouse && item.warehouse.country == idWharhouse.country).length>1 && data[index][itemst.arrayD].filter(item => item.warehouse && item.warehouse.country == idWharhouse.country).length-1 != i && itemst.name!='outOfStock' ?'border-b border-gray-600 border-dashed':''" class="flex justify-center items-center py-1">
                                                                        <span v-if="itemst.name=='outOfStock'">
                                                                            <span  v-if="war.warehouse.is_primary"  :class="war.outOfStock?'status-inactive':'status-active'" class="statusP"><span>{{war.outOfStock?'Inactive':'Active'}}</span></span>
                                                                        </span>
                                                                        <span v-else-if="itemst.name=='price'">
                                                                            <span  v-if="war.warehouse.is_primary" :class="war[itemst.name]?'text-green-600 ':'text-red-600 '" class="statusP uppercase">{{war[itemst.name]}} <sup v-if="idWharhouse">{{idWharhouse.currency}}</sup></span>
                                                                        </span>
                                                                        <span v-else-if="itemst.name=='upsells'">
                                                                            <span  v-if="war.warehouse.is_primary" class="statusP">
                                                                                <span style="background-color:#fce7e7;color:rgba(237,94,94,1);padding:0.35em 0.65em;font-size:0.75em;font-weight:500" v-if="!war.upsells || (war.upsells && war.upsells.length === 0)">Non utilisé</span>
                                                                                <svg class="fill-current text-green-600" v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.334 11.96c-.713-.726-.872-1.829-.393-2.727.342-.64.366-1.401.064-2.062-.301-.66-.893-1.142-1.601-1.302-.991-.225-1.722-1.067-1.803-2.081-.059-.723-.451-1.378-1.062-1.77-.609-.393-1.367-.478-2.05-.229-.956.347-2.026.032-2.642-.776-.44-.576-1.124-.915-1.85-.915-.725 0-1.409.339-1.849.915-.613.809-1.683 1.124-2.639.777-.682-.248-1.44-.163-2.05.229-.61.392-1.003 1.047-1.061 1.77-.082 1.014-.812 1.857-1.803 2.081-.708.16-1.3.642-1.601 1.302s-.277 1.422.065 2.061c.479.897.32 2.001-.392 2.727-.509.517-.747 1.242-.644 1.96s.536 1.347 1.17 1.7c.888.495 1.352 1.51 1.144 2.505-.147.71.044 1.448.519 1.996.476.549 1.18.844 1.902.798 1.016-.063 1.953.54 2.317 1.489.259.678.82 1.195 1.517 1.399.695.204 1.447.072 2.031-.357.819-.603 1.936-.603 2.754 0 .584.43 1.336.562 2.031.357.697-.204 1.258-.722 1.518-1.399.363-.949 1.301-1.553 2.316-1.489.724.046 1.427-.249 1.902-.798.475-.548.667-1.286.519-1.996-.207-.995.256-2.01 1.145-2.505.633-.354 1.065-.982 1.169-1.7s-.135-1.443-.643-1.96zm-12.584 5.43l-4.5-4.364 1.857-1.857 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.642z"/></svg>
                                                                            </span>
                                                                        </span>
                                                                        <!-- <span class="flex box-flexx" v-else>
                                                                            <div class="mr-2"><img class="w-4 h-4 rounded-full" :src="idWharhouse.flag"></div>
                                                                            <span>{{ war.quantity[itemst.name] }}</span>
                                                                        </span> -->
                                                                        <template v-else>
                                                                            <template>
                                                                                <svg v-if="war.warehouse.is_primary" class="fill-current mr-2" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M12 6.453l9 8.375v9.172h-6v-6h-6v6h-6v-9.172l9-8.375zm12 5.695l-12-11.148-12 11.133 1.361 1.465 10.639-9.868 10.639 9.883 1.361-1.465z"/></svg> <img class="w-4 h-4 rounded-full" :src="war.warehouse.flag">  
                                                                            </template>
                                                                            <div class="pl-1 flex">{{ war.quantity[itemst.name] }}   <span class="mx-1 px-1 ware-name" v-if="war.warehouse.parent">({{war.warehouse.name}})</span></div>
                                                                        </template>
                                                                  </div>

                                                             </div>
                                                             <!-- /Producs quatite-->
                                                             <div :class="itemst.name=='id'|| itemst.name=='order_id'?'normal-case':''" v-else>
                                                                  <span v-if="itemst.name==='oldObject' || itemst.name==='newObject'">
                                                                       <span v-if="data[index][itemst.name] && data[index][itemst.name].settings && data[index][itemst.name].settings.length">
                                                                            <div v-for="(setting, i) in data[index][itemst.name].settings" :key="i" class="flex flex-col lg:flex-row mb-2 items-center">
                                                                                <img :src="setting.flag" class="w-4 h-4 rounded-full mr-2">
                                                                                <div id="confirmation" style="transform: inherit;" :class="setting.confirmation?'active':''" class="intro-y mr-1 flex-1 box-1 py-1">
                                                                                        <div class="text-smmm font-medium text-center">Confirmation</div>
                                                                                    </div>
                                                                                    <div style="transform: inherit;" id="Warehouse" :class="setting.fulfillement?'active':''" class="intro-y  mr-1 flex-1 box-1 py-1">
                                                                                        <div class="text-smmm font-medium text-center">Warehouse</div>
                                                                                    </div>
                                                                                    <div id="Shipping" style="transform: inherit;" :class="setting.shipping?'active':''" class="intro-y flex-1 box-1 py-1">
                                                                                        <div class="text-smmm font-medium text-center">Shipping</div>
                                                                                    </div>
                                                                                </div>
                                                                        </span>
                                                                        <span v-else-if="data[index][itemst.name] && data[index][itemst.name].countries && data[index][itemst.name].countries.length" class="flex">
                                                                                <span v-for="(images, r) in data[index][itemst.name].countries" :key="r">
                                                                                        <img :src="'https://static.dwcdn.net/css/flag-icons/flags/4x3/'+images.toLowerCase()+'.svg'" class="w-4 h-4 rounded-full mr-2">
                                                                                </span>
                                                                        </span>
                                                                        <span v-else-if="data[index][itemst.name] && data[index][itemst.name].permissions && data[index][itemst.name].permissions.length">
                                                                            <table class="table w-full text-center">
                                                                                <thead class="bg-gray-100 text-black"><tr><th class="px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider"> Model </th><th class="px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider"> Create </th><th class="px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider"> Watch </th><th class="px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider"> Read </th><th class="px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider"> Update </th><th class="px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider"> Delete </th></tr></thead>
                                                                                <tbody>
                                                                                    <tr v-for="(el, mm) in data[index][itemst.name].permissions" :key="mm" :class="mm % 2 == 0 ? 'bg-gray-100' : ''">
                                                                                    <td class="px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm"><p class="text-gray-700 capitalize" style="font-size: 11px;">{{ el.model }}</p></td>
                                                                                        
                                                                                    <td class="px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm">
                                                                                        <p class="text-gray-600 ml-3 text-semibold capitalize">
                                                                                        <label class="inline-flex text-sm items-center capitalize">
                                                                                            <input v-model="el.access.create" disabled type="checkbox" class="form-checkbox border-gray-500 h-4 w-4 text-green-500 "/>
                                                                                        </label>
                                                                                        </p>
                                                                                    </td>
                                                                                    <td
                                                                                        class="px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm"
                                                                                    >
                                                                                        <p class="text-gray-600 ml-3 text-semibold capitalize">
                                                                                        <label
                                                                                            class="inline-flex text-sm items-center capitalize"
                                                                                        >
                                                                                            <input
                                                                                            v-model="el.access.watch"
                                                                                            type="checkbox" disabled
                                                                                            class="form-checkbox border-gray-500 h-4 w-4 text-green-500 "
                                                                                            />
                                                                                        </label>
                                                                                        </p>
                                                                                    </td>
                                                                                    <td
                                                                                        class="px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm"
                                                                                    >
                                                                                        <p class="text-gray-600 ml-2 text-semibold capitalize">
                                                                                        <label
                                                                                            class="inline-flex text-sm items-center capitalize"
                                                                                        >
                                                                                            <input
                                                                                            v-model="el.access.read"
                                                                                            type="checkbox" disabled
                                                                                            class="form-checkbox border-gray-500 h-4 w-4 text-green-500 "
                                                                                            />
                                                                                        </label>
                                                                                        </p>
                                                                                    </td>
                                                                                    <td
                                                                                        class="px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm"
                                                                                    >
                                                                                        <p class="text-gray-600 ml-3 text-semibold capitalize">
                                                                                        <label
                                                                                            class="inline-flex text-sm items-center capitalize"
                                                                                        >
                                                                                            <input
                                                                                            v-model="el.access.update"
                                                                                            type="checkbox" disabled
                                                                                            class="form-checkbox border-gray-500 h-4 w-4 text-green-500 "
                                                                                            />
                                                                                        </label>
                                                                                        </p>
                                                                                    </td>
                                                                                    <td
                                                                                        class="px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm"
                                                                                    >
                                                                                        <p class="text-gray-600 ml-3 text-semibold capitalize">
                                                                                        <label
                                                                                            class="inline-flex text-sm items-center capitalize"
                                                                                        >
                                                                                            <input
                                                                                            v-model="el.access.delete"
                                                                                            type="checkbox" disabled
                                                                                            class="form-checkbox border-gray-500 h-4 w-4 text-green-500 "
                                                                                            />
                                                                                        </label>
                                                                                        </p>
                                                                                    </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </span>
                                                                        <span v-else-if="data[index][itemst.name] && data[index][itemst.name].details && data[index][itemst.name].details.length">
                                                                                 <table class="table w-full text-center">
                                                                                       <thead class="bg-gray-100 text-white"><tr class="bg-green-400 text-smmm font-medium flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"><th class="text-left">Products</th><th class="text-left">Quantity</th><th class="text-left">Total</th></tr></thead>
                                                                                        <tbody>
                                                                                            <tr v-for="(el, mm) in data[index][itemst.name].details" :key="mm" :class="mm % 2 == 0 ? 'bg-gray-100' : ''">
                                                                                               <td class="px-1 py-1 pt-1 text-left border-gray-200 bg-white text-sm"><p class="text-gray-700 capitalize" style="font-size: 11px;">{{ el.product }}</p></td>
                                                                                               <td class="px-1 py-1 pt-1 text-left border-gray-200 bg-white text-sm"><p class="text-gray-700 capitalize" style="font-size: 11px;">{{ el.quantity }}</p></td>
                                                                                               <td class="px-1 py-1 pt-1 text-left border-gray-200 bg-white text-sm"><p class="text-gray-700 capitalize" style="font-size: 11px;">{{ el.unitPrice }}</p></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                 </table>
                                                                            </span>
                                                                        <span v-else>
                                                                            {{ data[index][itemst.name] }} 
                                                                        </span>
                                                                   </span>  
                                                                   <span v-else-if="itemst.name==='is_primary'">
                                                                        <svg v-if="data[index][itemst.name]" class="fill-current" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path d="M10.041 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591zm5.959-17v2h-8v-2h8zm0 24v-2h-8v2h8zm2-22h4v4h2v-6h-6v2zm-18 14h2v-8h-2v8zm2-10v-4h4v-2h-6v6h2zm22 2h-2v8h2v-8zm-2 10v4h-4v2h6v-6h-2zm-16 4h-4v-4h-2v6h6v-2z"/></svg>
                                                                    </span>
                                                                    <span v-else-if="itemst.link">  <!--check if exist link -->
                                                                        <a v-if="data[index][itemst.name] && $f.validURL(data[index][itemst.name])" :href="data[index][itemst.name]" target="_blank" class="no-underline relative text-blue-600"><svg  class="fill-current inline-block" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/></svg></a>
                                                                        <span style="background-color: rgb(252, 231, 231); color: rgb(237, 94, 94); padding: 0.35em 0.65em; font-size: 0.75em; font-weight: 500;" v-else>Non défini</span>
                                                                    </span>
                                                                    <span v-else-if="itemst.button"> <!--check if exist link -->
                                                                        <button :disabled="dataSelected.loading" @click="GeneratePayment(config.name,data[index],index)" class="w-full font-semibold rounded-md text-white py-2 px-4 w-1/6 focus:outline-none loidingconfirm relative">
                                                                        <loading v-if="dataSelected.data._id && dataSelected.data._id.toString() === data[index]['_id'].toString()" :active.sync="dataSelected.loading" :is-full-page="fullPage"></loading>
                                                                        <img v-if="itemst.img" :src="itemst.img" class="mx-2 img-storeino inline-block" style="height: 25px;">  {{ itemst.title }}
                                                                        </button>
                                                                    </span>
                                                                    <span v-else-if="itemst.options" :class="currentUser.type === 'Seller'?'no-arrow-select':''"> <!--check if exist link -->   
                                                                        <div class="input-group-1">

                                                                        <select :disabled="dataSelected.loading || currentUser.type === 'Seller'?true:false" @change="ChangeStatus(data[index],index,itemst.table)" :class="itemst.class" :style="getStyleByName(data[index][itemst.name])" class="h-full w-fit py-2 px-2 text-xs capitalize outline-none block bg-white" :name="itemst.name" autocomplete="type" v-model="data[index][itemst.name]">
                                                                                <option v-for="(value, index) in itemst.options" :key="index" :value="value">
                                                                                {{ value }}
                                                                                </option>
                                                                        </select>

                                                                        <span v-if="dataSelected.data._id && dataSelected.data._id.toString() === data[index]['_id'].toString() && dataSelected.loading || (config.name === 'balancesHistories' && data[index].proof && !dataSelected.loading)" :style="config.name === 'balancesHistories' && data[index].proof?'padding: 0px;   width: inherit;   border: inherit;   background: inherit;   border-radius: inherit;   line-height: inherit;':''" class="input-group-addon  loidingconfirm relative">
                                                                            <loading  :active.sync="dataSelected.loading" :is-full-page="fullPage"></loading>
                                                                            <expandable-image v-if="config.name === 'balancesHistories' && data[index].proof" class="expandable-img rounded-md mx-auto inline-block align-top" :src="data[index].proof" alt="img-pr" />
                                                                        </span>

                                                                        </div>
                                                                    
                                                                    </span>
                                                                   <span v-else v-html="config.name === 'warehouses' && itemst.name === 'currency' && data[index]['parent']?'':data[index][itemst.name]"></span> 
                                                                    
                                                                   <sup class="text-black uppercase ml-1" v-if="data[index][itemst.name] && (itemst.name=='totalPrice' || itemst.name=='fees') && idWharhouse">{{idWharhouse.currency }}</sup>  
                                                             </div>
                                                        </div>
                                                    </div>       
                                            </div>    
                                        </div>
                                        <div v-else> <!-- show two fieds seller(name,nemail),custumers(name,phone) ... -->
                                            <div v-for="(ele,itemelem) in itemst.elements" :key="itemelem">
                                                <div v-for="(refit,ind) in ele.items" :key="ind">
                                                    <div :class="refit.class">
                                                        <div v-if="refit.name == 'picture'">
                                                            <img :src="data[index][itemst.name][refit.name]||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="h-12 w-12" />
                                                        </div>
                                                        <div v-else>
                                                            <span v-if="refit.name=='city'"> <!--city order in shipping-->
                                                               <i v-if="refit.icone" class="material-icons tiny">{{refit.icone}}</i> {{data[index][itemst.name]['customer'][refit.name]}} 
                                                            </span>
                                                            <span v-else> 
                                                              <span v-if="data[index][itemst.name] && !isEmpty(data[index][itemst.name][refit.name] )"> <i v-if="refit.icone" class="material-icons tiny">{{refit.icone}}</i>
                                                                <span v-if="refit.name=='shippingAddress' || refit.name=='fullName'">{{exceptText(data[index][itemst.name][refit.name],'fullName')}}</span>
                                                                <span v-else>{{ data[index][itemst.name][refit.name] }}</span> 
                                                              </span>
                                                            </span>   
                                                        </div>   
                                                    </div>
                                                </div>
                                            </div>  
                                        </div> 
                                    </div>    
                                </div> 
                                <div v-if="(col.model && col.model=='expedition') || (col.model && col.model=='order') || (col.model && col.model=='payments') || (col.model && col.model=='shippings') || (col.model && col.model=='sourcings')"> <!-- Expedition and Order and Shipping sous table -->    
                                    <div class="flex">
                                        <div v-if="(col.model && col.model=='expedition') || (col.model && col.model=='order') || (col.model && col.model=='payments') || (col.model && col.model=='sourcings')">
                                            <p><span class="font-semibold mr-1 text-green-600"> <span v-if="col.model=='order'">{{QuantityTotalProductOrderOnly(data[index][col.name])}}</span><span v-else>{{data[index][col.name].length}} </span>  </span><b>{{$t(col.title)}}</b></p>
                                            <span v-if="col.model!='sourcings'"  class="font-light text-gray-700">
                                                  {{ $moment(data[index]['date']).format('yyyy-MM-DD') }}
                                            </span>
                                        </div>
                                        <div v-else><!--shppings -->
                                            <p><span class="font-bold mr-1 text-green-600"> {{data[index].order.details.length}} </span>{{$t('products')}}</p>
                                            <span class="font-light text-gray-700">{{$t('quantity')}}: </span><b>{{QuantityTotalProductOrder(data[index])}}</b> 
                                        </div>    
                                        <button v-if="config.sousTable" @click="showSousTable(index)" :class="$colors.newColor" class="bg-gray-100 hover:bg-green-100 hover:text-white rounded-full mr-2 flex items-center text-2xl focus:outline-none p-1 ml-1">
                                            <i class="material-icons" style="transition: 0.3s" :style=" element && element._id == item._id ? 'transform: rotate(180deg)' : ''">keyboard_arrow_down</i>
                                        </button>
                                    </div>
                                </div> 
                              </div>    
                            </td>
                            <td v-if="config.actions && config.actions.elements && config.actions.elements.length != 0 " class="px-6 py-4 whitespace-no-wrap border-b border-gray-200" >
                                <div class="flex" :class="config.actions.class">
                                    
                                    <div v-for="(action,ind) in config.actions.elements" :key="ind">
                                        <div @click="getAccess(action.key,data[index]['status'],data[index]['type'],action.role,data[index]['lastUnreachedDate']||data[index]['remind']||null)" v-if="getAccess(action.key,data[index]['status'],data[index]['type'],action.role,data[index]['lastUnreachedDate']||data[index]['remind']||null) && action.role" :class="$colors.actionTable">
                                            <div  v-if="action.link"> <!--link view, edit -->
                                                
                                                <router-link 
                                                    :to="{ path: `${action.link}/${config.name === 'pointages' ? data[index]['_id']['user']['_id'] + '?Range_date=' + $f.QueryStringToobject().Range_date + '&country=' + $f.QueryStringToobject().country : config.name === 'affiliates'?data[index]['product']?data[index].product._id:data[index]['_id']:data[index]['_id']}` }"
                                                    :class="config.actions.class"><i class="material-icons mx-2">
                                                    {{ action.icone }}</i></router-link>

                                            </div>
                                            <div v-else> <!--link delete -->
                                                <button :disabled="loadingDouble" @click="emitFunction(action.key,data[index]['_id'],data[index])" class="relative loidingconfirm" :class="config.actions.class">
                                                    <loading v-if="action.notifications && dataSourcing.data._id &&  dataSourcing.data._id.toString() ===  data[index]['_id'].toString()" :active.sync="dataSourcing.loading" :is-full-page="fullPage"></loading>
                                                    <i v-if="action.icone" class="material-icons mx-2">{{action.icone}}</i> 
                                                    <img v-if="action.img" :src="action.img" alt="img" class="material-icons img-table mx-2"/> 
                                                    <svg v-if="action.svg"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :class="[action.class,action.notifications && data[index]['Nb_views']?'notification-bell':'']"><path  :d="action.svg"></path></svg>
                                                    <span v-if="action.notifications && data[index]['Nb_views']"  class="dot-count vs-avatar--count badgeNumber views">{{ data[index]['Nb_views'] }}</span>
                                                </button>                                       
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                            </td>
                        </tr>
                        <tr v-if="config.sousTable && (element && element._id == data[index]['_id'])"><!-- sous table info expedition and order -->
                          <td :colspan="config.columns.length+1" class="border">
                              <div class="w-full rounded-2xl">
                                   <table class="table w-full text-center" :class="config.name=='shippings'? 'table-shipp':''">
                                        <thead class="bg-gray-100 text-black">
                                            <tr>
                                               <th v-for="(col, ind) in config.columnsDialog.elements" :key="ind" class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">{{$t(col.name)}}
                                                <span v-if="config.name=='sourcings' && col.name=== 'Unit Price'"> ({{idWharhouse.currency}})</span>
                                               </th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="config.name=='expeditions' || config.name=='orders' || config.name=='payments' || config.name=='sourcings'">
                                            <tr v-for="(row,j) in element[config.columnsDialog.details]" :key="j" class="bg-light-green-200">
                                                <td v-for="(col, ind) in config.columnsDialog.elements" :key="ind" class="text-xs leading-5 capitalize text-gray-900">
                                                    <div v-for="(item, indeitem) in col.items" :key="indeitem">
                                                        <div v-if="config.name=='expeditions'"> <!-- Expedition sous table -->
                                                            <span v-if="col.attribut=='product'">
                                                                <div v-if="item.name=='picture'">
                                                                <img :src="[row.product][0][item.name]||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="w-8 my-2 rounded-md mx-auto">
                                                                </div>
                                                                <div v-else>
                                                                    {{[row.product][0][item.name]}}
                                                                </div>
                                                            </span> 
                                                            <span v-else>
                                                                <div v-if="item.name=='received'">
                                                                    <table  class="min-w-full">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="text-center bg-gray-200 px-6 py-1 border border-gray-300 text-left leading-4 text-green-500 tracking-wider">{{$t('ok_good')}}</th>
                                                                                <th  class="text-center bg-gray-200 px-6 py-1 border border-gray-300 text-left leading-4 text-green-500 tracking-wider">{{$t('defective')}}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody class="bg-white">
                                                                            <tr class="bg-light-green-200">
                                                                                <td class="text-xs leading-5 capitalize text-gray-900">{{[row.quantity][0][item.name]}}</td>
                                                                                <td class="text-xs leading-5 capitalize text-gray-900">{{[row.quantity][0][item.name_1]}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                 <div v-else>
                                                                    <div v-if="item.name=='merchandise_value'">
                                                                          {{[row][0][item.name]}} <sup class="text-black uppercase" v-if="idWharhouse">{{idWharhouse.currency}}</sup>
                                                                    </div>
                                                                    <div v-else>
                                                                          {{[row.quantity][0][item.name]}}
                                                                    </div>      
                                                                 </div>                                                               
                                                            </span> 
                                                        </div>
                                                        <div v-else-if="config.name=='sourcings'"> <!-- Sourcing sous table -->
                                                                <span v-if="col.attribut=='productObject'">
                                                                    <div v-if="item.name=='image'">
                                                                        <img :src="[row.productObject][0][item.name]||'https://img.icons8.com/external-others-inmotus-design/67/000000/external-Image-basic-ui-elements-others-inmotus-design-3.png'" class="w-8 my-2 rounded-md mx-auto">
                                                                    </div>
                                                                    <div v-else>
                                                                        {{[row.productObject][0][item.name]}}
                                                                    </div>
                                                                </span> 
                                                                <span v-else>
                                                                    {{[row][0][item.name]}} 
                                                                </span>    
                                                            </div>
                                                        <div v-else-if="config.name=='orders'"> <!-- Order sous table -->
                                                             <div v-if="col.referent"> 
                                                                <span v-if="col.referent=='product'">
                                                                    <div v-if="item.name=='picture'">
                                                                         <img :src="[row.product][0][item.name]||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="w-8 my-2 rounded-md mx-auto">
                                                                    </div>
                                                                    <div v-else>
                                                                        <span v-if="item.name=='name' && idWharhouse && idWharhouse._id">
                                                                              <a class="text-blue-600" target="_blank" :href="data[index].affiliate && data[index].affiliate.type && currentUser.type === 'Seller'?`/affiliates/requests?name=${[row.product][0].id}`:`/products?name=${[row.product][0].id}&limit=10&details.warehouse=${idWharhouse._id}`">{{ [row.product][0][item.name] }} </a>
                                                                        </span>
                                                                        <span v-else>
                                                                            {{[row.product][0][item.name]}}
                                                                        </span>
                                                                    </div>
                                                                </span> 
                                                                <span v-else>{{[row.category][0][item.name]}}</span> 
                                                             </div>
                                                             <div v-else>
                                                                 {{item.name=='warehouseParentName'?([row][0][item.name]||idWharhouse.name):[row][0][item.name]}} <sup class="text-black uppercase" v-if="item.name=='unitPrice' && idWharhouse">{{idWharhouse.currency}}</sup>
                                                             </div>
                                                        </div>
                                                        <div v-else><!-- payament sous tabl -->
                                                          <div v-if="item.referent">
                                                              <div v-if="item.field">
                                                                  {{row[item.referent][item.field][item.name]}}
                                                              </div>
                                                              <div v-else>
                                                                   {{row[item.referent][item.name]}} <sup class="text-black uppercase" v-if="item.name=='totalPrice' && config.name=='payments' && idWharhouse">{{idWharhouse.currency}}</sup>
                                                              </div> 
                                                          </div>
                                                          <div v-else>
                                                              <div :class="((item.name=='shipping_refund_vat' || item.name=='shipping_cancel_vat') && row[item.name]==0  && config.name=='payments') ?'empty-row':''">
                                                                     {{row[item.name]}}  <sup class="text-black uppercase" v-if="config.name=='payments' && idWharhouse">{{idWharhouse.currency}}</sup>
                                                              </div>
                                                          </div>
                                                        </div>                                                      
                                                    </div>
                                                </td>
                                            </tr> 
                                        </tbody>
                                        <tbody  v-else><!--Shipping sous table order info-->
                                            <tr class="bg-light-green-200">
                                                 <td v-for="(col, ind) in config.columnsDialog.elements" :key="ind" class="text-xs leading-5 capitalize text-gray-900">
                                                      <div v-for="(item, indeitem) in col.items" :key="indeitem">
                                                            <div v-if="col.attribut"> 
                                                                 <div v-if="!item.elements">
                                                                    <span v-if="item.name == 'shippingAddress'">{{ data[index].address }}</span> 
                                                                    <span v-else>{{ data[index][col.referent][col.attribut][item.name] }}</span>
                                                                 </div>
                                                                 <div v-else> <!-- Show two fields (Custumer) -->
                                                                        <div v-for="(ele,itemelem) in item.elements" :key="itemelem">
                                                                            <div v-for="(refit,ind) in ele.items" :key="ind">
                                                                                <div :class="ele.class">
                                                                                     <div v-if="col.attribut!='seller'">
                                                                                          <i v-if="refit.icone" class="material-icons tiny">{{refit.icone}}</i>  {{data[index][col.referent][col.attribut][refit.name]}}
                                                                                     </div>  
                                                                                     <div v-else>
                                                                                        <span v-if="!isEmpty(data[index][config.columnsDialog.details][config.columnsDialog.name][0].product.seller[refit.name])">   <i v-if="refit.icone" class="material-icons tiny">{{refit.icone}}</i>  {{data[index][config.columnsDialog.details][config.columnsDialog.name][0].product.seller[refit.name]}}</span>
                                                                                     </div> 
                                                                                </div>
                                                                            </div>
                                                                        </div>  
                                                                 </div>                                                    
                                                            </div>
                                                            <div v-else>
                                                                {{data[index][col.referent][item.name]}} <sup class="text-black uppercase" v-if="item.name=='totalPrice' && idWharhouse">{{idWharhouse.currency}}</sup> 
                                                            </div>  
                                                      </div>   
                                                 </td>   
                                            </tr>               
                                        </tbody>
                                         <thead class="bg-gray-100 text-black"><!--Shipping sous table product order info-->
                                            <tr><th v-for="(col, ind) in config.columnsDialog.sousElements" :key="ind" class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">{{$t(col.name)}}</th></tr>
                                         </thead>
                                         <tbody>
                                            <tr v-for="(prod, k) in data[index][config.columnsDialog.details][config.columnsDialog.name]" :key="k" class="bg-light-green-200"> <!-- sous table products in order -->
                                                <td v-for="(col, ind) in config.columnsDialog.sousElements" :key="ind" class="text-xs leading-5 capitalize text-gray-900">
                                                    <div v-for="(item, indeitem) in col.items" :key="indeitem">
                                                         <div v-if="col.referent">                                                          
                                                            <div v-if="item.name=='picture'">
                                                                <img :src="prod[col.referent][item.name]||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="w-8 my-2 rounded-md mx-auto">
                                                            </div>
                                                            <div v-else>
                                                                <span v-if="item.name=='name' && idWharhouse && idWharhouse._id">
                                                                    <a class="text-blue-600" target="_blank" :href="data[index].affiliate && data[index].affiliate.type && currentUser.type === 'Seller'?`/affiliates/requests?name=${prod[col.referent].id}`:`/products?name=${prod[col.referent].id}&limit=10&details.warehouse=${idWharhouse._id}`">{{prod[col.referent][item.name] }}  </a>
                                                                </span>
                                                                <span v-else>
                                                                    {{prod[col.referent][item.name]}} 
                                                                </span>
                                                            </div>                                                          
                                                         </div>
                                                         <div v-else> 
                                                            <span> {{item.name=='warehouseParentName'?(prod[item.name]||idWharhouse.name):prod[item.name]}} <sup class="text-black uppercase" v-if="item.name=='unitPrice' && idWharhouse">{{idWharhouse.currency}}</sup></span> 
                                                         </div>
                                                    </div>
                                                </td>
                                            </tr> 
                                         </tbody>     
                                    </table>     
                              </div>
                          </td> 
                        </tr>
                         <!--**************************************Debut Modal Change Shipping Address  and Status Order***************************-->
                        
                          <vue-modal-2 v-if="config.name=='orders'" :name="'modal1_' + index" :headerOptions="{title: $t('order_information')}" :noFooter="true" modalSize="xl" @on-close="closePopUpInformation(index)">
                            <modal-info-order :item="item" :index="index" :idWharhouse="idWharhouse" :sous_warehouses="sous_warehouses" :cities="cities" @getFeesShippBycity="getFeesShippBycity" :feesShipp="feesShipp" :CheckQteDefecteuseorderexist="CheckQteDefecteuseorderexist" @CheckQteDefecteuseOrder="CheckQteDefecteuseOrder"  :courier="courier" :currentUser="currentUser" @getCourierZone="getCourierZone" @OrderChange="OrderChange" :shipping="shipping"  :config="config" :statusOrder="statusOrder" :zones="zones" />
                            <div class="modal-footer  px-5 border0-t text-right">
                               <button @click="closePopUpInformation(index)" class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">Cancel</button>
                               <button @click="ConfirmOperationUpdate(item, index)" class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right relative loidingconfirm" :disabled="loadingcreateShipp"><loading :active.sync="loadingcreateShipp" :is-full-page="fullPage"></loading>Confirm</button>
                            </div>
                         </vue-modal-2>     
                          
                        <!--**************************************Debut Modal Change Shipping Address  and Status Order***************************-->
                        <!--**************************************Debut Modal Change status recived expeditions***************************-->
                       
                        <vue-modal-2 v-if="config.name=='expeditions'" :name="'modal1_' + index" :headerOptions="{title: $t('expedition_information')}" :noFooter="true" modalSize="xl" @on-close="closePopUpInformation(index)">
                            <modal-info-expo :item="item" :index="index" :config="config" :idWharhouse="idWharhouse" :CheckExpeditionRecived="CheckExpeditionRecived" @CheckQteSentExist="CheckQteSentExist" :statusExpedition="statusExpedition" :transports="transports"/>
                            <div class="modal-footer  px-5 border0-t text-right">
                               <button @click="closePopUpInformation(index)" class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">Cancel</button>
                               <button @click="ConfirmOperationUpdate(item, index)" class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right relative loidingconfirm" :disabled="loadingcreateShipp"><loading :active.sync="loadingcreateShipp" :is-full-page="fullPage"></loading>Confirm</button>
                            </div>
                         </vue-modal-2>     
                          
                        <!--**************************************Debut Modal Change status recived expeditions***************************-->
                        <!--**************************************Debut Modal Change status shipping***************************-->
                       
                         <vue-modal-2 v-if="config.name=='shippings'" :name="'modal1_' + index" :headerOptions="{title: $t('shipping_informations')}" :noFooter="true" modalSize="xl" @on-close="closePopUpInformation(index)">
                            <modal-info-shipp :item="item" :index="index" :dataCountry="dataCountry" :feesShipp="feesShipp"   :couriers="couriers" @ChangeQuantityOrder="ChangeQuantityOrder" @searchCouriers="searchCouriers" :currentUser="currentUser" :config="config" @OpenClosePopupModalReporte="OpenClosePopupModalReporte" :CheckQteDefecteuseexist="CheckQteDefecteuseexist" :CheckQteReturnedexist="CheckQteReturnedexist"  @CheckQteDefecteuse="CheckQteDefecteuse" @CheckQteReturned="CheckQteReturned" :CheckQteDefecteuseorderexist="CheckQteDefecteuseorderexist" @CheckQteDefecteuseOrder="CheckQteDefecteuseOrder" :zones="zones" :statusShipp="statusShipp" :typesShipp="typesShipp" :idWharhouse="idWharhouse"/>
                            <div class="modal-footer  px-5 border0-t text-right">
                               <button @click="closePopUpInformation(index)" class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">Cancel</button>
                               <button @click="ConfirmOperationUpdate(item, index)" class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right loidingconfirm relative" :class="loadingcreateShipp?'noselect':''"><loading :active.sync="loadingcreateShipp" :is-full-page="fullPage"></loading>Confirm</button>
                            </div>
                          </vue-modal-2>   


                            <vue-modal-2 v-if="config.name=='sourcings'" :name="'modal1_' + index" :headerOptions="{title: 'Sourcing informations'}" :noFooter="true" modalSize="xl" @on-close="closePopUpInformation(index)">
                                <modal-info-sourcing :item="item" :dataCountry="dataCountry" :index="index" :currency="idWharhouse?idWharhouse.currency:''" :currentUser="currentUser" :config="config" :statusShipp="statusShipp"/>
                                <div class="modal-footer px-5 border0-t text-right">
                                <button @click="closePopUpInformation(index)" class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">Cancel</button>
                                <button @click="ConfirmOperationUpdate(item, index)" class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-blue-500 py-2 px-8 items-center focus:outline-none md:float-right loidingconfirm relative" :class="loadingdatashipp?'noselect':''"><loading :active.sync="loadingdatashipp" :is-full-page="fullPage"></loading>Confirm</button>
                                </div> 
                            </vue-modal-2>  
                        
                        <!--**************************************/Debut Modal Change status shipping***************************-->
                         <!--**************************************Debut Modal Change shipping print***************************-->
                        <vue-modal-2  v-if="config.name=='shippings'" class="printing" name="print-shippings-delivry" :headerOptions="{title: $t('shipping_informations')}" :noFooter="true" modalSize="xl">
                                     <modal-info-shipp-print :item="item"   :index="index" :idWharhouse="idWharhouse" :config="config"/>
                        </vue-modal-2>    
                        <!--**************************************/Debut Modal Change shipping print***************************-->
                    </tbody>
                </table>
              </div>
                <div v-if="data.length <= 0" class="bg-green-100 py-8">
                    <span class="w-full flex justify-center text-center py-3 border-b border-gray-200 text-base">
                        <p class="font-semibold flex text-green-500 whitespace-no-wrap">
                            <i class="material-icons mx-3">error</i>
                            <span class>{{$t('no_rows_selected')}}</span>
                        </p>
                    </span>
                </div>
            </div>
            <!--pagination -->
            <div class="p-1" v-if="paginate.lastpage > 1" >
                <div class="flex flex-col items-center my-12">
                    <div-paginate  :paginate="paginate" @paginatation="paginatation" />
                </div>
            </div>
            <!--end pagination -->
            <!--Modal reporté shipping-->
             <vue-modal-2 v-if="config.name=='shippings'" class="modalreporteshipp"  name="ModalReporteShipp" :headerOptions="{title: $t('postponed_shippings')}" :noFooter="true" modalSize="lg" @on-close="OpenClosePopupModalReporte('close','postponed')">
               <div class="w-full bg-white rounded-lg px-4 pt-2">
               <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full md:w-full px-3 mb-2 mt-2">
                       <datetime-v2 :min-datetime="$moment(Date.now()).format('yyyy-MM-DD')" v-model="postponed.date"
                                    :placeholder="$t('postponed_date')" input-class="focus:outline-none shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" ></datetime-v2> 
                    </div>
                    <div class="w-full md:w-full px-3 mb-2 mt-2">
                        <textarea class="bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full h-20 py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white" v-model="postponed.comment" placeholder="Your Comment"></textarea>
                    </div>
                    <div class="w-full md:w-full flex items-start md:w-full px-3">
                        <div class="-mr-1">
                        <button @click="ConfirmReporte('reporte')" class="rounded flex focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">Confirm</button>
                        </div>
                    </div> 
                </div>
                </div> 
             </vue-modal-2 > 
            <!--Modal change status Shipping -->
            <vue-modal-2 v-if="config.name=='shippings' || config.name=='orders'"  name="ModalChangeStatus" :headerOptions="{title: $t('change_status')}" :noFooter="true" modalSize="lg" @on-close="OpenClosePopupModal('close')">
                <loading :active.sync="loadingChange" :is-full-page="fullPage"></loading>
                <div class="bg-white rounded-lg"  ref="shippp">
                    <div class="rounded-lg flex">
                        <div class="w-1/5 flex justify-center items-center">
                            <span class="w-16 h-16 bg-green-600 text-white p-3 rounded-full"><i class="material-icons" style="font-size: 40px;">update</i></span>
                        </div>
                        <div class="w-full pt-9 pr-4" :class="config.name=='orders'?'mb-130':''">
                            <h3 class="font-bold text-green-700 mb-8">{{$t('change_status')}}</h3>
                            <div class="mb-5 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                    <p>
                                        <label for="status" class="bg-white text-gray-700 px-1">{{$t('status')}}</label>
                                    </p>
                                </div> 
                                <p>
                                    <v-select v-if="config.name=='shippings'"  v-model="change_status.statusTochange" :options="statusShippChange" ></v-select> 
                                    <v-select v-else label="name"  v-model="change_status.statusTochange" :options="statusShippChange" ></v-select>    
                                </p>
                            </div>
                            <div v-if="config.name=='shippings'" class="flex items-center mb-5 justify-between"><span class="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span> <span @click="showcourier = !showcourier" class="text-xs text-green-600 font-bold cursor-pointer  uppercase dark:text-blue-500 hover:underline"><i class="material-icons mr-2" style="font-size: 15px;">person_add</i> {{$t('select_courier')}}</span> <span class="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span></div>
                            <div v-if="showcourier && config.name=='shippings'" class="border mb-4 h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                    <p>
                                    <label for="category" class="bg-white text-gray-700 px-1">{{$t('courier')}} <span :class="$colors.required">*</span></label>
                                    </p>
                                </div>
                                <p>
                                    <v-select  label="fullName"  @search="searchCouriers($event,'hh')" v-model="change_status.courier" :options="couriers" ></v-select>
                                </p>
                            </div>
                        
                        </div>
                    </div>  
                    <div v-if="config.name=='shippings'" class="tab-cc mx-4 s-sidebarwidget s-sidebarwidget__yellow s-anchors s-anchors__grayscale">
                            <div class="d-block p-0 m-0">
                               <div class="s-sidebarwidget--header flex s-sidebarwidget__small-bold-text fc-light d:fc-black-900 bb bbw1"><svg xmlns="http://www.w3.org/2000/svg" class="fill-current mr-2"  width="20" height="20" viewBox="0 0 24 24"><path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/></svg> Change in order of cases</div>
                                <div class="s-sidebarwidget--item">
                                    
                              <table class="w-full">
                                    <tbody>
                                        <tr><td><b>To prepare</b></td><td><span><svg class="fill-current" width="24" height="24"  xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>OutOfStock</b></td></tr>
                                        <tr><td><b>OutOfStock</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>To prepare</b></td></tr>
                                        </tbody>
                                </table>
                                <hr class="hr-grey">
                                <table class="w-full">
                                    <tbody>
                                        <tr><td><b>To prepare</b></td><td><span><svg class="fill-current" width="24" height="24"  xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>Prepared</b></td></tr>
                                        <tr><td><b>(Prepared,Reprogrammer)</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>Shipped</b></td></tr>
                                        <tr><td><b>Shipped</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>(Reprogrammer,Delivered)</b></td></tr>
                                        <tr><td><b>Delivered</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>Paid</b></td></tr>
                                        </tbody>
                                </table>
                                <hr class="hr-grey">
                                <table class="w-full">
                                    <tbody>
                                        <tr><td><b>(Shipped,Reprogrammer,Delivered)</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>Cancelled</b></td></tr>
                                        <tr><td><b>(Shipped,Reprogrammer,Delivered)</b></td><td><span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg></span></td><td class="text-right"><b>Refused</b></td></tr>
                                        </tbody>
                                    </table>
                                  </div>    
                            </div>
                    </div>
                    <div class="p-4 modal-footer flex space-x-4">
                        <a href="#" @click="OpenClosePopupModal('close')" class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400  font-bold rounded-lg text-sm">{{$t('cancel')}}</a>
                        <a href="#" @click="confirmChange" class="w-1/2 px-4 py-3 text-center text-green-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm">{{$t('confirm')}}</a>
                    </div>
                </div>
             </vue-modal-2>   
                
            <!-- end modal change status shipping -->
             <!--Modal change status Shipping By dauchette -->
            <vue-modal-2 v-if="config.name=='shippings'"  name="ModalChangeStatusByDauchette" :headerOptions="{title: $t('change_status')+(newShipp.status?` (${newShipp.status} : ${newShipp.shippings.length} orders)`:'')}" :noFooter="true" modalSize="xl">
                <div class="bg-white rounded-lg mb-20">
                    <div class="bg-dauch mb-2 px-3 mx-3 p-4 rounded-lg bg-white rounded-lg flex">
                        <div class="w-1/5 flex justify-center items-center">
                            <span class="w-16 h-16 bg-green-600 text-white p-3 rounded-full"><i class="material-icons" style="font-size: 40px;">update</i></span>
                        </div>
                        <div class="w-full pt-9 pr-4">
                            <h3 class="font-bold text-green-700 mb-6">{{$t('change_status')}}</h3>

                            <div v-if="newShipp.dauchete"  class="border mb-4 h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                    <p>
                                    <label for="category" class="bg-white text-gray-700 px-1">{{$t('courier')}} <span :class="$colors.required">*</span></label>
                                    </p>
                                </div>
                                <p>
                                    <v-select :disabled="newShipp.shippings.length>0?true:false" label="fullName"  @search="searchCouriers($event,'hh')" v-model="newShipp.courier" :options="couriers" ></v-select>
                                </p>
                            </div>

                             <div v-if="newShipp.courier && newShipp.dauchete"  class=""> 
                               
                                <div class="flex flex-col lg:flex-row" :class="newShipp.shippings.length>0?'noselect':''"> 
                                    <div id="fulfillement" @click="activate('sortie','courier')" :class="{active: newShipp.settings.sortie}" class="intro-y flex-1 box-1 py-2 cursor-pointer zoom-in ">
                                        <div class="check">
                                            <span class="feather-icon select-none relative">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                            </span>
                                        </div>
                                        <img class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" width="64" height="64" src="https://img.icons8.com/external-kmg-design-detailed-outline-kmg-design/64/external-delivery-man-logistics-delivery-kmg-design-detailed-outline-kmg-design-2.png" alt="external-delivery-man-logistics-delivery-kmg-design-detailed-outline-kmg-design-2"/>
                                        <div class="text-xl font-medium text-center mt-5">Livreur sort</div>
                                    </div>

                                    <div id="confirmation" @click="activate('entree','courier')" :class="{active: newShipp.settings.entree}" class="intro-y flex-1 box-1 py-2 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in">
                                        <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                                        <img class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" width="64" height="64" src="https://img.icons8.com/external-kmg-design-detailed-outline-kmg-design/64/external-delivery-man-logistics-delivery-kmg-design-detailed-outline-kmg-design.png" alt="external-delivery-man-logistics-delivery-kmg-design-detailed-outline-kmg-design"/>
                                        <div class="text-xl font-medium text-center mt-5">Entre le livreur</div>
                                    </div>
                                    
                                </div>   
                                
                            </div>

                            <div v-if="newShipp.previous"  class="mb-2 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                    <p><label for="status" class="bg-white text-gray-700 px-1">{{$t('status')}}</label></p>
                                </div> 
                                <p>
                                    <v-select @input="checkChangeStatusPrevieus"  v-model="newShipp.status" :options="newShipp.previous?statusPrevious:statusShippChange" ></v-select>    
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="newShipp.status || (newShipp.courier && (newShipp.settings.entree || newShipp.settings.sortie))" class="bg-white rounded-lg mb-14">
                    <div class="flex flex-col  px-3 mx-3 p-4 rounded-lg bg-white bg-dauch">
                        <div class="flex flex-row">
                            <div class="flex w-full mb-5">
                                <input id="inputshipping"  @input="searchShippByTrack($event)" placeholder="Scannez le code QR ici ou le numéro de suivi ..." v-model="searchTextShipp" type="search" autofill="off"  class="upload-dropzone-border animation-pulse text-gray-700  text-xs border-b border-l border-r rounded-l-md rounded-r-md w-full focus:outline-none py-2 px-4">
                            </div>
                        </div>
                        <div style="overflow-x: auto;">
                           <table class="min-w-full w-full">
                               <thead class="relative">
                                    <tr class="bg-gray-400 text-gray-600 uppercase text-sm leading-normal">
                                        <th class="px-3 w-10  py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><label class="text-lg text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"><input type="checkbox" @click="selectAllCheckboxShipp($event)" class="form-checkbox checkallbboxShipp focus:outline-none focus:shadow-outline"></label></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Order ID </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Seller </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Custumer </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Shipping Date </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Traking number </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Total Price </span></th>
                                        <th class="px-3 text-center py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Status </span></th>
                                        <th v-if="newShipp.settings.entree" class="px-3 text-center py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> New Status </span></th>
                                        <th v-if="newShipp.settings.entree" class="px-3 text-center py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Commentaire </span></th>
                                        <th class="px-3 text-center py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Action </span></th>
                                    </tr>
                                </thead>
                                <loading :active.sync="newShipp.loading" :is-full-page="fullPage"></loading>
                                <tbody>
                                 <tr  v-for="(item, index) in newShipp.shippings" :key="index"  class="border-b border-gray-200 hover:bg-gray-100" :class="newShipp.settings.entree && item.newStatus && item.newStatus === 'return'?'bg-red-200':'bg-orange-200'">
                                   <td class="px-3 whitespace-no-wrap border-b border-gray-300"><label class="text-lg text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"><input type="checkbox"  @click="getRowDetailShipp(item, item._id)" :newStatus="item.newStatus?item.newStatus:''" :comment="item.comment||''" :status="item.status?item.status:''" :trackingNumber="item.trackingNumber||''"  :id="item._id" :upsel="item.upsel?item.upsel:'false'" :courier="item.courier?item.courier._id||item.courier:''" :orderobjet="item.orderObjet?JSON.stringify(item.orderObjet):''" :order="item.order?item.order._id:''" :seller="(item.seller && item.seller.source)?item.seller.source:'nosource'" :class="'rowProduct-'+item._id" class="form-checkbox rowCheckboxShipp focus:outline-none focus:shadow-outline"></label></td>
                                   <td class="px-3  whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900">{{item.orderObjet.id}}</div></td>
                                   <td class="px-3  whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900">{{item.seller.fullName||item.seller.email}}</div></td>
                                   <td class="px-3 whitespace-no-wrap border-b border-gray-300"><div style="font-size: 10px;line-height: 15px;" class="leading-5 capitalize rounded-full text-gray-900"><span>{{item.orderObjet.customer.fullName}}</span><span class="block">{{item.orderObjet.customer.phone}}</span></div></td>
                                   <td class="px-3  whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900">{{$moment(item.date).format('yyyy-MM-DD')}}</div></td>
                                   <td class="px-3  whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900">{{item.trackingNumber}}</div></td>
                                   <td class="px-3  whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900"><span  class="font-bold mr-1 text-blue-600">{{item.orderObjet.totalPrice}} <sup v-if="idWharhouse">{{idWharhouse.currency}}</sup></span></div></td>
                                   <td class="px-3 whitespace-no-wrap border-b border-gray-300 text-center"><div class="text-xs leading-5 capitalize rounded-full text-gray-900"><button class="border capitalize rounded-full text-gray-600  text-xs px-4 bg-white hover:border-gray-500 focus:outline-none appearance-none" :style="$f.ColorsStatusShippings(item.status)">{{item.status}}</button></div></td>
                                   <td v-if="newShipp.settings.entree" class="px-3  whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full" :class="item.newStatus && item.newStatus === 'return'?'text-red-800':'text-orange-400'"><b>{{item.newStatus}}</b></div></td>
                                   <td v-if="newShipp.settings.entree" class="px-3  whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full"><b>{{item.comment}}</b></div></td>
                                   <td class="px-3 whitespace-no-wrap border-b border-gray-300 text-center">
                                      <button @click="removeOrder(item, index)" :class="$colors.actionTable" class="rounded-full  focus:outline-none w-8 h-8"><i class="material-icons mx-1">delete</i></button>
                                   </td>
                                 </tr>
                                </tbody>
                           </table>
                            <div v-if="newShipp.shippings.length==0" class="bg-blue-100 py-8">
                                <span class="w-full flex justify-center text-center py-3 border-b border-gray-200 text-base">
                                     <img alt="..." class="w-20 ml-4" :src="imageScannerShipp"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="p-4 flex space-x-4 modal-footer">
                        <a href="#" @click="OpenClosePopupModal('close','dauchette')" :class="loadingdatashipp?'noselect':''" class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400  font-bold rounded-lg text-sm">{{$t('cancel')}}</a>
                        <a href="#" @click="EmitActionNewShipp('dauchette')" :class="loadingdatashipp?'noselect':''" class="w-1/2 px-4 py-3 text-center text-green-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative"><loading :active.sync="loadingdatashipp" :is-full-page="fullPage"></loading> {{$t('confirm')}}</a>
                    </div>
                </div>
             </vue-modal-2>   
                
            <!-- end modal change status shipping -->
             <!--Modal change status Shipping -->
                 <vue-modal-2 v-if="config.name=='products'"  name="ModalChangeQte" :headerOptions="{title: $t('change_quantity')}" :noFooter="true" modalSize="lg" @on-close="OpenClosePopupModal('close')">
                <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                <div class="bg-white rounded-lg">
                    <div class="rounded-lg flex">
                    <!-- <div class="w-1/3 pt-6 flex justify-center items-center">
                        <span class="w-16 h-16 bg-green-600 text-white p-3 rounded-full"><i class="material-icons" style="font-size: 40px;">import_export</i>{{selectProduct.name}}</span>
                    </div> -->
                    <div class="w-full pt-9 px-4 mb-20">
                       
                       <div  v-if="selectProduct.name" class="py-3 px-5 mx-2 mb-6 bg-gray-100 text-gray-900 rounded-md text-sm border border-gray-200" role="alert">
                         <p> <strong>{{$t('product')}}: {{selectProduct.name}} </strong></p>
                         <p class="mb-3"><strong>{{$t('current_quantity')}}: </strong></p>
                          <div class="flex flex-wrap">  
                               <div class="w-2/6 text-center px-2">
                                   <div class="bg-gray-300 p-2 rounded-lg flex items-center justify-center border border-gray-200">
                                   <div class="w-1/3 bg-transparent flex items-center justify-center icon-step">
                                      {{$t('quantity_in_stock')}}
                                   </div>
                                   <div class="w-2/3 bg-gray-200  flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                                       <h2 class="font-bold text-sm">Total:  {{ selectProduct.quantity.inStock }}</h2>
                                       <div class="py-1">
                                           <div  v-for="(wharhouse, i) in producWarhouse" :key="i" v-if="wharhouse.warehouse.country == idWharhouse.country" class="flex justify-center items-center py-1">
                                               <div class="flex">
                                                  <svg v-if="wharhouse.warehouse.is_primary" class="fill-current mr-2" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M12 6.453l9 8.375v9.172h-6v-6h-6v6h-6v-9.172l9-8.375zm12 5.695l-12-11.148-12 11.133 1.361 1.465 10.639-9.868 10.639 9.883 1.361-1.465z"/></svg> <img class="w-4 h-4 rounded-full" :src="wharhouse.warehouse.flag">  
                                               </div>
                                               <div class="pl-1 flex">{{ wharhouse.quantity.inStock }}   <span class="mx-1 px-1 ware-name pt-1" v-if="wharhouse.warehouse.parent">({{wharhouse.warehouse.name}})</span></div>
                                           </div>
                                       </div>
                                   </div>
                                   </div>
                               </div>
                               <div class="w-2/6 text-center px-2">
                                   <div class="bg-gray-300 p-2 rounded-lg flex items-center justify-center border border-gray-200">
                                   <div class="w-1/3 bg-transparent  flex items-center justify-center icon-step">
                                        {{$t('quantity_total')}}
                                   </div>
                                   <div class="w-2/3 bg-gray-200  flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                                       <h2 class="font-bold text-sm">Total:  {{ selectProduct.quantity.total }}</h2>
                                       <div class="py-1">
                                           <div  v-for="(wharhouse, i) in producWarhouse" :key="i" v-if="wharhouse.warehouse.country == idWharhouse.country" class="flex justify-center items-center py-1">
                                               <div class="flex">
                                                  <svg v-if="wharhouse.warehouse.is_primary" class="fill-current mr-2" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M12 6.453l9 8.375v9.172h-6v-6h-6v6h-6v-9.172l9-8.375zm12 5.695l-12-11.148-12 11.133 1.361 1.465 10.639-9.868 10.639 9.883 1.361-1.465z"/></svg> <img class="w-4 h-4 rounded-full" :src="wharhouse.warehouse.flag">  
                                               </div>
                                               <div class="pl-1 flex">{{ wharhouse.quantity.total }}   <span class="mx-1 px-1 ware-name pt-1" v-if="wharhouse.warehouse.parent">({{wharhouse.warehouse.name}})</span></div>
                                           </div>
                                       </div>
                                   </div>
                                   </div>
                               </div>  
                                <div class="w-2/6 text-center px-2">
                                   <div class="bg-gray-300 p-2 rounded-lg flex items-center justify-center border border-gray-200">
                                   <div class="w-1/3 bg-transparent  flex items-center justify-center icon-step">
                                        {{$t('quantity_total')}}
                                   </div>
                                   <div class="w-2/3 bg-gray-200  flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                                       <h2 class="font-bold text-sm">Defective:  {{ selectProduct.quantity.defective }}</h2>
                                       <div class="py-1">
                                           <div  v-for="(wharhouse, i) in producWarhouse" :key="i" v-if="wharhouse.warehouse.country == idWharhouse.country" class="flex justify-center items-center py-1">
                                               <div class="flex">
                                                  <svg v-if="wharhouse.warehouse.is_primary" class="fill-current mr-2" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M12 6.453l9 8.375v9.172h-6v-6h-6v6h-6v-9.172l9-8.375zm12 5.695l-12-11.148-12 11.133 1.361 1.465 10.639-9.868 10.639 9.883 1.361-1.465z"/></svg> <img class="w-4 h-4 rounded-full" :src="wharhouse.warehouse.flag">  
                                               </div>
                                               <div class="pl-1 flex">{{ wharhouse.quantity.defective }}   <span class="mx-1 px-1 ware-name pt-1" v-if="wharhouse.warehouse.parent">({{wharhouse.warehouse.name}})</span></div>
                                           </div>
                                       </div>
                                   </div>
                                   </div>
                               </div>  
                       </div>
                       </div>

                     <div class="flex flex-wrap">  
                        <div class="w-full lg:w-1/3 px-2">
                           <div class="mb-5 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                               <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                   <p>
                                       <label for="status" class="bg-white text-gray-700 px-1">{{$t('type_operation')}}</label>
                                   </p>
                               </div> 
                               <p>
                                   <v-select class="v-instock"  v-model="ChangeQte.operation" :options="typeoperations" ></v-select>    
                               </p>
                           </div>
                       </div>
                        <div class="w-full lg:w-1/3 px-2">
                           <div class="mb-1 border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                               <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                   <p>
                                       <label for="status" class="bg-white text-gray-700 px-1">Type stock</label>
                                   </p>
                               </div> 
                               <p>
                                   <v-select class="v-instock"  v-model="ChangeQte.stock" :options="typeostocks" ></v-select>    
                               </p>
                           </div>
                       </div>
                        <div class="w-full lg:w-1/3 px-2">
                           <div class="mb-5 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                               <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                   <p>
                                       <label for="quantity" class="bg-white text-gray-700 px-1">{{$t('quantity')}}</label>
                                   </p>
                               </div> 
                               <p>  
                                     <input id="quantity"  type="number" min="0" v-model="ChangeQte.quantity" class="py-2 text-xs px-2 outline-none block h-full w-full"/> 
                               </p>
                           </div>
                       </div>
                        <!-- <div v-if="checkQte" class="w-full mx-4 px-4 py-3 px-5 mb-4 bg-red-100 text-red-900 text-sm rounded-md border border-red-200 flex items-center" role="alert">
                           <div class="w-4 mr-2">
                               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"></path>
                               </svg>
                           </div>
                        <span><strong>{{$t('quantity_instock_insufficient')}}</strong></span>
                       </div>  -->
                     </div>  
                       <section class="warehouse mb-20" v-if="expeditionData.warhouseParent.exist">   
                                   <div class="flex mb-5 items-center text-center">
                                       <hr class="border-gray-600 border-1 w-full rounded-md">
                                       <label class="block font-medium text-sm cursor-pointer text-green-500  w-full"><span class="font-medium text-base text-green-800 dark:text-green-200">Choisir warehouse</span> </label>
                                       <hr class="border-gray-600 border-1 w-full rounded-md">
                                   </div>
                                   <div class="bg-ww warhouseparent">
                                       <h2 class="text-sm font-bold truncate mb-5">Définir le warehouse pour ce produit </h2> 
                                       <div class="w-full">
                                         <div v-for="it in checkWarehousingParent" :key="it._id"  :id="it._id" @click="activateWarehouse(it)" :class="{active: it.fullOf,'parent-warehouse':!it.is_primary}" class="border border-gray-600 intro-y flex-1 box-1 cursor-pointer zoom-in p-2 mb-2 pl-10">
                                           <div class="check-left"><span class="feather-icon select-none relative"><svg :class="it.fullOf?'bg-green-500':'bg-gray-500'" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check p-1 w-5 h-5 text-white rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                                           <div class="text-sm font-bold text-left">{{it.name}} <span v-if="it.is_primary">(Principal)</span></div>
                                         </div>             
                                       </div>
                                   </div>
                        </section>  
                             
                   </div>
                    </div>
                    <div class="p-4 modal-footer flex space-x-4">
                        <button @click="OpenClosePopupModal('close')" class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400  font-bold rounded-lg text-sm" :class="ChangeQte.loading?'noselect':''">{{$t('cancel')}}</button>
                        <button  @click="confirmChangeQte" class="w-1/2 px-4 py-3 text-center text-green-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative" :class="ChangeQte.loading?'noselect':''"><loading :active.sync="ChangeQte.loading" :is-full-page="fullPage"></loading>{{$t('confirm')}}</button>
                    </div>
                </div>
             </vue-modal-2>           
            <!-- end modal change status shipping -->
            <!-- data Escel -->
            <dialog class="printingExcel" v-if="config.name=='orders'" >
                <modal-export-excel-orders :items="data" />
            </dialog>   
            <dialog class="printingExcel" v-if="config.name=='shippings'" >
                <modal-export-excel-shippings :items="data"/>
            </dialog>   
            <!-- / end dataExcel -->

             <!--Modal set service seller Shipping -->
             <vue-modal-2 v-if="config.name=='expeditions'" name="ModalChangeSetSettingsSeller" :headerOptions="{title: expeditionData.setting.exist?`Définir les services pour le nouveau warehouse de ce seller`:'Définir le warehouse pour chaque produit'}" :noFooter="true"  modalSize="lg" @on-close="$vm2.close('ModalChangeSetSettingsSeller')">
                <loading :active.sync="expeditionData.loading" :is-full-page="fullPage"></loading>
                 <div class="bg-white rounded-lg" style="margin-bottom: 60px;">

                    <div class="relative">
                        <div class="rounded-lg flex">
                            <div class="w-full p-4">
                                <div class="change-status border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                        <p>
                                            <label for="settings" class="bg-white text-gray-700 px-1">Country (warhousing)</label>
                                        </p>
                                    </div> 
                                   <p>
                                   <input v-if="expeditionData.country" type="text" disabled v-model="expeditionData.country" class="py-2 text-xs px-2 outline-none block h-full w-full">
                                  </p>
                                </div> 
                              <section class="warehouse" v-if="expeditionData.warhouseParent.exist">   
                                    <div class="flex my-5 items-center text-center">
                                        <hr class="border-gray-600 border-1 w-full rounded-md">
                                        <label class="block font-medium text-sm cursor-pointer text-green-500  w-full"><span class="font-medium text-base text-green-800 dark:text-green-200">Choisir warehouse</span> </label>
                                        <hr class="border-gray-600 border-1 w-full rounded-md">
                                    </div>
                                    <div class="bg-ww">
                                        <h2 class="text-sm font-bold truncate mb-5">Définir le warehouse pour chaque produit (<img v-if="idWharhouse" :src="idWharhouse.flag" class="h-4 w-5 blob inline-block" :alt="idWharhouse.country">)</h2> 
                                        <!-- <div class="w-full">
                                          <div v-for="it in checkWarehousingParent" :key="it._id"  :id="it._id" @click="activateWarehouse(it)" :class="{active: it.fullOf}" class="border border-gray-600 intro-y flex-1 box-1 cursor-pointer zoom-in p-2 mb-2 pl-10">
                                            <div class="check-left"><span class="feather-icon select-none relative"><svg :class="it.fullOf?'bg-green-500':'bg-gray-500'" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check p-1 w-5 h-5 text-white rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                                            <div class="text-sm font-bold text-left">{{it.name}} <span v-if="it.is_primary">(Principal)</span></div>
                                          </div>             
                                        </div> -->
                                       
                                           <div v-for="(item, index) in expeditionData.data.details" :key="index" :value="item._id" class="flex w-full space-x-12 h-auto py-1 px-8 rounded border border-gray-600 mb-2">
                                                <div>
                                                    <img
                                                    :alt="item.product.name"
                                                    :src="item.product.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'"
                                                    class="h-16 w-16 rounded-md mx-auto"
                                                    />
                                                </div>
                                                <div class="col-span-2 w-full">
                                                    <h3 class="text-gray-80">{{ item.product.name }}</h3>
                                                   <p>
                                                    <v-select  v-model="item.warehouseParent" :options="checkWarehousingParent" placeholder="Select warehouse" label="name" class="border border-gray-600">
                                                    <template slot="option" slot-scope="option">
                                                        {{ option.name }} <span v-if="option.is_primary">(Principal)</span>
                                                    </template>
                                                    </v-select>
                                                </p>
                                                   
                                                </div>
                                            </div>
                                    </div>
                              </section>  
                                 <!--services -->
                              <section v-if="expeditionData.setting.exist">   
                                <div class="flex mt-10 mb-5 items-center text-center">
                                    <hr class="border-gray-600 border-1 w-full rounded-md">
                                    <label class="block font-medium text-sm cursor-pointer text-green-500  w-full"><span class="font-medium text-base text-green-800 dark:text-green-200">Choisir les services</span> </label>
                                    <hr class="border-gray-600 border-1 w-full rounded-md">
                                </div>
                                 <div class="bg-ww"> 
                                    <h2 class="text-sm font-bold truncate mb-5">Définir les services pour le nouveau warehouse de ce seller</h2> 
                                    <div class="flex flex-col lg:flex-row">
                                      <div  id="confirmation" @click="activate('confirmation')" :class="{active: expeditionData.setting.confirmation}" class="border border-gray-600 intro-y flex-1 box-1 py-10 cursor-pointer zoom-in">
                                        <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                                       <img class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" src="https://img.icons8.com/external-outline-lafs/64/000000/external-ic_support-menu-outline-lafs.png"/>
                                        <div class="text-xl font-medium text-center mt-10">Confirmation</div>
                                      </div>
                                      <div id="fulfillement" @click="activate('fulfillement')" :class="{active: expeditionData.setting.fulfillement}" class="border border-gray-600 intro-y flex-1 box-1 py-10 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in">
                                          <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                                          <img class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" src="https://img.icons8.com/external-becris-lineal-becris/64/000000/external-warehouse-business-intelligence-becris-lineal-becris.png"/>
                                          <div class="text-xl font-medium text-center mt-10">Warehouse</div>
                                          <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                          <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
                                      </div>
                                      <div id="shipping" @click="activate('shipping')" :class="{active: expeditionData.setting.shipping}" class=" border border-gray-600 intro-y flex-1 box-1 py-10 lg:ml-5 cursor-pointer zoom-in">
                                          <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                                         <img class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" src="https://img.icons8.com/external-bartama-glyph-64-bartama-graphic/64/000000/external-Shipping-e-commerce-glyph-bartama-glyph-64-bartama-graphic.png"/>
                                          <div class="text-xl font-medium text-center mt-10">Shipping</div>
                                          <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                          <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
                                      </div>
                                    </div>
                                 </div>
                              </section>  
                           </div>
                           </div>    
                          
                         </div> 
                    
                </div>
                <div class="modal-footer  px-5 border0-t text-right">
                    <button @click="$vm2.close('ModalChangeSetSettingsSeller')" :disabled="expeditionData.loading" class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">Cancel</button>
                    <button  @click="confirmChangeExpedition" class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-500 py-2 px-8 items-center focus:outline-none md:float-right relative loidingconfirm" :disabled="expeditionData.loading"><loading :active.sync="expeditionData.loading" :is-full-page="fullPage"></loading>Confirm</button>
                </div>
            </vue-modal-2>  


            <vue-modal-2  name="messages" :headerOptions="{title: 'Info messages'}" :noFooter="true" modalSize="lg" @on-close="closePopup('messages')">
                <div class="bg-white rounded-lg p-2">
                    <div v-if="!messagesInfo.previous" class="rounded-lg">
                        <span v-if="messagesInfo.messages.length>0"> <p v-for="(msg, ll) in messagesInfo.messages" :key="ll" class="Note mx-2"><strong>Note</strong>: {{msg}}</p></span>
                        <div  v-if="messagesInfo.info.length>0" class="overflow-x-auto w-full px-4">
                            <table class="table w-full table-messages">
                                <thead>
                                    <tr class="bg-gray-700 dark:bg-dark-1 text-white text-left">
                                        <th class="whitespace-nowrap p-2">Warehouse</th>
                                        <th class="whitespace-nowrap p-2">Product</th>
                                        <th class="whitespace-nowrap p-2">Quantity {{messagesInfo.status=='paid'?'Total':'in Stock'}}</th>
                                        <th class="whitespace-nowrap p-2">NB orders</th>
                                        <th class="whitespace-nowrap p-2">NB quantity orders</th>
                                        <th class="whitespace-nowrap p-2">Info</th>
                                        <th class="whitespace-nowrap p-2">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in messagesInfo.info" :key="index" :class="'bg-'+item.type">
                                        <td class="border-b dark:border-dark-5 p-2">{{ item.warehouse }}</td>
                                        <td class="border-b dark:border-dark-5 p-2">{{ item.productName }}</td>
                                        <td class="border-b dark:border-dark-5 p-2">{{ item.inStock }}</td>
                                        <td class="border-b dark:border-dark-5 p-2">{{ item.count }}</td>
                                        <td class="border-b dark:border-dark-5 p-2">{{ item.qte }}</td>
                                        <td class="border-b p-2 dark:border-dark-5"  :class="item.type">{{ item.text }}</td>
                                        <td class="border-b p-2 dark:border-dark-5" :class="item.type">{{item.type}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> 
                    </div>
                    <div v-else style="overflow-x: auto;">
                        
                           <table v-for="(msg, ll) in messagesInfo.messages" :key="ll" class="min-w-full w-full">
                            <div v-if="msg.datas.filter(function(item) { return item.status && item.status === 'delivered' }).length>0" class="w-full border-gray-200" style="display: table-caption;">
                                <div role="alert" class="w-full bg-green-100 border-t-4 border-green-600 rounded-b text-teal-darkest px-4 py-3 shadow-md">
                                    <div class="flex justify-center"><i class="large material-icons mr-5">account_balance_wallet</i>
                                        <div>
                                            <p class="font-bold">Total Orders Delivered: <span>{{msg.datas.filter(function(item) { return item.status && item.status === 'delivered' }).length}}</span></p>
                                            <p class="font-bold">Total: {{msg.datas.filter(function(item) { return item.status && item.status === 'delivered' && item.totalPrice }).map(item => item.totalPrice).reduce((prev, next) => prev + next)}} <sup>{{idWharhouse.currency}}</sup></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                              <caption style="background-color: #20827b; color: #fff; margin-bottom: 10px; text-transform: uppercase;">{{ msg.module}} </caption>
                             
                               <thead class="relative">
                                    <tr v-if="msg.module == 'shippings'" class="bg-gray-400 text-gray-600 uppercase text-sm leading-normal">
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Order id </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Traking number </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Old Status </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> status </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Message </span></th>
                                        <th class="px-3 text-center py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Action </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> View </span></th>
                                    </tr>
                                    <tr v-if="msg.module == 'payments'" class="bg-gray-400 text-gray-600 uppercase text-sm leading-normal">
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Payment id </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Old data </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> New data </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Message </span></th>
                                        <th class="px-3 text-center py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> Action </span></th>
                                        <th class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"><span> View </span></th>
                                    </tr>
                                </thead>
                                <loading :active.sync="loadingdatashipp" :is-full-page="fullPage"></loading>
                                <tbody v-if="msg.module == 'shippings'">
                                    <tr  v-for="(item, index) in msg.datas" :key="index" class="border-b border-gray-200 hover:bg-gray-100">
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900">{{item.orderId}}</div></td>
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900">{{item.trackingNumber}}</div></td>
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900"><button class="border capitalize rounded-full text-gray-600  text-xs px-2 bg-white hover:border-gray-500 focus:outline-none appearance-none" :style="$f.ColorsStatusShippings(item.oldStatus)+';font-size: 10px;line-height: 15px;'">{{item.oldStatus}}</button></div></td>
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900"><button class="border capitalize rounded-full text-gray-600  text-xs px-2 bg-white hover:border-gray-500 focus:outline-none appearance-none" :style="$f.ColorsStatusShippings(item.status)+';font-size: 10px;line-height: 15px;'">{{item.status}}</button></div></td>
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div style="font-size: 10px;" class="text-xs leading-5 capitalize rounded-full text-gray-900">{{item.text}}</div></td>
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900" :class="item.type">{{item.type}}</div></td>
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900"><router-link target="_blank" :to="{ path: `/shippings?name=${item.trackingNumber}&country=${idWharhouse.country}`}"><i class="material-icons"> remove_red_eye</i></router-link></div></td>
                                    </tr>
                                </tbody>
                                <tbody v-if="msg.module == 'payments'">
                                    <tr  v-for="(item, index) in msg.datas" :key="index" class="border-b border-gray-200 hover:bg-gray-100">
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900">{{item.id}}</div></td>
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900">{{item.oldCount}} orders</div></td>
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900">{{item.newCount}} orders</div></td>
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div style="font-size: 10px;" class="text-xs leading-5 capitalize rounded-full text-gray-900">{{item.text}}</div></td>
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div class="text-xs leading-5 capitalize rounded-full text-gray-900" :class="item.type">{{item.type}}</div></td>
                                    <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300"><div v-if="!item.isDeleted" class="text-xs leading-5 capitalize rounded-full text-gray-900"> <router-link target="_blank" :to="{ path: `/payments/detail/${item._id}`}"><i class="material-icons"> remove_red_eye</i></router-link></div></td>
                                    </tr>
                                </tbody>
                           </table>
                           
                        </div>
                    <div class="px-4 py-2 flex float-right space-x-4">
                        <a href="#" @click="closePopup('messages')" class="px-10 py-2 text-center bg-gray-500 text-white hover:bg-gray-400  font-bold rounded-lg text-sm">OK</a>
                    </div>
                </div>
            </vue-modal-2> 

             <!--Update qte order-->
              <vue-modal-2 v-if="config.name=='shippings' && orderToUpdate.orderObjet"  name="updateorders" :headerOptions="{title: `Update details order (id: ${orderToUpdate.orderObjet.id})`}" :noFooter="true" modalSize="lg" @on-close="closePopup('updateorders')">
                <div class="bg-white rounded-lg">
                    <div class="rounded-lg flex">
                    <div class="w-1/4 flex justify-center items-center">
                        <span class="w-16 h-16 bg-green-600 text-white p-3 rounded-full"><i class="material-icons" style="font-size: 40px;">update</i></span>
                    </div>
                    <div class="w-full pr-4 mt-4">
                      <div  v-for="(it, indx) in orderToUpdate.orderObjet.details" :key="indx" class="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4">
                            <div class="grid grid-cols-1">
                              <div class="flex items-center ml-auto"><div class="flex-shrink-0 w-10 h-10"> <img alt="..." :src="it.picture?it.picture:'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="h-10 w-10 rounded-md mx-auto"></div><div class="ml-3"> {{it.name}} </div></div>
                            </div>
                            <div class="grid grid-cols-1">
                                <div class="border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                          <p>
                                              <label for="fullname" class="bg-white text-gray-700 px-1">{{$t('unit_price')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
                                          </p>
                                      </div> 
                                      <p>
                                          <input type="number"  v-model="it.unitPrice" class="py-2 text-xs px-2 outline-none block h-full w-full"> 
                                      </p>
                                  </div> 
                            </div>
                            <div class="grid grid-cols-1">
                                  <div class="border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                          <p>
                                              <label for="Email" class="bg-white text-gray-700 px-1">{{$t('quantity')}}</label>
                                          </p>
                                      </div> 
                                      <p>
                                      <input type="number" v-model="it.quantity" class="py-2 text-xs px-2 outline-none block h-full w-full"> 
                                      </p>
                                  </div> 
                            </div>
                           
                      </div> 
                       <div class="flex justify-end mt-4"><div  class="text-danger flex items-center">{{$t('total_price')}}: {{TotalPrice(orderToUpdate.orderObjet)}} <sup v-if="idWharhouse" class="uppercase">{{idWharhouse.currency }}</sup> </div></div>
                    </div>
                    
                    </div>
                    <div class="p-4 flex space-x-4">
                        <a href="#" @click="closePopup('updateorders')" class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400  font-bold rounded-lg text-sm">{{$t('cancel')}}</a>
                        <a href="#" @click="UpdateOrder()" class="w-1/2 px-4 py-3 text-center text-blue-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative" :class="loadingupdateorder?'noselect':''"><loading :active.sync="loadingupdateorder" :is-full-page="fullPage"></loading>{{$t('confirme')}}</a>
                    </div>
                </div>
            </vue-modal-2>   

            <vue-modal-2 v-if="config.name=='shippings'"  name="Refund" :headerOptions="{title: `Remboursement`}" :noFooter="true" modalSize="lg">
                <div class="rounded-lg mb-14">
                    <div class="flex flex-col border border-gray-500 px-3 mx-3 p-4 rounded-lg relative">
                        <div class="flex flex-row">
                            <div class="flex w-full mb-5">
                                <input id="inputshipping"  @input="searchShippByTrack_01($event)" placeholder="Scan qr code OR copier traking number here ..." v-model="searchTextShipp" type="search" autofill="off"  class="border-t  text-gray-700 border-gray-500  text-xs border-b border-l border-r rounded-l-md rounded-r-md w-full focus:outline-none py-1 px-4">
                                <!--<button @click="searchNewShipp" ref="sendReplyNewShipp" class="bg-white rounded-r-md text-blue-500  border-t border-r border-b hover:opacity-75  px-3 focus:outline-none">
                                    <i class="py-1 material-icons">search</i> 
                                </button>-->
                            </div>
                        </div>
                        <loading :active.sync="newShipp.loading" :is-full-page="fullPage"></loading>
                        <div v-if="newShipp.refund.data"  style="overflow-x: auto;">
                             <div class="grid lg:grid-cols-2 gap-6">
                                <div class="data-ss">
                                    <div class="row-data">
                                        <span class="title-1">Seller</span>
                                        <div class="content-1">{{newShipp.refund.data.seller.fullName}}</div>
                                    </div>
                                    <div class="row-data">
                                        <span class="title-1">Order ID</span>
                                        <div class="content-1">{{newShipp.refund.data.orderObjet.id}}</div>
                                    </div>
                                    <div class="row-data">
                                        <span class="title-1">Customer</span>
                                        <div class="content-1">{{newShipp.refund.data.orderObjet.customer.fullName}}</div>
                                        <div class="content-1">{{newShipp.refund.data.orderObjet.customer.phone}}</div>
                                    </div>
                                </div>
                                <div class="data-ss">
                                    <div class="row-data">
                                        <span class="title-1">Traking number</span>
                                        <div class="content-1">{{newShipp.refund.data.trackingNumber}}</div>
                                    </div>
                                    <div class="row-data">
                                        <span class="title-1">Shipping date</span>
                                        <div class="content-1">{{$moment(newShipp.refund.data.date).format('yyyy-MM-DD')}}</div>
                                    </div>
                                    <!-- <div class="row-data">
                                        <span class="title-1">Type</span>
                                        <div class="content-1 status" :style="colorStatus(newShipp.echange.data.type)">{{newShipp.refund.data.type}}</div>
                                    </div> -->
                                    <div class="row-data">
                                        <span class="title-1">Status</span>
                                        <div class="content-1 status" :style="colorStatus(newShipp.refund.data.status)">{{newShipp.refund.data.status}}</div>
                                    </div>
                                </div>
                                <div>

                                </div>
                             </div>
                             <div class="w-full data-ss">
                                <div class="header-1">
                                    <div class="content">
                                        <span class="title">Orders details</span>
                                        <div class="content-1">TotalPrice: {{newShipp.refund.data.orderObjet.totalPrice}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></div>
                                   </div>
                                </div>
                                <div class="header-table">
                                    <div class="xz84dc7"><div class="title">Product name</div></div>
                                    <div class="x8yj6t5"><div class="title">Quantity</div></div>
                                    <div class="x8yj6t5"><div class="title">total</div></div>
                                </div>
                                <div v-for="(item, index) in newShipp.refund.data.order.details" :key="index" class="header-table">
                                    <div class="xz84dc7"><div class="title title-1">{{item.product.name}}</div></div>
                                    <div class="x8yj6t5"><div class="title title-1">{{item.quantity}}</div></div>
                                    <div class="x8yj6t5"><div class="title title-1">{{item.quantity*item.unitPrice}}</div></div>
                                </div>
                             </div>
                        </div>  
                    </div>
                    <div class="p-4 flex space-x-4 modal-footer">
                        <a href="#" @click="OpenClosePopupModal('close','Refund')" class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400  font-bold rounded-lg text-sm">Cancel</a>
                        <a href="#" @click="EmitActionNewShipp('Refund')" class="w-1/2 px-4 py-3 text-center text-blue-100 bg-blue-600 rounded-lg hover:bg-blue-700 hover:text-white font-bold text-sm relative loidingconfirm" :class="newShipp.loading?'noselect':''"><loading :active.sync="newShipp.loading" :is-full-page="fullPage"></loading> Confirme Refund</a>
                    </div>
                </div>
            </vue-modal-2>     

           <!--double order-->
          <vue-modal-2 class="test"  name="ModalDouble" :headerOptions="{title: `Double orders (${paginateDouble.total })`}" :noFooter="true" modalSize="xl" @on-close="$vm2.close('ModalDouble')">
            <div class="flex flex-col w-full h-auto p-5 pt-0  bottom_bitt">
                <div class="align-middle inline-block min-w-full overflow-hidden bg-white shadow-dashboard rounded-bl-lg rounded-br-lg" style="margin-bottom: 62px;">
                <table class="min-w-full">
                    <thead>
                        <tr>
                            <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left leading-4  text-green-600  tracking-wider">ID Order</th>
                            <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4  text-green-600  tracking-wider">{{$t('customer')}}</th>
                            <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4  text-green-600  tracking-wider">{{$t('products')}}</th>
                            <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4  text-green-600  tracking-wider">{{$t('total_price')}}</th>
                             <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4  text-green-600  tracking-wider">{{$t('date')}}</th>
                            <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4  text-green-600  tracking-wider">{{$t('status')}}</th>
                            <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-right text-sm leading-4  text-green-600  tracking-wider">{{$t('action')}}</th>
                            
                        </tr>
                    </thead>
                    <loading :active.sync="loadingDouble" :is-full-page="fullPage"></loading>
                    <tbody class="bg-white">
                        <tr v-for="(order,ind) in dataDouble" :key="ind">
                            <td class="border-t-2 border-gray-300 px-3 py-1">
                                <div class="flex items-center">
                                    <div>
                                        <div class="text-sm leading-5 text-gray-800">{{order.id}}</div>
                                    </div>
                                </div>
                            </td>
                            <td class="border-t-2 border-gray-300 px-3 py-1">
                                <div class="text-sm leading-5 siz12 text-blue-900">
                                  <div class="flex-grow-1">
                                    <h5 class="fs-14">{{order.customer.fullName}}</h5>
                                    <p class="badge badge-soft-success text-uppercase">{{order.customer.phone}}</p></div>
                                  
                                </div>
                            </td>
                            <td class="border-t-2 border-gray-300 px-3 py-1">
                                <div class="text-sm leading-5 text-blue-900"><b style="background-color: #e8bc52;">{{sumProduct(order.details)}}</b></div>
                            </td>
                            <td class="px-3 py-1 text-left border-t-2 border-gray-300 whitespace-no-wrap">
                                <div class="text-sm leading-5 text-blue-900">{{order.totalPrice}} <sup v-if="idWharhouse">{{idWharhouse.currency}}</sup> </div>
                            </td>
                            <td class="px-3 py-1 text-left border-t-2 border-gray-300 whitespace-no-wrap">
                                <div class="text-sm leading-5 text-blue-900">{{$moment(order.date).format('yyyy-MM-DD') }}</div>
                            </td>
                             <td class="border-t-2 border-gray-300 px-3 py-1">
                                <div class="text-sm leading-5 text-blue-900" v-bind:style="{color: order.status.name?order.status.color:'',borderColor: order.status.name?order.status.color:''}">{{order.status.name}}</div>
                            </td>
                            <td class="border-t-2 border-gray-300 px-3 py-1 text-right">
                               <router-link target="_blank" :to="{ path: `/orders/detail/${order._id}`}" class="text-sm leading-5 px-2 rounded-full text-xs text-blue-900 cursor-pointer font-medium"><i class="material-icons mx-2"> remove_red_eye</i></router-link>
                            </td>
                           
                        </tr>
                        </tbody> 
                    </table>

                      <!--pagination -->
                    <div class="p-1" v-if="paginateDouble.lastpage > 1" >
                        <div class="flex flex-col items-center mt-2">
                            <div-paginate :paginate="paginateDouble" @paginatation="paginatationDouble" />
                        </div>
                    </div>
                    <!--end pagination -->
                            
            </div>     
                
            </div>

            <div class="modal-footer px-5 border0-t text-right">
                <button @click="$vm2.close('ModalDouble')" class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">Cancel</button>
            </div>
          </vue-modal-2>    

  <vue-modal-2 class="test" v-if="config.name === 'sourcings'"   name="chatMessages" :headerOptions="{title: `Messages de discussion (ID SOURCING: ${dataSourcing.data.id})`}" :noFooter="true" modalSize="md" @on-close="CloseModal('chatMessages')">

           <div class=""> 
             <div class="chat-main-wrapper">    
               <div class="col-lg-9 message-view task-view">
                
                    <div class="chat-window">
                       
                        <div class="chat-contents">
                            <div class="chat-content-wrap">
                                 <div class="chat-wrap-inner">
                                      <div class="chat-box">
                                           <div class="chats messages" ref="msgContainer">

                                            <span class="cc-1lcq" v-if="Object.entries(dataSourcing.data.messages).length === 0">
                                                <span class="cc-r03y cc-1v0h cc-179w">Des questions? Discute avec nous!</span>
                                            </span>


                                            <template v-for="(item,ind) in Object.entries(dataSourcing.data.messages)" v-chat-scroll>
                                                <div class="chat-line">
                                                     <span class="chat-date">{{item[0]}}</span>  
                                                </div>

                                                <div v-for="(mess, indd) in item[1]" :key="item[0]+'-'+indd" class="chat message" :class="mess.access.type ===  currentUser.type2?'chat-right':'chat-left'">
                                                    <div v-if="mess.access.type !== currentUser.type2" class="chat-avatar">
                                                       <span class="avatar"><img :src="currentUser.type === 'Seller'? imageRm :'https://img.icons8.com/color/96/circled-user-male-skin-type-1-2--v1.png'" alt="User Image"></span>
                                                    </div>
                                                    <div class="chat-body">
                                                       <div class="chat-bubble">
                                                            <div class="chat-content" :ref="ind === Object.entries(dataSourcing.data.messages).length - 1 && indd === item[1].length - 1?`collapse-${dataSourcing.data._id}`:'no'">
                                                                    <p v-if="mess.type ==='text'">{{mess.message}}</p>
                                                                    <span v-else class="chat-img-attach">
                                                                        <expandable-image  class="expandable-img" :src="mess.message" alt="img-pr"/>
                                                                    </span>
                                                                    <span class="chat-time">{{mess.__v}}</span>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>

                                            

                                                <!-- <div class="chat chat-right">
                                                    <div class="chat-body">
                                                       <div class="chat-bubble">
                                                            <div class="chat-content">
                                                                    <p>Hello. What can I do for you?</p>
                                                                    <span class="chat-time">8:30 am</span>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat-line">
                                                     <span class="chat-date">October 8th, 2018</span> 
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar">
                                                      <a href="profile.html" class="avatar"><img src="https://img.icons8.com/color/96/circled-user-male-skin-type-1-2--v1.png" alt="User Image"></a>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-bubble">
                                                            <div class="chat-content">
                                                                <p>I'm just looking around.</p>
                                                                <p>Will you tell me something about yourself? </p>
                                                                <span class="chat-time">8:35 am</span>
                                                            </div>
                                                           
                                                        </div>
                                                        <div class="chat-bubble">
                                                            <div class="chat-content">
                                                                <p>Are you there? That time!</p>
                                                                <span class="chat-time">8:40 am</span>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-right">
                                                      <div class="chat-body">
                                                         <div class="chat-bubble">
                                                            <div class="chat-content">
                                                                    <p>Where?</p>
                                                                    <span class="chat-time">8:35 am</span>
                                                            </div>
                                                           
                                                         </div>
                                                         <div class="chat-bubble" :id="'scroll-chat-bottom-'+dataSourcing.data._id">
                                                            <div class="chat-content">
                                                                <p>OK, my name is Limingqiang. I like singing, playing basketballand so on.</p>
                                                                <span class="chat-time">8:42 am</span>
                                                            </div>
                                                            
                                                        </div>
                                                     </div>
                                                </div> -->

                                            </div>
                                      </div>
                                </div>
                            </div>
                        </div>   

                            <div class="chat-footer">
                                <div class="message-bar">  
                                    <div class="message-inner" :class="dataSourcing.data.status === 'rejected'?'noselect':''">
                                        <span @click="openPopup('ImageMessages')" class="link2 attach-icon"><img src="https://img.icons8.com/ios-filled/100/attach.png" alt="Attachment Icon"></span>
                                        <div class="message-area">
                                            <div class="input-group">
                                                <input v-model="dataSourcing.message" class="form-control" placeholder="Type message..." @keyup.enter="addMessageTriger" />
                                                <button :disabled="dataSourcing.loading" :class="dataSourcing.loading?'noselect':''" @click="addMessage('sourcings','message')" ref="sendReply" class="btn btn-custom  loidingconfirm relative" type="button"><loading :active.sync="loadingupdateorder" :is-full-page="fullPage"></loading> <img src="https://img.icons8.com/ios-filled/100/FFFFFF/paper-plane.png" alt="Attachment Icon"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>

                        </div>
                </div>
            </div>
        </div> 

            <!-- <div class="modal-footer px-5 border0-t text-right">
                <button @click="$vm2.close('chatMessages')" class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">Fermer</button>
            </div> -->

          </vue-modal-2>   



 <vue-modal-2 class="uploadImage" v-if="config.name === 'sourcings'"   name="ImageMessages" :headerOptions="{title: `Télécharger une image`}" :noFooter="true" modalSize="md" @on-close="CloseModal('ImageMessages')">

            <div  class="">
                <div class="relative rounded">
                
                    <div class="flex w-full mt-10 mb-120 items-center justify-center bg-grey-lighter">
                        <label class="flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:text-green-800">
                            <svg  v-if="!dataSourcing.picture" class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <img v-if="dataSourcing.picture" :src="dataSourcing.picture" alt="image message"  style="height: 200px;"/>
                            <span v-else class="mt-2 text-base leading-normal"> {{$t('select_image')}} ('jpg', 'jpeg', 'png')</span>
                            <input type='file' @change="handleImage($event,'chat')" class="hidden" accept="image/*"   />

                            <span v-if="!dataSourcing.picture" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                                <p>La taille de image doit pas dépasser 500 ko</p>
                            </span>
                        </label>
                    </div>

                </div>
            </div>

            <div class="modal-footer px-5 border0-t text-right"> 
                <button :disabled="dataSourcing.loading" :class="dataSourcing.loading?'noselect':''" @click="addMessage('sourcings','image')" class="flex text-white mx-auto max-w-sm rounded text-center bg-green-500 py-2 px-8 items-center focus:outline-none md:float-right loidingconfirm relative"><loading :active.sync="dataSourcing.loading" :is-full-page="fullPage"></loading> Soumettre</button>
            </div>

            </vue-modal-2>   

           <!-- Modal CONFIRM LIVREUR STATUS-->
            <vue-modal-2 name="modalConfirm" :headerOptions="{title: 'Confirm Status'}" :noFooter="true" modalSize="md" @close="CloseModal('modalConfirm')">
                <div class="flex flex-col w-full h-auto">
                <!-- Header -->
                <div class="bg-white rounded px-2  pb-2 flex flex-col">
                    <p class="font-bold text-center">Veuillez sélectionner un statut valide pour cette commande ⚠️ 📣</p>
                    <div class="p-5 pb-2">
                        <div class="border border-gray-700 animation-pulse   w-full h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                <!-- <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                    <p>
                                    <label for="seller" class="bg-white text-gray-700 px-1">{{$t('select_seller')}} <span :class="$colors.required">*</span></label>
                                    </p>
                                </div> -->
                                <p>
                                    <v-select placeholder="Status ..."  v-model="newShipp.enterLivruers.newStatus" :options="newShipp.enterLivruers.status" ></v-select>
                                </p>
                                
                         </div>
                         <div class="border border-gray-700 animation-pulse mt-2   w-full h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                              
                                <p class="">
                                    <input autocomplete="false" placeholder="Commentaire ..."
                                      type="text" v-model="newShipp.enterLivruers.comment"
                                        class="w-full p-1"
                                         />
                                </p>
                         </div>
                    </div>
                    
                    <div class="flex p-2 mt-4">
                        <div class="flex-auto flex flex-row-reverse"> 
                            <button  @click="ConfirmStatusLivreur"   class="w-1/3 p-3 btn-action rounded bg-blue-600 hover:bg-blue-500 text-white font-semibold focus:outline-none"> Confirm </button>
                            <!-- <button :disabled="loading"  @click="CloseModal('modalConfirm')" class="w-1/3 p-3 mr-4 rounded bg-gray-600 hover:bg-gray-500 text-white font-semibold focus:outline-none"> Annuler</button> -->
                        </div>
                    </div>
                </div>
               </div>
            </vue-modal-2>
         <!-- End Modal CONFIRM LIVREUR STATUS -->

     

        </div>          
    </div>
</template>

<!--  ************  -->
<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import addFontNormal from '@/assets/fonts/WorkSans-normal';
import addFontBold from '@/assets/fonts/WorkSans-bold';
import imageScanner from "@/assets/scanner.png"
import { mixin as clickaway } from "vue-clickaway";
import image from "@/assets/CODINAFRICA.png";

    export default {
   mixins: [clickaway],
        props: {
            config: { type: Object},
            data:{ default: [] },
            element: {type: Object },
            loading:{required:false},
            CheckQteDefecteuseexist:{required:false},
            CheckQteReturnedexist:{required:false},
            CheckQteDefecteuseorderexist:{required:false},
            CheckExpeditionRecived:{required:false},  
            name: { type: String, default: "" },
            emailCurrentCall: { type: String, default: "" },
            paginate: { type: Object },
            statusOrder:{ type: Array },
            zones:{ type: Array },
            couriers:{ type: Array },
            limit:{ type: Number },
            statusExpedition:{ type: Array },
            statusShipp:{ type: Array },
            statusShippChange:{ type: Array },
            tabId:{ type: Array },
            typesShipp:{ type: Array },
            transports:{ type: Array },
            isFilterCourier:{required:false},
            CourierInfo: {type: Object },
            selectProduct:{type: Object },
            producWarhouse:{type: Array,default: function () {return []} },
            idWharhouse: { type: Object},
            currentUser: { type: Object},
            sous_idWharhouse: { type: String,default() {return null}},
            sous_warehouses: { type: Array,default() {return []}},
            DivFilter:{ type: Boolean,default() {return true}},
        },
        data() {
            return {
                imageScannerShipp: imageScanner,
                imageRm : image ,
                showcourier:false,
                open:false,
                change_status:{
                    statusTochange:'',
                    courier:null
                },
                 shipping: {
                    address: "",
                    type: "normal",
                    date: "",
                    status:'to prepare',
                    order:{ type: Object },
                    zone:{ type: Object },
                    courier:{ type: Object },
                },
                shippingChange:{
                    type: "pendingchange",
                    date: '',
                    status:'to prepare',
                    order:{ type: Object },
                    zone:{ type: Object },
                    courier:{ type: Object },
                },
                fullPage: false,
                loadingdatashipp:false,
                filters: [],
                actions: [],
                newSeller: {},
                roles: [],
                categories: [],
               // currentUser: {},
                courier:[],
                checkQte:false,
                loadingChange:false,
                custumerTest:"Moukhtar ouakat",
                ShippCode:"Moukhtar ouakat",
                ChangeQte:{
                    operation:'Addition',
                    stock:'inStock-total',
                    quantity:0,
                    loading:false,
                    warehouse:null,
                    seller:null,
                    warhouseParent:null,
                    settings:null
                },
                typeoperations:['Addition','Subtraction'],
                typeostocks:['inStock', 'total','inStock-total','defective'],
                dataCountry: this.$countries,
                openLimit:false,
                openAnalytics:false,
                warehouses:[],
                currency:"",
              //  idWharhouse:null,
                limitdata:0,
                filters:{},
                feesShipp:null,
                loadingcreateShipp:false,
                postponed:{_id:null,date:null,comment:undefined,index:null,status:undefined},
                expeditionData:{model:null,loading:false,data:{},country:null,warhouseParent:{exist:false,warehouse:null},setting:{exist:false,confirmation:false,fulfillement:false,shipping:false,warehouse:null}},
                checkSetting:null,
                checkWarehousingParent:[],
                cities:[],
                loadingpdf:false,
                messagesInfo:{messages:[],info:[],status:'status'},
                searchTextShipp:"",
                orderToUpdate:{details:[]},
                loadingupdateorder:false,
                dataExcel:{loading:false,data:[]},
                timer: 0,
                dataSourcing:{loading:false,data:{messages:{}},message:'',picture:''},
                dataSelected:{ loading: false, data: {},product:null },
                newShipp:{
                    status:null,courier:false,
                    settings:{sortie:false,entree:false},
                    previous:false,
                    dauchete:false,
                    shippings:[],
                    tabId:[],
                    loading:false,
                    refund:{
                        data:null,
                    },
                    textButton:'Next',
                    enterLivruers:{status:["reprogrammer","return"],newStatus:"",comment:"",data:null}
                },
               statusPrevious:["to prepare","shipped","reprogrammer"],
               loadingDouble:false,
               dataDouble:[],
               paginateDouble: {
                total: 0,
                currentpage: 1,
                lastpage: 0,
                per_page: 0,
               },
               filterDouble:{},
               currentSettings:this.$store.state.settings.find(el => el.country === this.idWharhouse.country),
               currentSettingsAll:this.$store.state.settings,
               currentSettingsGlobal:this.$store.state.settingsGlobal,
               
            };
        },
        async mounted() {
           // await this.getUser();
            //await this.getwharhouseId();
           // await this.getWarehouses();
            if(this.config.name=='orders')  await this.getCities();
            this.filters=this.$f.QueryStringToobject();
            if(this.filters && this.filters["limit"]) this.limitdata=this.filters["limit"];
            console.log('statusShippChange-----------',this.statusShippChange)
        },
        computed: {
            warhouseSelected(){
               return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
            },
        },
        watch: {
            'warhouseSelected': async function(oldVal, newVal) {
               //  await this.getwharhouseId();
               if(this.config.name==='orders')  await this.getCities();
                
            },
            'newShipp.courier': async function(oldVal, newVal) {
                console.log('new courier detected----',oldVal)
               if(oldVal) this.emtyDataShipp('watch_courier');
            },
            'newShipp.settings.sortie': async function(oldVal, newVal) {
                console.log('new settings detected----',oldVal)
                this.emtyDataShipp('watch_settings');
            },
        },

        methods: {
            getStyleByName(name){
                var colorsNmae=[{name:'private',style:'color:red;border: 1px solid red;'},{name:'public',style:'color:green;border: 1px solid green'},{name:'validé',style:'color:green;border: 1px solid green'},{name:'paid',style:'color:green;border: 1px solid green'},{name:'not paid',style:'color:orange;border: 1px solid orange'},{name:'pending',style:'color:orange;border: 1px solid orange'},{name:'approved',style:'color:green;border: 1px solid green'},{name:'rejected',style:'color:red;border: 1px solid red'}];
                if(colorsNmae.find(el=> el.name === name.toLowerCase())) return colorsNmae.find(el=> el.name === name.toLowerCase()).style;
            },
            async GeneratePayment(name, data, index) {
                console.log('GeneratePayment name-----',name)
                console.log('GeneratePayment data-----',data)
                if (name === 'credits') {
                    const groupedData = data.reduce((acc, item) => {
                    if (!acc.ids) {
                        acc.ids = [];
                        acc.codes= [];
                        acc.warehouse = item.warehouse;
                        acc.service = item.service;
                        acc.amount = [];
                        acc.method = item.method;
                        acc.status = item.status;
                    }
                    
                    acc.ids.push(item.id);
                    acc.codes.push(item.code);
                    acc.amount.push(item.amount);
                    
                    return acc;
                    }, {});
                    
                    this.creditData = groupedData;
                }

                if (name === 'pointages') { 
                    const FilterLink = this.$f.QueryStringToobject();

                    if (FilterLink.Range_date.split(',').length !== 2) {
                    this.$toast.warning("Error Range date, please actulisé la page");
                    return false;
                    }

                    if (!FilterLink.country || (FilterLink.country && FilterLink.country.length !== 2)) {
                    this.$toast.warning("Error Warehouse selected, please actulisé la page");
                    return false;
                    }

                    this.dataPointage.formData = data;
                    this.dataPointage.formData.periode = { from: new Date(FilterLink.Range_date.split(',')[0]), to: new Date(FilterLink.Range_date.split(',')[1]) };
                    this.dataPointage.formData.country = FilterLink.country;
                    this.dataPointage.formData.warehouse = this.idWharhouse._id;

                    this.openPopup('pointage');
                    const { content: userData } = await this.$server.get("users", { id: this.dataPointage.formData.user._id, notPopulate: true });
                    if (userData && userData.classements && userData.classements.delivery) this.dataPointage.formData.user.classement = userData.classements.delivery;

                }

                if (name === 'affiliates') { 
                    

                    this.$confirm("Êtes-vous sûr de demander cette offre ?").then(async (res) => {
                            if (res) {
                                this.dataSelected.data=data;
                                this.dataSelected.loading=true;
                                console.log('data form-----',data)
                                const { content: dataAffiliate } = await this.$server.create("affiliates",data);
                                this.dataSelected.loading=false;
                                console.log('dataAffiliate---------',dataAffiliate)
                                this.dataSelected.data={};
                                if(dataAffiliate && dataAffiliate._id) return this.$toast.success("Votre demande a ete envoyé avec succès");
                                else return this.$toast.warning(dataAffiliate);
                            
                            }
                    });

                }
                },
            async ChangeStatus(dataToEdit,index,name) {
                    console.log('dataToEdit------',dataToEdit)
                    console.log('name------',name)
                    
                    if(name === "balancesHistories" && dataToEdit.status === 'paid') {
                    
                        this.dataSelected.data=dataToEdit;
                        this.dataSourcing.picture=dataToEdit.proof;
                        return this.$vm2.open('ImageMessages');
                        
                    }else{
                        this.$confirm("Est vous sûre pour faire le changement ?").then(async (res) => {
                            if (res) {
                                let bodyData={};
                                if(name === "products") bodyData={_id:dataToEdit._id,visibility:dataToEdit.visibility};
                                if(name === "affiliates") bodyData={_id:dataToEdit._id,status:dataToEdit.status};
                                if(name === "payments") bodyData={_id:dataToEdit._id,status:dataToEdit.status,changementStatus:true,country:this.idWharhouse.country};
                                if(name === "balancesHistories") bodyData={_id:dataToEdit._id,status:dataToEdit.status,changementStatus:true};
                                this.dataSelected.data=dataToEdit;
                                let nameTable='affiliates';
                                if(name === 'payments') nameTable="payments"
                                if(name === 'balancesHistories') nameTable="balancesHistories"
                                this.dataSelected.loading=true;
                                const data = await this.$server.update(nameTable,bodyData);
                                this.dataSelected.loading=false;
                                this.dataSelected.data={};
                                console.log('data------',data)
                                if (data && data._id){
                                    if(['affiliates','payments','balancesHistories'].includes(name)) this.data[index].status=dataToEdit.status;
                                    return this.$toast.success(`Updated successfully`);
                                }else{
                                    if(['affiliates','payments','balancesHistories'].includes(name)) this.data[index].status=dataToEdit.oldStatus;
                                    console.log('data not found ------',data)
                                    return this.$toast.warning(data.content||data);
                                } 

                                //  setTimeout(() => (this.loading = false), 1200);
                                //  await this.refresh();
                            }
                        });
                    }

                    
                
                },
                async addMessage(name,type){
                 if(!this.dataSourcing.message && type === 'message') return false;
                 if(!this.dataSourcing.picture && type === 'image'){
                    this.$toast.warning("No image upload");
                    return false;
                 }
                 if(this.dataSourcing.picture && type === 'image' && !this.checkSize('chat')){
                    this.$toast.warning("la taille de image doit pas dépasser 500 ko !!");
                    return false;
                 }
                 if(!this.dataSourcing.data._id){
                    this.$toast.warning("Data sourcing not Found");
                    return false;
                 }
                
                 this.dataSourcing.loading=true;
                 let bodyData={ _id: this.dataSourcing.data._id, message: this.dataSourcing.message,type:'text'};
                 if(type === 'image') {
                    bodyData.type='image';
                    bodyData.picture=this.dataSourcing.picture;
                 }   
                 const data = await this.$server.update("sourcings",bodyData);
                 this.dataSourcing.loading=false;
                 if(data && data._id){
                    this.dataSourcing.message="";
                    //this.dataSourcing.picture="";
                    this.dataSourcing.data.messages= this.groupedMessage(data.messages);
                    if(type === 'image') this.CloseModal('ImageMessages');
                    this.scroolMessageToBottom();
                 }else this.$toast.warning(data);
            },
            addMessageTriger(){
              this.$refs.sendReply.click();
            },
         
            handleImage(e,name){
                console.log('event img----',e)
                const selectImage=e.target.files[0];
                console.log('selectImage----',selectImage)
                if(selectImage) this.createBase64Image(selectImage,name);
            },
            createBase64Image(fileObjet,name){
                const reader=new FileReader();
                reader.readAsDataURL(fileObjet);
                if(name === 'chat'){
                    reader.onload= ()  =>{
                       this.dataSourcing.picture= reader.result;
                    };
                }
                
            },
            niceBytes(x){
                const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
                let l = 0, n = parseInt(x, 10) || 0;
                while(n >= 1024 && ++l){
                    n = n/1024;
                }
                return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
            },
            checkSize(name){
                if(name === 'chat'){
                    if(this.dataSourcing.picture){
                        let base64Length = this.dataSourcing.picture.length - (this.dataSourcing.picture.indexOf(',') + 1);
                        let padding = (this.dataSourcing.picture.charAt(this.dataSourcing.picture.length - 2) === '=') ? 2 : ((this.dataSourcing.picture.charAt(this.dataSourcing.picture.length - 1) === '=') ? 1 : 0);
                        let fileSize = base64Length * 0.75 - padding
                        let sizeImage = this.niceBytes(fileSize);
                        let typeSize=sizeImage.split(' ')[1];
                        if(typeSize==="bytes" || typeSize==="KB"){
                            if(sizeImage.split(' ')[0]<=500) return true;
                            else return false;
                        }else return false;
                    }else return true;
                }

                return false;  
            },
            CloseModal(name,isSave=null){
                if(name === 'ImageMessages'){
                    if(this.dataSourcing.loading) return false
                    //this.dataSourcing.picture=""; 
                }
               this.$vm2.close(name);
               console.log('name-----',name)
               console.log('isSave-----',isSave)
               if(name === 'modalConfirm' && !isSave){
                    this.$toast.warning("La commande pas ajouter dans la listes");
                    this.newShipp.enterLivruers.newStatus=""
                    this.newShipp.enterLivruers.comment=""
                    this.newShipp.enterLivruers.data=null
                    return false;
               }
            },
            scroolMessageToBottom(){
                this.$nextTick(function() {
                        var container = this.$refs.msgContainer;
                        console.log('container------',container)
                        container.scrollTop = container.scrollHeight + 100000;
                        console.log('container he------',container.scrollHeight)
                });
            },
            groupedMessage(data){
                console.log('data is array ------',Array.isArray(data))
                console.log('data 11------',data)
                // data= data.forEach(arg => {
                //     arg.date = this.$moment(arg.date).lang("fr").format('LL');
                // });
                // data=_.chain(data).groupBy("type").map((value, key) => ({ date: key, datas: value })).value();
                // console.log('data 22------',data)
                let finalObj = {}
                if(Array.isArray(data)){
                    data.forEach((games) => {
                        games.__v=this.$moment(games.date).format('HH:mm a');
                        const date = this.$moment(games.date).lang("fr").format('LL');
                        if (finalObj[date]) {
                        finalObj[date].push(games);
                        } else {
                            finalObj[date] = [games];
                        }
                    })
                    console.log('data 22------',finalObj)
                    return finalObj;
                }else return data
               
            },
            removeOrder(data, index) {
                this.$confirm(this.$t('Confirm delete order')).then(
                    async (res) => {
                       var index = this.newShipp.shippings.findIndex(function(o){return o._id.toString() === data._id.toString();})
                       if (index !== -1) this.newShipp.shippings.splice(index, 1);
                       this.$toast.success("Order deleted in the listes")
                    }
                );
            },
            async getOrderDoubled(filter){
                this.loadingDouble=true;
               
                if(filter.page) this.filterDouble.page=filter.page;
                const res = await this.$server.search("orders",this.filterDouble);
                this.loadingDouble=false;
                if(res && res.content && res.content.results){
                    this.dataDouble=res.content.results;
                    this.paginateDouble.total = res.content.total;
                    this.paginateDouble.lastpage = res.content.last_page;
                    this.paginateDouble.per_page = res.content.per_page;
                    this.paginateDouble.currentpage = res.content.current_page;
                } else{
                    this.dataDouble=[];
                    this.paginateDouble={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}
                }
            },
            async paginatationDouble(direction) {
                var page = 0;
                if (direction == "next") {
                    page = ++this.paginate.currentpage;
                } else {
                    page = --this.paginate.currentpage;
                }
                this.filterDouble['page']=page;

                this.getOrderDoubled(this.filterDouble);
            },
            sumProduct(data){
                let product="";
                for (let i in data) {
                    if(data[i].product && data[i].product.name) product+=data[i].product.name
                    if(data.length-1 != i) product+=" ,"; 
                }
                return product;
            },
            cc_format(value) {
                var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
                if (v.length > 6)
                    v = [v.slice(0, 6), ' ', v.slice(6)].join('');
                if (v.length > 11)
                    v = [v.slice(0, 11), ' ', v.slice(11)].join('');
                if (v.length > 14)
                    v = [v.slice(0, 14), ' ', v.slice(14)].join('');
                if (v.length > 16)
                    v = [v.slice(0, 16), ' ', v.slice(16)].join('');
                return v;
            },
            searchShippByTrack_01: _.debounce(async function(e) {
                console.log('oooooooooooooooooooook',e.target.value)
                if (typeof this.searchTextShipp !== 'undefined' && this.searchTextShipp) {
                    this.newShipp.loading=true;
                    const res = await this.$server.get("shippings", {country:this.idWharhouse.country,trackingNumber:this.searchTextShipp.trim().toUpperCase()});
                    console.log('res fin shipp',res)
                    this.newShipp.loading=false;
                    if(res && res.content && res.content._id) this.newShipp.refund.data=res.content;
                    else { alert('Aucune commande trouvée', "warning");this.newShipp.refund.data=null;}
                    this.searchTextShipp="";
                }
            }, 500),
            colorStatus(status) {
                switch (status) {
                    case 'to prepare':
                        return "color: rgb(233 147 19); border-color: rgb(233 147 19);" 
                        break;
                    case 'prepared':
                        return  "color:rgb(58 217 18); border-color: rgb(58 217 18);" 
                        break;
                    case 'shipped':
                        return  "color:rgb(58 217 18); border-color: rgb(58 217 18);" 
                        break;
                    case 'delivered':
                        return  "color:rgb(58 217 18); border-color: rgb(58 217 18);" 
                        break;
                    case 'paid':
                        return  "color:rgb(34, 150, 78); border-color: rgb(34, 150, 78);" 
                        break;  
                    case 'refunded':
                        return  "color:rgb(150 15 227); border-color: rgb(150 15 227);" 
                        break;   
                    case 'processed':
                                return  "color:rgb(10 10 10); border-color: rgb(10 10 10);" 
                        break;     
                    case 'cancelled':
                        return "color: rgb(255, 5, 5); border-color: rgb(255, 5, 5);" 
                        break;
                       
                }
           },
            checkChangeStatusPrevieus(status){
              if(status && this.newShipp.previous){
                    let columns = document.querySelectorAll('.checkallbboxShipp');
                    columns.forEach(column => {column.checked = false});
                    this.newShipp.shippings=[];
                    this.newShipp.tabId=[];
              }
            },
             async getFeesShippBycity(city){
                this.feesShipp=null;
                const res = await this.$server.search("zones",{cities:city});
                if(res.content.results)  this.feesShipp=res.content.results[0].price;
            },
             TotalPrice(data){
                let Total=0;
                    for (const element of data.details) {
                        Total+=element.unitPrice * element.quantity;
                    }
                    data.totalPrice=Total;
                    return Total;
             },
            async ChangeQuantityOrder(data){
                if(data.orderObjet.details.length>1) {
                    alert('tu peux pas modifier cette order car contient des produit different', "warning");
                    return false;
                }
               console.log('ChangeQuantityOrder 1111----------',data)
            //    for (let i in data.orderObjet.details) {
            //         const editData = await this.$server.get("products", {id:data.orderObjet.details[i].product,});
            //         data.orderObjet.details[i].name=editData.content.name;
            //         data.orderObjet.details[i].picture=editData.content.picture
            //     }
               this.orderToUpdate=data;
               this.openPopup('updateorders');
            },
            activateWarehouse:function(el){
                console.log('el  1111',this.checkWarehousingParent)
                this.checkWarehousingParent.forEach(function (warehouse) {if (warehouse._id.toString() == el._id.toString()) warehouse.fullOf = true;else warehouse.fullOf = false;});
                this.expeditionData.warhouseParent.warehouse=el;
                console.log('el  222',this.checkWarehousingParent)
            },
            async UpdateOrder(){
                for (let i in this.orderToUpdate.orderObjet.details) {
                   if(!parseInt(this.orderToUpdate.orderObjet.details[i].quantity) || parseInt(this.orderToUpdate.orderObjet.details[i].quantity)==0){
                       alert('Quantité required !!!', "warning");
                       return false;
                   }
                }
                this.loadingupdateorder=true;
                console.log('ChangeQuantityOrder 2222----------',this.orderToUpdate)
                this.orderToUpdate['UpdateQteOrder']=true;
                const res= await this.$server.update("shippings", this.orderToUpdate);
                this.loadingupdateorder=false;
                console.log('res 2222----------',res)
                if (res && res._id) {
                    const index = this.data.findIndex(shipp => shipp._id.toString()===res._id.toString());
                    this.data[index].order.details[0].quantity = res.orderObjet.details[0].quantity;
                    this.data[index].order.details[0].unitPrice = res.orderObjet.details[0].unitPrice;
                     this.data[index].order.totalPrice = res.orderObjet.totalPrice;
                    alert('Quantite order updated', "success");
                    this.closePopup('updateorders');
                }else alert(res, "warning");
               

            },
            closePopup(name){
               this.$vm2.close(name);
            },
           async openPopup(name){
                this.$vm2.open(name);
            },
            async getCities(){
               const resCities = await this.$server.get("zones", { allCities: true,country:await this.warhouseSelected}); 
                    if(resCities.content.length>0)  this.cities= resCities.content;
                    else this.cities=[];
            },
            activate:function(el,courier=null){
                if(courier){
                    console.log('el----',el)
                   this.newShipp.settings[el] = !this.newShipp.settings[el];
                   if(el === 'entree') this.newShipp.settings['sortie'] =false;
                   else this.newShipp.settings['entree'] =false;
                } 
                else this.expeditionData.setting[el] = !this.expeditionData.setting[el];
            },
            async getwharhouseId(){
                await this.$store.dispatch('wharhouse/getwharhouseId',{country:await this.warhouseSelected}).then((res) => {
                        this.idWharhouse=res;
                    })
            },
            async getCurrency(){
                await this.$store.dispatch('wharhouse/getCurrency').then((res) => {
                        this.currency=res;
                    })
            },
            async getWarehouses(){
                const filter = {limit:10};
                const res = await this.$server.search("warehouses",filter);
                if (res.content.results) {
                    this.warehouses = res.content.results;
                } else this.warehouses = [];
           },
           setSelectedWharhouse(warehouse) {
                if(warehouse){
                    this.ChangeQte.warehouse=warehouse;
                }
           }, 
           away() {
             this.open = false; 
            },  
            awayLimit(){
               this.openLimit = false;
            },
             exceptText(text,name=null){
                let lent=40;
                if(name) lent=20;
                if (text && text.length > lent) text = text.substring(0, lent) + "...";
               return text;
            },
            serchsellerFilter(){
                this.debounce(function(){
                //  console.log('serch______________',this.)
                },500)
            },
            debounce(func, wait, immediate) {
                var timeout;
                return function() {
                var context = this, args = arguments;
                var later = function() {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                    if (callNow) func.apply(context, args);
                };
            },
            getElementInArray(data,value){
                var result  = data.filter(function(o){
                    let warhouse=o.warehouse;
                    if(o.warehouse._id) warhouse=o.warehouse._id;
                    
                    return warhouse == value.toString();
                } );
                return result? result[0] : null; // or undefined
            },
            changedQte(event){
                    this.checkQte=false;
                    if(this.ChangeQte.operation=="Subtraction" && parseInt(ExistWarhouse.quantity.inStock)<parseInt(this.ChangeQte.quantity)){
                        this.checkQte=true;
                    }
                    if(this.ChangeQte.operation=="Subtraction" && parseInt(ExistWarhouse.quantity.total)<parseInt(this.ChangeQte.quantity)){
                        this.checkQte=true;
                    }
                     if(this.ChangeQte.operation=="Subtraction" && parseInt(ExistWarhouse.quantity.defective)<parseInt(this.ChangeQte.quantity)){
                        this.checkQte=true;
                    }
                
            },
            async getUser() {
              const res = await this.$me;
               this.currentUser = res.content;
               console.log('user',this.currentUser.role.permissions)
            },
            isEmpty(value){
                if(value == null || value == "" || value == " ") return true;
                else return false;
            },
            async getCurrentCourierZone(){
                if(this.shipping._id){
                    const filter = {
                        type: 'Courier',
                        zones:this.shipping.zone._id
                    };
                    const res = await this.$server.search("users",filter);
                    if (res.content.results) {
                        this.courier = res.content.results;
                    } else this.courier = [];
                }  
            }, 
            async CheckDataShip(item){
              if (item._id) {
                const editData = await this.$server.get("shippings", {
                  order: item._id,
                }); 
                if(editData.content._id){
                   this.shipping = editData.content;
                }else{
                     const nDate = new Date().toLocaleString('en-US', {timeZone: 'Africa/Casablanca'});
                    let newdate=new Date(nDate);
                    this.shipping={};
                    this.shipping.order=item._id;
                    this.shipping.seller=item.seller;
                    this.shipping.status="to prepare";
                }   
                     
              }
            },
            async getCourierZone(event){
                this.courier = [];
                const filter = {
                    type: 'Courier',
                    zones:event.target.options[event.target.options.selectedIndex].id
                };
                const res = await this.$server.search("users",filter);
                if (res.content.results) {
                    this.courier = res.content.results;
                } else this.courier = [];
            },
            formattedDate(date,formatdate=null) {
                let format="yyyy-MM-DD HH:mm";
                if(formatdate) format=formatdate;
                if(this.config.name=="shippings") format="yyyy-MM-DD";
                var date=this.$moment(date).format(format);
                if(date=='Invalid date') return null;
                else return  date;
            },  
            async emitFunction(action,id,data){
               if(action==="delete") {
                   if(this.config.name == 'products'){
                      const resExp = await this.$server.search("expeditions", {"details.product":id});
                      const resOrd = await this.$server.search("orders", {"details.product":id});
                        if(resExp.content.results || resOrd.content.results) alert(this.$t('product_already_used'), "warning");
                        else this.remove(id);

                    } else{
                       this.remove(id);
                    }
                  
                }
               if(action==="print") {this.generatePdf(id,data);}
               if(action==="double") {
                  this.$vm2.open('ModalDouble');
                  this.filterDouble={'details.product':data.details[0].product._id,'customer.country':data.customer.country,phone:data.customer.phone,seller:data.seller._id};
                  await this.getOrderDoubled(this.filterDouble);
               }
               if(action==="messages") {
                   console.log('yes mess',data)
                   this.dataSourcing.data=data;
                   this.dataSourcing.loading=true;
                   const dataSour = await this.$server.update("sourcings",{ _id:data._id, watch:true});
                   const findItemSourcing = this.data.findIndex(o => o._id.toString() === id.toString());
                    if (findItemSourcing != -1) {
                        this.data[findItemSourcing].Nb_views = 0;
                    } else return alert('Une error est produit refresh page svp et try again', 'warning');
                   console.log('yes dataSour',dataSour)
                   this.dataSourcing.loading=false;
                   if(dataSour) this.dataSourcing.data.messages=dataSour.messages;
                   this.dataSourcing.data.messages=this.groupedMessage(this.dataSourcing.data.messages);
                   this.dataSourcing.message="";
                   this.$vm2.open('chatMessages');
                   this.scroolMessageToBottom();
                   console.log('this.dataSourcing.data.messages------',this.dataSourcing.data.messages)
               }
            },
            paginatation: function (val) {
                this.$emit('paginatation',val);
            },
            refresh: function () {
                this.$emit('refresh');
            },
            reset: function (val) {
                this.$emit('reset',val);
            },
            filtrer: function (val) {
                this.$emit('filtrer',val);
            },
            search: function (val) {
                this.$emit('search',val);
            },
            showSousTable: function (val) {
                this.$emit('showProducts',val);
            },
            CheckQteDefecteuse: function (val) {
                this.$emit('CheckQteDefecteuse',val);
            },
            CheckQteReturned: function (val) {
                this.$emit('CheckQteReturned',val);
            },
            CheckQteDefecteuseOrder: function (val) {
                this.$emit('CheckQteDefecteuseOrder',val);
            },
            CheckQteSentExist: function (val) {
               this.$emit('CheckQteSentExist',val);
            }, 
            generateListPdf: function () {
                this.$emit('generateListPdf');
            },
            downloadAnalytics: function () {
                this.$emit('downloadAnalytics');
            },
            downloadCsv: function () {
                if(this.config.name=='orders') this.$emit('downloadOrders');
                if(this.config.name=='shippings') this.$emit('downloadShippings');
            },
            downloadExcel:async function () {
                var nameTable=this.config.name;
               
                var htmls = "";
                var uri = 'data:application/vnd.ms-excel;base64,';
                var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'; 
                var base64 = function(s) {
                    return window.btoa(unescape(encodeURIComponent(s)))
                };

                var format = function(s, c) {
                    return s.replace(/{(\w+)}/g, function(m, p) {
                        return c[p];
                    })
                };

                let table =document.getElementById("p-table");

                if(this.config.name === 'payments'){
                    let warehousesSys=[];
                    this.dataExcel.loading=true;
                    const resW = await this.$server.search("warehouses", {limit:100});
                    if (resW && resW.content.results) warehousesSys=resW.content.results;

                    this.dataExcel.data=[];table='';
                    
                    
                    let filtersP={country:this.idWharhouse.country};
                    if(this.$f.QueryStringToobject()) filtersP=this.$f.QueryStringToobject();
                    //filtersP.country=this.idWharhouse.country;
                    filtersP.country=warehousesSys.map(e=>e.country).join('-');
                   
                   // return false
                  //  console.log('filter export to excell ----',filtersP)
                    var itemsCounts=50;
                    filtersP.limit=itemsCounts;
                    filtersP.page = 1;
                   // filtersP['orderObjet.feesShipp']=-1;
                    var count = Math.ceil(this.paginate.total/itemsCounts);
                    var close=false;
                   
                    console.log('total ----',this.paginate.total)
                    console.log('count  ----',count)
                    for (var i = 0; i <count; i++) {
                        if(close) return;
                        //this.countDown(this.timer);
                        const resAllshipp = await this.$server.search(this.config.name,filtersP);
                       // console.log('resAllshipp export to excell ----',resAllshipp)
                         //return false
                        if(resAllshipp.content.results) {
                            this.timer+=resAllshipp.content.results.length;
                            filtersP.page++;
                            this.dataExcel.data.push(...resAllshipp.content.results);
                        }else close=true  

                        if(close) {this.timer=0;this.dataExcel.loading=false;alert("An error occurred, please reload the page and try again !!", "error"); }
                    }

                   // const resAllshipp = await this.$server.search("shippings",filtersP);
                    if(this.dataExcel.data.length>0) {
                        this.timer=0;
                        this.dataExcel.loading=false;
                        console.log('dataExcel--------------',this.dataExcel.data)

                        table=`
                        <table style="width: 100%;border-collapse: separate;text-indent: 0;border-spacing: 2px;">
                            <thead> 
                                <tr>
                                    <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " rowspan="3" > Seller </th> 
                                    <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " rowspan="3" > RIB </th> 
                                    <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " rowspan="3" > Paiement par  </th> 
                                    <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " rowspan="3" > Nombre des orders </th> 
                                    <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " rowspan="3" > Nombre des orders Upselling </th> 
                                    <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " colspan="${warehousesSys.length > 0 ? warehousesSys.length + 1 : 1}" > Warehouses <sup>(MAD)</sup> </th> 
                                    <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " rowspan="3" > Crédits <sup>(MAD)</sup> </th>
                                    <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " rowspan="3" > Reste <sup>(MAD)</sup> </th>
                                </tr> 
                                <tr>`;
                                    for (const wraehouse of warehousesSys) {
                                       table+= `<th style="background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; "> ${wraehouse.name} </th>`;
                                    }  
                                    table+= `<th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a; color: #fff;" rowspan="2" > Total </th> 
                                </tr> 
                                <tr> `;
                                    for (const wraehouse of warehousesSys) {
                                       table+= `<th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; "> Amount </th> `;
                                    }  
                                    table+= `</tr>
                            </thead>
                            <tbody>`;
                                
                                if(this.dataExcel.data.length == 0){
                                    table+=`<tr><th class="border p-3 bg-gray-100" colspan="8">${this.$t("no_record_found")}</th></tr>`;
                                }    

                                for (const payment of this.dataExcel.data) {
                                    table+= 
                                    `<tr>
                                        <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ payment.seller.fullName || '' }</td>
                                        <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ payment.seller.rib?this.cc_format(payment.seller.rib.toString()):''  }</td>
                                        <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ payment.seller.paymentBy || ''  }</td>
                                        <td width="250" style="font-weight: bold; border: 1px solid #4e504a;text-align:center">${ payment.count  }</td>
                                        <td width="250" style="font-weight: bold; border: 1px solid #4e504a;text-align:center">${ payment.details.filter(item => item.upsell_vat).length  }</td>`;
                                            for (const wraehouse of warehousesSys) {
                                                table+=`<td style="background-color: #1ba903; border: 1px solid #4e504a;font-weight: bold;color: #fff;text-align:center"> ${ this.getMadAmount(payment, wraehouse, 'byCountry') ? Math.round(this.getMadAmount(payment, wraehouse, 'byCountry')) : '' } </td>`;
                                            }  

                                        table+= `<td style="background-color: #1ba903; border: 1px solid #4e504a;font-weight: bold;color: #fff;text-align:center">${ this.getMadAmount(payment, null, 'byMad') ? Math.round(this.getMadAmount(payment, null, 'byMad')) : '' } </td> 
                                        <td style="background-color: #ed3434; border: 1px solid #4e504a;font-weight: bold;color: #fff;text-align:center">${ this.getMadAmount(payment, null, 'byCredits') ? Math.round(this.getMadAmount(payment, null, 'byCredits')) : '' } </td>
                                        <td style="background-color: #1ba903; border: 1px solid #4e504a;font-weight: bold;color: #fff;text-align:center">${ Math.round((this.getMadAmount(payment, null, 'byMad')-this.getMadAmount(payment, null, 'byCredits'))) } </td>
                                    </tr>`;
                                }
                       
                        table+= `</tbody> </table>`;  
                        let parser = new DOMParser();
                        let doc = parser.parseFromString(table, 'text/html');
                        table=doc.body;
                    }else this.dataExcel.loading=false;

                     
                }

                if(this.config.name === 'expeditions' || this.config.name === 'products' || this.config.name === 'charges'){

                    this.dataExcel.loading=true;
                    this.dataExcel.data=[];table='';
                    console.log('oook expp')
                    
                    
                    
                    let filtersP={country_to:this.idWharhouse.country};
                    if(this.$f.QueryStringToobject()) filtersP=this.$f.QueryStringToobject();
                    
                    var itemsCounts=100;
                    filtersP.limit=itemsCounts;
                    filtersP.page = 1;
                   // filtersP['orderObjet.feesShipp']=-1;
                    var count = Math.ceil(this.paginate.total/itemsCounts);
                    var close=false;
                   
                    console.log('total ----',this.paginate.total)
                    console.log('count  ----',count)
                    for (var i = 0; i <count; i++) {
                        if(close) return;
                        //this.countDown(this.timer);
                        const resAllshipp = await this.$server.search(this.config.name,filtersP);
                       // console.log('resAllshipp export to excell ----',resAllshipp)
                         //return false
                        if(resAllshipp.content.results) {
                            this.timer+=resAllshipp.content.results.length;
                            filtersP.page++;
                            this.dataExcel.data.push(...resAllshipp.content.results);
                        }else close=true  

                        if(close) {this.timer=0;this.dataExcel.loading=false;alert("An error occurred, please reload the page and try again !!", "error"); }
                    }

                   // const resAllshipp = await this.$server.search("shippings",filtersP);
                    if(this.dataExcel.data.length>0) {
                        this.timer=0;
                        this.dataExcel.loading=false;
                        console.log('dataExcel--------------',this.dataExcel.data)

                        table=`
                        <table style="width: 100%;border-collapse: separate;text-indent: 0;border-spacing: 2px;">
                            <thead>`;
                                if(this.config.name === 'expeditions'){
                                    table+= 
                                    ` <tr>
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " > ID </th> 
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; "  > Seller </th> 
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; "  > TRANSPORT MODE </th> 
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " > FROM </th>
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " > EXPEDITION DATE </th>
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " > Products </th>
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " > FEES </th>
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; "  > TYPE </th>
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; "  > STATUS </th>
                                    </tr> `;
                                }
                                if(this.config.name === 'products'){
                                    table+= 
                                    ` <tr>
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " > ID </th> 
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; "  > Seller </th> 
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; "  > NAME </th> 
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " > IN STOCK </th>
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " > TOTAL </th>
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " > DEFECTIVE </th>
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " > DATE CREATION </th>
                                    </tr> `;
                                }
                                 if(this.config.name === 'charges'){
                                    table+= 
                                    ` <tr>
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " > ID </th> 
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; "  > Seller </th> 
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; "  > COMMENT </th> 
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; " > DATE CREATION </th>
                                        <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; "  > AMOUNT </th> 
                                         <th style=" background-color: #19706a; font-weight: bold; border: 2px solid #4e504a;color: #fff; "  > STATUS </th> 
                                    </tr> `;
                                }
                               
                                table+= ` </thead>
                            <tbody>`;
                                
                                if(this.dataExcel.data.length == 0){
                                    table+=`<tr><th class="border p-3 bg-gray-100" colspan="7">${this.$t("no_record_found")}</th></tr>`;
                                }    

                                for (const exp of this.dataExcel.data) {
                                    if(this.config.name === 'expeditions'){
                                      table+= `
                                        <tr>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.id }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.seller.fullName || '' }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.transportMode }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${  this.$f.getCountryNameByCode(this.dataCountry,exp.country) }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${  this.$moment(exp.date).format('yyyy-MM-DD')  }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ this.ProductGroupNmae(exp.details)}</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.fees||0 }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.type }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.status }</td>
                                        </tr>`;
                                    }
                                    if(this.config.name === 'products'){
                                      table+= `
                                        <tr>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.id }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.seller.fullName || '' }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.name }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.details.find(item => item.warehouse && item.warehouse.is_primary &&  item.warehouse.country === this.idWharhouse.country)?exp.details.find(item => item.warehouse && item.warehouse.is_primary &&  item.warehouse.country === this.idWharhouse.country).quantity.inStock:'0' }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${  exp.details.find(item => item.warehouse && item.warehouse.is_primary &&  item.warehouse.country === this.idWharhouse.country)?exp.details.find(item => item.warehouse && item.warehouse.is_primary &&  item.warehouse.country === this.idWharhouse.country).quantity.total:'0' }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.details.find(item => item.warehouse && item.warehouse.is_primary &&  item.warehouse.country === this.idWharhouse.country)?exp.details.find(item => item.warehouse && item.warehouse.is_primary &&  item.warehouse.country === this.idWharhouse.country).quantity.defective:'0' }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ this.$moment(exp.date).format('yyyy-MM-DD') }</td>
                                        </tr>`;
                                    }
                                    if(this.config.name === 'charges'){
                                      table+= `
                                        <tr>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.id }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.seller.fullName || '' }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.comment }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${  this.$moment(exp.date).format('yyyy-MM-DD')  }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.amount||0 }</td>
                                            <td width="250" style="font-weight: bold; border: 1px solid #4e504a;">${ exp.payment && exp.payment._id?'Applied':'Not applied' }</td>
                                        </tr>`;
                                    }
                                   
                                }
                       
                        table+= `</tbody> </table>`;  
                        let parser = new DOMParser();
                        let doc = parser.parseFromString(table, 'text/html');
                        table=doc.body;
                    }else this.dataExcel.loading=false;

                     
                }

                htmls =table.outerHTML;
                //console.log('html--------------',htmls)
                var ctx = {
                    worksheet : nameTable,
                    table : htmls
                }

                var link = document.createElement("a");
                link.download = nameTable+".xls";
                link.href = uri + base64(format(template, ctx));
                link.click();
            },
            ProductGroupNmae(data){
                let product="";
                for (let i in data) {
                    if(data[i].name) product+=data[i].name+` (quantity sent: ${data[i].quantity.sent}, quantity received: ${data[i].quantity.received})</span>`;
                    if(data.length-1 != i) product+=" | "; 
                }
                return product;
            },
            getMadAmount(data, wraehouse = null, type = '') {
            //  console.log('data------', data);
                let dataPay = 0;
                if (!data.amountCurrency) data.amountCurrency = [];
                if (!data.infoCurrency) data.infoCurrency = [];
                if (!data.amountExpeditionsCurrency) data.amountExpeditionsCurrency = [];
                if (!data.amountRefundsCurrency) data.amountRefundsCurrency = [];
                if (!data.amountChargesCurrency) data.amountChargesCurrency = [];

                if (type === 'byCountry') {
                    dataPay = data.amountCurrency.find(
                    (e) => e.country == wraehouse.country
                    );
                    if (dataPay) {
                    const objC = data.infoCurrency.find(
                        (o) => o.country === wraehouse.country
                    );
                    dataPay = dataPay.amount / objC.currency;
                    }
                } else if (type === 'byCredits') {

                    const creditExpedtions = data.amountExpeditionsCurrency
                    .map((item) => {
                        const objC = data.infoCurrency.find((o) => o.currencyName === item.currency);
                        return item.fees_amount / objC.currencyCredit;
                    })
                    .reduce((prev, curr) => prev + curr, 0);

                    const creditRefunds = data.amountRefundsCurrency
                    .map((item) => {
                        const objC = data.infoCurrency.find(
                        (o) => o.currencyName === item.currency
                        );
                        return item.fees_amount / objC.currency;
                    })
                    .reduce((prev, curr) => prev + curr, 0);

                    const creditCharges = data.amountChargesCurrency
                    .map((item) => {
                        const objC = data.infoCurrency.find(
                        (o) => o.currencyName === item.currency
                        );
                        return item.fees_amount / objC.currencyCredit;
                    })
                    .reduce((prev, curr) => prev + curr, 0);

                    dataPay =(creditExpedtions || 0) + (creditRefunds || 0) + (creditCharges || 0);

                } else {
                    dataPay = data.amountCurrency
                    .map((item) => {
                        const objC = data.infoCurrency.find(
                        (o) => o.currencyName === item.currency
                        );
                        return item.amount / objC.currency;
                    })
                    .reduce((prev, curr) => prev + curr, 0);
                }

                return dataPay;
            },
            showPrConfirmOperationUpdateoducts: function (data,index) {
                this.$emit('ConfirmOperationUpdate',val);
            },
            selectAllCheckbox: function ($event) {
                this.$emit('selectAllCheckbox',$event);
            },
            getRowDetail: function (data,id) {
                this.$emit('getRowDetail',data,id);
            },
            emtyTabId: function () {
                this.$emit('emtyTabId');
            },
            searchSellers: function (value,event) {
                this.$emit('searchSellers',value,event);
            },
            searchCouriers: function (value,event) {
                this.$emit('searchCouriers',value,event);
            },
            Changelimit: function (val) {
                this.openLimit=false;
                this.$emit('Changelimit',val);
            },
            EmitMethodAction(action){
                console.log(action)
              if(action=="addPay") this.$router.push('/payments/new');
              if(action=="printdInvoice") this.generatePdf();
              if(action=="print") this.generateListPdf();
              if(action=="changestatus") this.OpenClosePopupModal('open');
              if(action=="printdDelivry") this.generatePdf();
              if(action=="addPrd") this.$router.push('/products/new');
              if(action=="addqte") {this.OpenClosePopupModal('open');}
              if(action=="deleteorders" && this.config.name==="orders") {
                 if (this.tabId.length  > 0 ) {
                 
                    this.$confirm("Are you sure to delete this orders ?").then(async (res) => {
                            if(res){
                            console.log('tab id====',this.tabId)
                            const data = {ids:this.tabId} 
                            this.loadingpdf=true;
                            const ress = await this.$server.deleteMany("orders", data);
                            this.loadingpdf=false;
                            if(ress) {await this.refresh(); alert("delete orders", "success"); this.emtyTabId(); }
                        }
                    }); 
                       
                       
                    }else alert(`No ${this.config.name} selected !`, "warning");
              }
            },
            async ConfirmReporte(action){
              if(!this.postponed.date) {
                  alert(this.$t('postponed_date_required'), "warning");
                  return false;
              }
              this.postponed.status="remind";
              const res = await this.$server.update("shippings", this.postponed);
               if (res) {
                    this.closePopUpInformation(this.postponed.index);
                    this.postponed.date=null;this.postponed.comment=undefined; this.postponed.status=undefined;
                    this.OpenClosePopupModalReporte('close','postponed');
                    alert(this.$t('shippings_updated'), "success"); 
               }
            },
            async confirmChange(){
                this.messagesInfo= {messages:[],info:[],status:'status'}
                if (this.change_status.statusTochange == "") {
                    alert(this.$t('no_status_selected'), "warning");
                }else{
                   if(!this.change_status.courier && this.change_status.statusTochange=="shipped"){
                     alert("Courier required !", "warning");
                     return false;
                   }
                   if(this.config.name=="orders" && this.change_status.statusTochange.name=="Pending"){
                       console.log('this.tabId.------',this.tabId)
                       console.log('transafer ------',this.tabId.filter(obj => (obj.status === "Orange money" || obj.status === "Pending" || obj.status === "Unreached")).length)
                     if(this.tabId.filter(obj => (obj.status === "Orange money" || obj.status === "Pending" || obj.status === "Unreached")).length != this.tabId.length){
                        alert("Please sélectionner seulement les ordres de status Orange money or Pending traits !", "warning");
                        return false
                     } 
                     //return false
                   }
                    this.$confirm(`You are sure to do this action, Total orders to ${this.change_status.statusTochange.name||this.change_status.statusTochange} : ${this.tabId.length}`).then(async (action) => {
                        if (action) {
                            //-----------------//
                            if(this.config.name === "orders"){
                                let message=null;
                                var itemsCounts=50;
                                var count = Math.ceil(this.tabId.length/itemsCounts);
                                var close=false;
                                this.loadingChange = true;
                                for (var o = 0; o < count; o++) {
                                    if(close) return;
                                    var s= this.tabId.slice(o*itemsCounts,o*itemsCounts + itemsCounts)
                                    const data = {
                                        ids:s,
                                        status:this.change_status.statusTochange,
                                        courier:this.change_status.courier,
                                        warhouse:this.idWharhouse._id,
                                        country:this.idWharhouse.country
                                    }   
                                    const res = await this.$server.updateMany(this.config.name, data);
                                    if(!res || (res && !res.nModified)){
                                        close=true;
                                        message="Une erreur c'est produite svp essaie encore: "+res;
                                    } 

                                    if(close) this.loadingChange=false;
                                }
                                this.loadingChange=false;
                                if(message) alert(message, "warning"); 
                                else{
                                    alert(this.$t('orders_updated'), "success");  
                                    this.open = false;
                                    this.change_status.statusTochange="";
                                    this.emtyTabId();
                                    this.OpenClosePopupModal('close');
                                    await this.refresh();
                                }
                            }else{
                                this.loadingChange = true;
                                const data = {
                                    ids:this.tabId,
                                    status:this.change_status.statusTochange,
                                    courier:this.change_status.courier,
                                    warhouse:this.idWharhouse._id,
                                    country:this.idWharhouse.country
                                }   
                                const res = await this.$server.updateMany(this.config.name, data);
                                this.loadingChange = false;
                                console.log('res updatemany)-----------',res)
                                if (res) {
                                    this.showcourier=false;
                                    this.open = false;
                                    this.change_status.statusTochange="";
                                    this.change_status.courier=null;
                                    this.emtyTabId();
                                    this.OpenClosePopupModal('close');
                                    await this.refresh();
                                    let messa='shippings_updated';
                                    if(this.config.name=="orders") messa='orders_updated';  
                                    if((res.messages && res.messagesStock) || (res.nModified && !res.messages)){
                                        if(res.messages && res.messagesStock){
                                        if(res.messages.length==0 && res.messagesStock.length==0) alert(this.$t(messa), "success");  
                                        else {this.$vm2.open('messages');this.messagesInfo.messages=res.messages;this.messagesInfo.info=res.messagesStock;this.messagesInfo.status=res.status;}
                                        }else alert(this.$t(messa), "success");     
                                    }else alert(res, "warning");  

                                } else  alert(res, "warning");  
                            }
                           

                            //-----------///
                      } 
                    });
                }
            },
            
            async confirmChangeQte(){
                console.log('this.idWharhouse-------',this.idWharhouse)
              this.ChangeQte.warehouse=this.idWharhouse;
              this.ChangeQte.seller=this.selectProduct.seller;
              let data = {_id:this.tabId[0],seller:this.selectProduct.seller,actionInstock:this.ChangeQte,dataWhrahouseSetting:this.expeditionData.dataWhrahouseSetting};
              if(!this.ChangeQte.warehouse){
                    alert("Warehouse not detected (svp actualiser la page)", "warning");
                    return false;
              }
              
              if(this.expeditionData.warhouseParent.exist && !this.expeditionData.warhouseParent.warehouse){
                alert("Warehouse est obligatoir", "warning");
                return false;
              }
              this.ChangeQte.loading = true;
              if(this.expeditionData.setting.exist) {this.ChangeQte.settings=this.expeditionData.setting;this.ChangeQte.settings.warehouse=this.ChangeQte.warehouse._id;data.dataWhrahouseSetting=this.expeditionData.setting;}
              if(this.expeditionData.warhouseParent.exist) this.ChangeQte.warhouseParent=this.expeditionData.warhouseParent.warehouse;
              console.log('ChangeQte--------------------------',this.ChangeQte)
              console.log('ChangeQte--------------------------',this.expeditionData)
              //return false

                
                // if(parseInt(this.ChangeQte.quantity)==0){
                //     alert(this.$t('quantity_greater_zero'), "warning");  
                //     return false;
                // }
                
                const res = await this.$server.update("products", data);
                this.ChangeQte.loading = false;
                if (res) {
                    //this.loading = false;
                    this.checkSetting=null;
                    this.expeditionData.setting={exist:false,confirmation:false,fulfillement:false,shipping:false,warehouse:null},
                    this.expeditionData.warhouseParent={exist:false,warehouse:null},
                    this.ChangeQte={
                        operation:'Addition',
                        stock:'inStock-total',
                        quantity:0,
                        loading:false,
                        warehouse:null,
                        seller:null,
                        warhouseParent:null,
                        settings:null
                   };
                    this.emtyTabId();
                    this.OpenClosePopupModal('close');
                    await this.refresh();
                    alert(this.$t('product_updated'), "success");  
                } 
                
            },
            getRowDetailShipp(item, id){
                console.log('item-----',item)
                let itemInRow = this.tabId.filter(item => item.id === id);
                let isItemInRow = itemInRow.length > 0;
                    
                if (isItemInRow) { 
                    let pos = this.newShipp.tabId.map(function (e) {return e.id;}).indexOf(id);
                    this.newShipp.tabId.splice(pos, 1);
                } else {
                    this.newShipp.tabId.push({id:id,newStatus:item.newStatus||'',comment:item.comment||'',source:item.seller.source?item.seller.source:'nosource',order:item.order._id,trackingNumber:item.trackingNumber||'',upsel:item.upsel||'false',courier:item.courier?item.courier._id||item.courier:'',status:item.status,orderobjet:JSON.stringify(item.orderObjet)});
                }
                if(this.newShipp.tabId.length === 0){
                    let columns = document.querySelectorAll('.checkallbboxShipp');
                    columns.forEach(column => {column.checked = false});
                }
            },
            selectAllCheckboxShipp($event){
                let columns = document.querySelectorAll('.rowCheckboxShipp');
                this.newShipp.tabId = [];
                if ($event.target.checked == true) {
                    columns.forEach(column => {
                        column.checked = true;             
                        this.newShipp.tabId.push({id:column.id,newStatus:column.getAttribute('newStatus'),comment:column.getAttribute('comment'),source:column.getAttribute('seller'),order:column.getAttribute('order'),status:column.getAttribute('status'),courier:column.getAttribute('courier'),trackingNumber:column.getAttribute('trackingNumber'),upsel:column.getAttribute('upsel')||'false',orderobjet:column.getAttribute('orderobjet')});
                    });
                } else {
                   columns.forEach(column => {column.checked = false});
                   this.newShipp.tabId = [];
                }
            },
            searchShippByTrack: _.debounce(async function(e) {
                  console.log('oooooooooooooooooooook',e.target.value)

                if (typeof this.searchTextShipp !== 'undefined' && this.searchTextShipp) {
                        let filters={
                            limit:1,
                            trackingNumber:this.searchTextShipp.toString().trim().toUpperCase(),
                            country:this.idWharhouse.country
                        };
                        let index = this.newShipp.shippings.findIndex(element => element.trackingNumber.toString().trim().toUpperCase() === this.searchTextShipp.toString().trim().toUpperCase()); 
                       
                        if (index === -1) {   

                            if(this.newShipp.dauchete && this.newShipp.settings.entree){
                                filters.courier=this.newShipp.courier._id||this.newShipp.courier;
                            }

                            this.newShipp.loading=true;
                            const res = await this.$server.search("shippings", filters);
                            console.log('res dauch----',res)
                            this.newShipp.loading=false;
                            if(res && res.content.results && res.content.results.length>0){

                                if(this.newShipp.previous){
                                    //check is same status
                                    if(res.content.results[0].status == this.newShipp.status) alert(`Please select other shipping nin ${this.newShipp.status}`, "warning"); 
                                    else {
                                        if(
                                        (this.newShipp.status == 'delivered' && ['processed','paid','cancelled','refused','return','outofstock'].indexOf(res.content.results[0].status) == -1) || 
                                        (this.newShipp.status == 'shipped' && ['processed','paid','delivered','cancelled','refused','return','outofstock','reprogrammer'].indexOf(res.content.results[0].status) == -1) || 
                                        (this.newShipp.status == 'reprogrammer' && ['shipped'].indexOf(res.content.results[0].status) == -1) || 
                                        (this.newShipp.status == 'prepared' && ['processed','paid','delivered','shipped','cancelled','refused','return','outofstock'].indexOf(res.content.results[0].status) == -1) ||
                                        (this.newShipp.status == 'to prepare' && ['processed','paid','delivered','shipped','prepared','cancelled','refused','return','outofstock'].indexOf(res.content.results[0].status) == -1) ||
                                        (this.newShipp.status == 'paid' && ['processed'].indexOf(res.content.results[0].status) == -1)
                                        ) alert(`Only to previous status accepted !`, "warning");
                                        else{
                                            console.log('dfdfdfdff---',['processed','paid','delivered','cancelled','refused','return','outofstock'].indexOf(res.content.results[0].status))
                                            this.newShipp.shippings.push(res.content.results[0]);
                                            this.searchTextShipp="";  
                                        }                               
                                    }
                                }else if(this.newShipp.dauchete){
                                    const resData=res.content.results[0];

                                    if(this.newShipp.settings.sortie){

                                        if(resData.status !== 'prepared' && resData.status !== 'reprogrammer'){
                                            let mess="La commande déjà Shipped";
                                            if(resData.status === 'to prepare')  mess="La commande pas encore préparé";
                                            this.$toast.warning(mess);
                                            this.searchTextShipp="";  
                                            return false;
                                        }

                                        if(resData.courier){
                                            this.$toast.warning("La commande déjà assigné par un autre Livreur");
                                            this.searchTextShipp="";  
                                            return false;
                                        }

                                        this.newShipp.shippings.push(resData);
                                       // location.hash = "#shippp";
                                        this.searchTextShipp="";   

                                    }else{

                                        if(resData.status !== 'shipped'){
                                            this.$toast.warning("La commande déjà traitée (Seulment les commandes Shipped)");
                                            this.searchTextShipp="";  
                                            return false;
                                        }

                                        if(!resData.newStatus){
                                            this.$vm2.open('modalConfirm');
                                            this.newShipp.enterLivruers.data=resData;
                                            return false;
                                        }
                                       
                                      //  resData.newStatus="delivered";

                                        this.newShipp.shippings.push(resData);

                                        // const container = this.$refs.shippp;
                                        // container.scrollTop = container.scrollHeight;

                                        this.searchTextShipp=""; 

                                    }
                                }else{
                                    this.newShipp.shippings.push(res.content.results[0]);
                                    this.searchTextShipp="";   
                                }
                            }else this.$toast.warning("Aucun commande trouvé");
                            
                        }else{
                            this.$toast.warning("La commande existe déjà dans la liste");
                            this.searchTextShipp="";
                        }
                }
            }, 500),
            ConfirmStatusLivreur(){
                if(!this.newShipp.enterLivruers.newStatus){
                        this.$toast.warning("Statut obligatoire !!!!!");
                        return false
                }else{
                    if(this.newShipp.enterLivruers.data){
                        this.newShipp.enterLivruers.data.newStatus=this.newShipp.enterLivruers.newStatus;
                        this.newShipp.enterLivruers.data.comment=this.newShipp.enterLivruers.comment;
                        this.newShipp.shippings.push(this.newShipp.enterLivruers.data);
                        this.searchTextShipp=""; 
                        this.CloseModal('modalConfirm','save');
                        this.newShipp.enterLivruers.newStatus="";
                        this.newShipp.enterLivruers.comment=""
                    } 
                   
                } 
            },
            async EmitActionNewShipp(name){
                if(name=='Refund'){
                    if(this.newShipp.refund.data){
                        console.log('data ref-----',this.newShipp.refund)
                            if(this.newShipp.refund.data.refund){
                                alert("Cette order est deja remboursé !", "warning");
                                return false;
                            }
                          
                            if(this.newShipp.refund.data.status != 'processed'){
                                alert("Seulement pour les shipping  processaid !", "warning");
                                return false;
                             }

                        this.$confirm(`You are sure to do this action`).then(async (action) => {
                            if (action) {
                                
                                try {
                                    this.newShipp.loading=true;
                                    this.newShipp.refund.data.Isrefund=true;
                                    const res = await this.$server.create("shippings",this.newShipp.refund.data);
                                    console.log('res----------',res)
                                    this.newShipp.loading=false;
                                    if(res && res._id) {
                                        this.OpenClosePopupModal('close','Refund');
                                        alert("Shipping rembourser created", "success"); 
                                        let routeData = this.$router.resolve({name: 'shippings', query: {name: res.trackingNumber,country:this.idWharhouse.country}});
                                        this.newShipp.refund.data=null;
                                        window.open(routeData.href, '_blank');
                                    }else alert(res, "warning");  
                                }catch (error) { 
                                    console.log('error----------',error)
                                    this.newShipp.loading=false;                        
                                    alert(error, "warning");  
                                }
                            
                        }
                        });            

                    }else alert("Data order not found, please refresh page and try again !", "warning");
               }else{
                    //check button next
                    if(this.newShipp.dauchete){
                        if(!this.newShipp.courier) {this.$toast.warning("Livreur obligatoire");return false;}
                        if(!this.newShipp.settings.sortie && !this.newShipp.settings.entree)  {this.$toast.warning("Type action Livreur obligatoire");return false;}
                    }
                    //  console.log('odd,d,d,d',this.newShipp.shippings)
                    // return false

                   this.messagesInfo= {messages:[],info:[],status:'status'};
                   if(this.newShipp.tabId.length>0){
                         if(this.newShipp.shippings.length !== this.newShipp.tabId.length){
                            this.$toast.warning('Nb Shipping selected incorrect, svp désélectionner et sélectionner à nouveau');  
                            return false;
                         }
                        let messA=`Vous êtes sûr de faire cette action, Total commandes à ${this.newShipp.status}: ${this.newShipp.tabId.length}`;
                        if(this.newShipp.dauchete) messA=`Vous êtes sûr de faire cette action, Total commandes: ${this.newShipp.tabId.length}`;

                        this.$confirm(messA).then(async (action) => {
                            if (action) {
                                
                                let data = {
                                    ids:this.newShipp.tabId,
                                    status:this.newShipp.status,
                                    warhouse:this.idWharhouse._id,
                                    country:this.idWharhouse.country
                                } 

                                if(this.newShipp.previous) data.previous=true;
                                if(this.newShipp.dauchete){
                                    data.dauchete=true;
                                    data.settingDauchete=this.newShipp.settings;
                                    if(this.newShipp.settings.sortie) data.status="shipped";
                                    data.courier=this.newShipp.courier._id||this.newShipp.courier;
                                }

                                console.log('data shipp______________',data)
                                this.loadingdatashipp = true;
                                const res = await this.$server.updateMany("shippings", data);
                                this.loadingdatashipp = false;
                                console.log('res shipp______________',res)

                                if(res) {
                                    
                                    if(res.messages && res.messagesStock){
                                        if(res.messages.length==0 && res.messagesStock.length==0){
                                            alert('Shippings updated', "success");  
                                            this.$vm2.close('ModalChangeStatusByDauchette');
                                            this.emtyDataShipp();
                                            await this.refresh();  
                                        }else{
                                            this.$vm2.close('ModalChangeStatusByDauchette'); 
                                            this.$vm2.open('messages');

                                            this.messagesInfo.messages=res.messages;
                                            this.messagesInfo.info=res.messagesStock;
                                           
                                            this.messagesInfo.status=res.status;
                                            if(res.previous || res.dauchete) {
                                                this.messagesInfo.previous=true;
                                                this.emtyDataShipp();
                                              if(res.previous)  await this.refresh();  
                                            }
                                        }
                                    }else this.$toast.warning(res,{duration:2000}); 
                                }else this.$toast.warning(res,{duration:2000}); 
                            }
                        });        

                   }else this.$toast.warning("No shippings selected !");
                }
            },
            emtyDataShipp(watch=null){
                let columns = document.querySelectorAll('.checkallbboxShipp');
                columns.forEach(column => {column.checked = false});
                this.newShipp.shippings=[];
                this.newShipp.tabId=[];
                this.newShipp.loading=false;
                this.newShipp.refund={data:null};
                this.newShipp.textButton='Next';
                this.newShipp.enterLivruers.newStatus='';
                this.newShipp.enterLivruers.comment=""
                this.newShipp.enterLivruers.data=null;
                if(watch){
                   if(watch === 'watch_courier') this.newShipp.settings={sortie:false,entree:false};
                }else{
                    this.newShipp.courier=false;
                    this.newShipp.settings={sortie:false,entree:false};
                    this.newShipp.status=null;
                    this.newShipp.previous=false;
                    this.newShipp.dauchete=false;
                }
            },
           async OpenClosePopupModal(action,method='',previous=null){
                console.log('action------------',action)
                console.log('method______________',method)
                let modal_id = "ModalChangeStatus";
                if(this.config.name=="products") modal_id = "ModalChangeQte";
                if(method === "dauchette") modal_id = "ModalChangeStatusByDauchette";
                if(method === "Refund") modal_id = "Refund";
                if(action === 'open') {
                    if(method==="dauchette" || method==="Refund"){
                          this.$vm2.open(modal_id);
                         // this.newShipp.textButton='Confirm';
                          if(previous && previous === 'previous') {this.newShipp.previous=true;this.newShipp.dauchete=false;}
                          if(previous && previous === 'dauchete') {this.newShipp.dauchete=true;this.newShipp.previous=false;}
                    }else{
                          console.log('this.tabId______________',this.tabId.length)
                        if (this.tabId.length  > 0 ) {
                        //  this.$refs[modal_id].showModal()
                            this.$vm2.open(modal_id);
                            if(this.config.name=="products" && modal_id==="ModalChangeQte"){
                                   const resW = await this.$server.search("warehouses", { country: this.idWharhouse.country,all:true,limit:10}); 
                                    console.log('resW--------------',resW)
                                     console.log('this.idWharhouse--------------',this.idWharhouse)
                                    if(resW.content && resW.content.results) {
                                        this.checkWarehousingParent=resW.content.results.sort((a,b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0));
                                        this.checkWarehousingParent.forEach(x => { x.fullOf=false; });
                                    }else this.checkWarehousingParent=[];

                                console.log('this.selectProduct--------------',this.selectProduct.seller)
                               // this.checkSetting=this.getElementInArray(this.selectProduct.seller.settings,this.idWharhouse._id);
                                 //console.log('this.checkSetting--------------',this.checkSetting)
                                 if(this.checkWarehousingParent.length>1) this.expeditionData.warhouseParent.exist=true;
                                // if(!this.expeditionData.setting.exist) this.expeditionData.dataWhrahouseSetting=this.checkSetting;
                            }
                        }else alert(`No ${this.config.name} selected !`, "warning");
                    }
                }else {
                    this.emtyDataShipp();
                    this.$vm2.close(modal_id);
                }
            },
            OpenClosePopupModalReporte(action,method,idShipp,index){
                 this.postponed._id=idShipp;
                 this.postponed.index=index;
                if(method=="postponed"){
                    let modal_id = "ModalReporteShipp";
                    if(action === 'open') {
                        this.$vm2.open(modal_id);
                        console.log('this.postponed__________________________________',this.postponed)
                    } else  {this.$vm2.close(modal_id);   this.postponed._id=null;}
                }else{
                    //unrached shipping
                   this.$confirm(this.$t('confirm_unreachable_shipping')).then(async (res) => {
                    if (res) {
                         this.loadingcreateShipp=true;
                        const nDate = new Date().toLocaleString('en-US', {timeZone: 'Africa/Casablanca'});
                        let newdate=new Date(nDate);
                        this.postponed.date=newdate.setDate(newdate.getDate()+1);
                        this.postponed.status="unreachable";
                        const data_ship = await this.$server.update("shippings", this.postponed);
                        this.loadingcreateShipp=false;
                            if (data_ship && data_ship._id) {
                                this.postponed.date=null;
                                this.postponed.comment=undefined;
                                 this.postponed.status=undefined;
                                this.closePopUpInformation(this.postponed.index);
                                alert(this.$t('shippings_updated'), "success"); 
                            } else alert(data_ship, "warning");  
                    }
                    });  
                }    
            },
            async ShowPopUpInformation(index,status=null,data=null) {
               
                 if(this.config.name=="statusHistories") return false;
                if(this.config.name=="shippings"){
                      if(data.country=='MA') await this.getFeesShippBycity(data.order.customer.city);
                     this.CheckQteDefecteuse(data);
                     this.CheckQteReturned(data);
                     if(this.currentUser.type=='Courier' && (status=="delivered" || status=="cancelled" || status=="processed")){
                        return false;
                     }
                    if(this.currentUser.type=='StockManager' && (status=="delivered" || status=="shipped"  || status=="processed")){
                       return false;
                     }
                    if(this.currentUser.type=='Accountant' && data.type=='normal'){
                          return false;
                    }
                     if(this.currentUser.type=='Seller'){
                          return false;
                    }
                }
                                if(this.config.name=='sourcings' && this.currentUser.type==='Seller') return false;


               if(this.config.name=='expeditions'){
                  if(this.currentUser.type!='Admin' && this.currentUser.type!='CEO' && this.currentUser.type!='Courier' && this.currentUser.type!='StockManager'){
                    if(this.currentUser.type=='Seller')  return false;
                    else{
                        if(!this.getAccessUser('expeditions', 'update'))  return false;
                     }
                  }

                  this.CheckQteSentExist(data);
               } 
               if(this.config.name=='orders'){
                    if(data.customer.country=='MA') await this.getFeesShippBycity(data.customer.city);
                    if(this.currentUser.type!='CallcenterManager' && this.currentUser.type!='Admin' && this.currentUser.type!='CEO' && this.currentUser.type!='TeleConsultant' && this.currentUser.type!='NRP'  && this.currentUser.type!='Accountant'){
                        if(this.currentUser.type === 'Seller')  return false;
                        else{
                            if(!this.getAccessUser('orders', 'update'))  return false;
                        }
                     }
                 // this.CheckQteDefecteuseOrder(data);
                  await this.CheckDataShip(data);  
                 
               }      
                // await this.assign(index);
                let modal_id = "modal1_" + index;
               
              //  this.$refs[modal_id][0].showModal();
                this.$vm2.open(modal_id);
            },
            getAccessUser(modelToCheck, accessToCheck) {
               if(this.currentUser && this.currentUser.role){
                   const permissions = this.currentUser.role.permissions;
                    for (let i in permissions) {
                        let model = permissions[i]["model"];
                        let access = permissions[i]["access"];

                        if (model == modelToCheck) {
                        return access[accessToCheck];
                        }
                    }
               }else return false
                
            },
            async confirmChangeExpedition(){
                if(this.expeditionData.setting.exist && !this.expeditionData.setting.confirmation && !this.expeditionData.setting.fulfillement && !this.expeditionData.setting.shipping){
                    alert("Les services est obligatoir", "warning");
                    return false;
                }
                if(this.expeditionData.warhouseParent.exist){
                    //check warehouse if select
                    for (let index = 0; index < this.expeditionData.data.details.length; index++) {
                        const expeditionLine = this.expeditionData.data.details[index];
                        if (!expeditionLine.warehouseParent) {
                            alert(`Warehouse est obligatoir pour ce produit : ${expeditionLine.product.name}`, "warning");
                            return false;
                            break;
                        }else expeditionLine.warehouseParentName=expeditionLine.warehouseParent.name||expeditionLine.warehouseParent;
                    }
                }
                console.log('dta--',this.expeditionData.data.details)
                // return false;
                this.expeditionData.loading=true;
                const data = await this.$server.update("expeditions",this.expeditionData);
                this.expeditionData.loading=false;
                if(data && data._id) {
                    this.checkSetting=null;
                    this.expeditionData.setting={exist:false,confirmation:false,fulfillement:false,shipping:false,warehouse:null},
                    this.expeditionData.warhouseParent={exist:false,warehouse:null},
                    this.$vm2.close('ModalChangeSetSettingsSeller');
                    this.closePopUpInformation(this.expeditionData.model);
                    alert("expeditions updated", "success");
                }else  alert(data, "warning");
            },
            async ConfirmOperationUpdate(dataToEdit, index) {
                if(this.config.name=="shippings"){
                    this.$confirm(`Are you sure to update this shipping (status : ${dataToEdit.status})`).then(async (res) => {
                            if (res) {
                                 dataToEdit.order.onlyCommentedit=true;
                                this.loadingcreateShipp=true; 
                                const  dataorderedit = await this.$server.update('orders', dataToEdit.order);
                                delete  dataToEdit.order.onlyCommentedit;
                                const  data = await this.$server.update(this.config.name, dataToEdit);
                                this.loadingcreateShipp=false;
                                console.log('data___k_________________',data)
                                if (data && data._id) {
                                        this.closePopUpInformation(index);
                                        alert(this.config.title+" updated", "success");
                                }else alert(data, "warning");  
                            }
                        });

                  
                }else if(this.config.name=="orders" && dataToEdit.status.name && dataToEdit.status.name=="Confirmed"){

                    
                     this.loadingcreateShipp=true;
                     
                     if(this.shipping._id){
                         const data_shi = await this.$server.update("shippings", this.shipping);
                         const data_order = await this.$server.update("orders", dataToEdit);
                         this.loadingcreateShipp=false;
                        if (data_shi && data_shi._id) {
                            this.closePopUpInformation(index);
                            alert(this.$t('shipping_order_updated'), "success");                            
                        }else alert(data_shi, "warning"); 
                     }else{     
                        if (this.checkDataShipping()) {
                            this.shipping.seller=dataToEdit.seller._id;
                            this.shipping.country=dataToEdit.customer.country;
                            this.shipping.totalPrice=dataToEdit.totalPrice;
                            const checkDataShipp = await this.$server.get("orders", {id: dataToEdit._id});
                            console.log('checkDataShipp----------------',checkDataShipp)
                            // if(checkDataShipp.content.teleConsultant && (checkDataShipp.content.status.name == "Pending" || checkDataShipp.content.status.name == "OutOfStock")){
                            //       this.loadingcreateShipp=false;
                            //       alert("Shipping is already being processed in the call center !!", "warning");
                            //       return false;
                            // }
                            if(checkDataShipp.content.status.name == "Confirmed"){
                                this.loadingcreateShipp=false;
                                 alert("Shipping already created for this order !!", "warning");
                                 await this.refresh();
                                 return false;
                            }

                            if(dataToEdit.sous_warehouses_orders && dataToEdit.sous_warehouses_orders.length>0 && !dataToEdit.zone){
                                this.loadingcreateShipp=false;
                                alert('Ville de stockage required', "warning");
                                return false
                            }

                            if(dataToEdit.sous_warehouses_orders && dataToEdit.sous_warehouses_orders.length>0 && dataToEdit.zone){
                                for (let index = 0; index < dataToEdit.details.length; index++) {
                                    const orderLine = dataToEdit.details[index];
                                    orderLine.warehouseParent=dataToEdit.zone._id;
                                    orderLine.warehouseParentName=dataToEdit.zone.name;
                                }
                            }

                            
                             dataToEdit['shipping']=this.shipping;
                             dataToEdit.creatShipInAdmin=true;
                             dataToEdit.Confirmation=true;
                             const data_order = await this.$server.update("orders", dataToEdit);
                             console.log('data_order----------------',data_order)
                             this.loadingcreateShipp=false;
                           if (data_order && data_order._id) {
                                 alert(this.$t('shipping_created'), "success");
                                this.closePopUpInformation(index);
                            } else alert(data_order, "warning");
                        } else {
                            alert(this.$t('all_required_field'), "warning");
                        }
                    }  
                   this.loadingcreateShipp=false;
                }else{
                    if(this.config.name=="orders" && dataToEdit.status.name && dataToEdit.status.name=="Cancelled" && !dataToEdit.comment){
                        alert(`Raison d'annulation (Comment) requise !`, "warning");
                        return false;
                    }
                    if(this.config.name=="expeditions"){
                            this.loadingcreateShipp=true;
                            const resW = await this.$server.search("warehouses", { country: dataToEdit.country_to,all:true,limit:10}); 
                            if(resW.content && resW.content.results) {
                                this.checkWarehousingParent=resW.content.results.sort((a,b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0));
                                this.checkWarehousingParent.forEach(x => { x.fullOf=false; });
                            }else this.checkWarehousingParent=[];
    
                            this.checkSetting=this.getElementInArray(dataToEdit.seller.settings,this.idWharhouse._id)

                            if(dataToEdit.status=='received' && (this.checkWarehousingParent.length>1 || !this.checkSetting || (this.checkSetting && !this.checkSetting.confirmation && !this.checkSetting.fulfillement && !this.checkSetting.shipping))){
                                if(!this.checkSetting || (this.checkSetting && !this.checkSetting.confirmation && !this.checkSetting.fulfillement && !this.checkSetting.shipping)) this.expeditionData.setting.exist=true;
                                if(this.checkWarehousingParent.length>1) this.expeditionData.warhouseParent.exist=true;
                                this.expeditionData.country=this.$f.getCountryNameByCode(this.dataCountry,dataToEdit.country_to);
                                this.expeditionData.data=dataToEdit;
                                this.expeditionData.model=index;
                                this.$vm2.open('ModalChangeSetSettingsSeller');
                                let exist=false;
                                dataToEdit.details.forEach(function (element) {
                                    if(element.warehouseParentName && element.warehouseParent && !element.warehouseParent._id){
                                        exist=true;
                                        element.warehouseParent={_id:element.warehouseParent,name:element.warehouseParentName}
                                    }
                                });
                                if(exist){
                                    const indexPP = this.data.findIndex(expedition => expedition._id.toString() ===  dataToEdit._id.toString());
                                    if(indexPP != -1) this.data[indexPP].details = dataToEdit.details;
                                }
                                
                                this.loadingcreateShipp=false;
                                return false
                            }    
                        }           

                    const data = await this.$server.update(this.config.name, dataToEdit);
                     console.log('data jjjjj esxped-------',data)
                    this.loadingcreateShipp=false;
                     if (data && data._id) {
                        this.closePopUpInformation(index);
                        alert(this.config.title+" updated", "success");
                    }else alert(data, "warning");
                }
            },
            async closePopUpInformation(index) {
               let modal_id = "modal1_" + index;
               this.$vm2.close(modal_id);
               await this.refresh();
            },
           async OrderChange(index,order){
             let modal_id = "modal1_" + index;
           //  this.$refs[modal_id][0].close();
             this.$vm2.close(modal_id);
              const DataShipp = await this.$server.get("shippings", {
                  order: order._id,
                  type:'normal'
                });
             if(DataShipp.content._id){     
               //  console.log('this___________________',DataShipp)     
               const nDate = new Date().toLocaleString('en-US', {timeZone: 'Africa/Casablanca'});
               let newdate=new Date(nDate);
                    this.shippingChange.address= DataShipp.content.address;
                    this.shippingChange.order= order._id;
                    this.shippingChange.totalPrice= order.totalPrice;
                    this.shippingChange.courier= DataShipp.content.courier._id;
                    this.shippingChange.zone= DataShipp.content.zone._id;
                    this.shippingChange.seller= DataShipp.content.seller;
                    this.shippingChange.date= newdate;
                    this.shippingChange.country= order.customer.country;
                    this.$confirm(this.$t('confirm_craete_shipping_change')).then(async (res) => {
                            if (res) {
                                const data_ship = await this.$server.create("shippings", this.shippingChange);
                                    if (data_ship._id) {
                                        this.$router.push('/shippings')
                                        alert(this.$t('shipping_created'), "success");
                                    } 
                            }
                        });
             }else{
                  alert(this.$t('no_shipping'), "error");
                 }
            },
            async getCategories() {
                const res = await this.$server.search("categories");
                if (res.content) this.categories = res.content.results;
                else this.categories = [];
            },
            async getRoles() {
                const res = await this.$server.search("roles");
                if (res.content) this.roles = res.content.results;
                else this.roles = [];
            },
            async remove(id) {
                this.$confirm(this.$t('you_sure')).then(async (res) => {
                    if (res) {
                        const data = await this.$server.delete(this.config.name, { _id: id });
                        console.log('data__________________________',data)
                        if(data && this.config.name=='orders'){
                           // await this.$server.deleteMany('statusHistories', { order: id });
                            //const res= await this.$server.delete('shippings', { order: id });
                          //  if(res) await this.$server.deleteMany('shippingsHistories', { shipping: res._id });
                        }
                        if(data && this.config.name=='shippings'){
                            // await this.$server.deleteMany('shippingsHistories', { shipping: id });
                             //await this.$server.delete('orders', { _id: data.order });
                             //await this.$server.deleteMany('statusHistories', { order: data.order });
                        }
                        if (data && data._id) {
                            alert(`${this.name} deleted !`, "success");
                            await this.refreshChild();
                        }else alert(data, "warning");
                    }
                });
            },
            async refreshChild() {
                await this.refresh();
            },
            getAccessLinks(action){
                const modelToCheck=this.config.name;
                const user=this.currentUser.type;
                if(modelToCheck=="shippings" && user=="Seller") return false
                if(modelToCheck=="products" && ['TeleConsultant', 'StockManager','NRP','CancelledOrder','ToRemind','Seller','Administrateur'].indexOf(user) != -1) return false
                if(modelToCheck=="products" && user=="Seller" && action=="addqte") return false
                if(modelToCheck=="payments" && user=="Seller" && action=="addPay") return false
                if(modelToCheck=="orders" && user=="Seller") return false
                return true;
            },
            getAccess(accessToCheck,status=null,type=null,role=null,orderTraits,checkIsLink=null) {
                if(!this.currentUser.role) return false;
                const modelToCheck=this.config.name;
                const permissions = this.currentUser.role.permissions;
               // let productExist=false;
                 if(!role){        
                      return false;
                 }
                  
                 if((modelToCheck == "shippings" || modelToCheck == "payments") && accessToCheck=="print") return true;
                 if(modelToCheck == "products"  && ['TeleConsultant', 'StockManager','NRP','CancelledOrder','ToRemind'].indexOf(this.currentUser.type) != -1) return false;
                 if(modelToCheck == "orders" && accessToCheck === "double" && status && status.name && status.name==='double') return true;
                                  if(modelToCheck == "sourcings" && accessToCheck === "messages") return true;

             
                for (let i in permissions) {
                    let model = permissions[i]["model"];
                    let access = permissions[i]["access"];
                    if (model == modelToCheck) {
                        
                         if(modelToCheck=="expeditions" && (accessToCheck=="update" || accessToCheck=="delete") && (status=="received" || status=="cancelled")){
                             if(this.currentUser.type=='Admin' ||  this.currentUser.type=='CEO'){
                                return true;
                             }else{ 
                                return false;
                             }
                         }
                        if((modelToCheck=="shippings" && type !== null  &&  this.currentUser.type=='Accountant'  &&  accessToCheck=="update" && type=='normal')){
                            return false;
                          }
                        if((modelToCheck=="shippings" && status !== null && this.currentUser.type=='Accountant') &&  accessToCheck=="update" && (status.name=="Processed" || status.name=="OutOfStock" || status.name=="Cancelled" || status.name=="Skipped" || status.name=="Pending" || status.name=="Unreached")){
                               return false;
                         }
                         if((modelToCheck=="orders" && status !== null && this.currentUser.type=='Seller') && (accessToCheck=="update" || accessToCheck=="delete")){
                            if(status.name=="Pending" || status.name=="OutOfStock"){
                                if(orderTraits) return false;
                                else return true;
                            }else return false;
                         } 

                        if((modelToCheck=="shippings" && status !== null && this.currentUser.type=='Courier') &&  accessToCheck=="update" && (status=="delivered" || status=="cancelled" || status=="processed")){
                               return false;
                         }
                        //  if(modelToCheck=="shippings" && status !== null && this.currentUser.type=='StockManager' && accessToCheck=="update"){
                        //     if(status!="cancelled") return true;
                        //     else return false;
                            
                        //  }
                        return access[accessToCheck];
                    }
                }
            },
            checkNewSellerData() {
                if (
                    this.newSeller.role &&
                    this.newSeller.username &&
                    this.newSeller.password &&
                    this.newSeller.email &&
                    this.newSeller.company &&
                    this.newSeller.type &&
                    this.newSeller.category
                )
                    return true;
                else return false;
            },
            checkDataShipping() {
                if (this.shipping.address && this.shipping.order && this.shipping.date)
                    return true;
                 else return false;
            },
            QuantityTotalProductOrder(data){
                var qte=0;
                for (let i in data.order.details) {
                    qte+=data.order.details[i].quantity;
                }
                return qte;
            },
            QuantityTotalProductOrderOnly(data){
                var qte=0;
                for (let i in data) {
                    qte+=data[i].quantity;
                }
                return qte;
            },
            getTotalAmoutPayemnt(data){
                let total=0;
                for(let i in data){
                  total+=data[i].amount;
                }
                return total;
            },
            async generatePdf(id,content) {
                if(this.config.name!="payments"){
                    if(this.tabId.length==0){
                        alert(`No Shipping selected !`, "warning");
                        return false;
                    }
                    console.log('this.tabId----------',this.tabId)
                //   var printContents = '<table class="min-w-full" style="page-break-after: avoid; page-break-before: avoid;"><tbody class="bg-white">';
                //   if(this.tabId.length==1){
                //       let modalPr_id = "Modalprint_" + this.tabId[0];
                //       printContents += '<tr style="height: 51mm;" class="bg-white">';
                //       printContents += '<td style="width:50%;" class="px-3 py-1 align-top border border-black">'+document.getElementById(modalPr_id).innerHTML+'</td>';
                //       printContents += '<td style="width:50%;" class="px-3 py-1 align-top"></td></tr>';
                //   }else{
                //    for(let i in this.tabId){
                //        let modalPr_id = "Modalprint_" + this.tabId[i];
                //        if(i % 2 == 0){
                //             printContents += '<tr style="height: 51mm;" class="bg-white">';
                //        }
                //        printContents += '<td style="width:50%;" class="px-1 py-1 align-top border border-black">'+document.getElementById(modalPr_id).innerHTML+'</td>';
                //         if(i % 2 != 0){
                //             printContents += '</tr>';
                //        }
                //    }
                // }
                //     printContents +='</tbody></table>';
                 const idsNotprepared=this.tabId.filter(function(item) { return item.status != 'prepared' && item.status != 'to prepare' && item.status != 'shipped' });
                 if(idsNotprepared.length>0) {
                      alert(`Delivery not est seulement autorisé pour les shoppings to prepare ou prepared !`, "warning");
                    return false;
                 }
                   
                  this.loadingpdf=true;
                  var printContents = "";
                  var style="";
                  if(this.tabId.length>1) style="height: 101%;border: 1px solid white;page-break-after: avoid; page-break-before: avoid;";
                  else style="height: 99%;border: 1px solid white;page-break-after: avoid; page-break-before: avoid;";
                   for(let i in this.tabId){
                     let modalPr_id = "Modalprint_" + this.tabId[i].id;
                     printContents +='<div class="container shipp-print" style="height: 99%;border: 1px solid white;page-break-after: avoid; page-break-before: avoid;">';
                     printContents += document.getElementById(modalPr_id).innerHTML;
                     printContents +="</div>";
                   }
                   // console.log(window.location.pathname.split( '/' ))
                        var mywindow = window.open('', 'PRINT', 'height=400,width=600');
                        mywindow.document.write('<html lang="en"><head><meta charset="utf-8"><title>DELIVRY NOTE</title>');
                        mywindow.document.write('<style type="text/css">.justify-center{justify-content: center;}.barcode-img svg{height:100px}p{margin:0}table{border-collapse: collapse;}table th, table td {border:1px solid #000;}.text-right{text-align:right}.flex-1{flex:1 1 0%}.w-40{width:10rem}.font-medium{font-weight:500}.py-1{padding-top:.25rem;padding-bottom:.25rem}.inline-block{display:inline-block}.mb-3{margin-bottom:.75rem}.py-2{padding-top:.5rem;padding-bottom:.5rem}.ml-auto{margin-left:auto}.border-black{border: 1px solid black;}.border{border-width:1px}.whitespace-no-wrap{white-space:nowrap}.text-center{text-align:center}.w-1/4{width: 25%}.text-left{text-align:left}.px-2{padding-left:.5rem;padding-right:.5rem}.min-w-full{min-width:100%}.mb-5{margin-bottom:1.25rem}.px-1{padding-left:.25rem;padding-right:.25rem}.mx-auto{margin-left:auto;margin-right:auto}body{font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";font-size:1em}.justify-between{justify-content:space-between}.w-full{width:100%}.block{display:block}.flex{display:flex}.uppercase{text-transform: uppercase;}.mb-2{margin-bottom: 0.5rem;}.mb-3{margin-bottom: 0.75rem;}.font-bold{font-weight: 700;}.font-semibold{font-weight:600}.text-xl{font-size: 1.25rem;line-height: 1.75rem;}.size-m{font-size: 12px;font-weight: 500;}@media print {@page {size:  auto; margin: 0mm;}}</style>');
                        mywindow.document.write('</head><body style="margin:2mm">');
                        mywindow.document.write(printContents);
                        mywindow.document.write('</body></html>');
                        mywindow.document.close(); // necessary for IE >= 10
                        mywindow.focus(); // necessary for IE >= 10*/
                        this.loadingpdf=false;
                        setTimeout(function () {mywindow.print();mywindow.close();}, 500);
            
                        return false;
                    }else{
                        if(this.tabId.length==0){
                             alert(this.$t('no_payement_selected'), "warning");
                            return false;
                        }
                        const doc = new jsPDF('p', 'pt');
                        const fontSizes = {
                            TitleFontSize:14,
                            SubTitleFontSize:12,
                            NormalFontSize:10,
                            SmallFontSize:9
                        };
                        const lineSpacing = 12;
                        let items;
                        let expeditions;
                        for(let k in this.tabId){
                                const filter = {
                                    _id: this.tabId[k]
                                }
                            const  res = await this.$server.search("payments", filter);
                            if (res.content.results) {
                                 items=res.content.results[0].details;
                                 expeditions=res.content.results[0].expeditions;
                             }
                        
                        let expeditionsCount=0;
                        //let expeditions=content.expeditions;
                        for(let i in expeditions){
                            expeditionsCount++;
                        }
                        let data=[]; let dataExp=[];
                        let i = 1;
                        items.forEach(el =>{let obj = [
                            i,
                            el.order.id,  
                            el.order.customer.fullName,
                            el.order.totalPrice,
                            el.confirmation_vat,
                            el.shipping_vat,
                            el.shipping_refund_vat,
                            el.shipping_change_vat,
                            el.amount,
                        ];
                        i++;
                        data.push(obj);

                        
                        });
                        doc.autoTable({ 
                        headerStyles: {fillColor: [0, 0, 0]},
                        theme: 'grid',
                        head: [['#', 'ID',this.$t('customer'),this.$t('total_price'),this.$t('confirmation_fees'),this.$t('shipping_fees'),this.$t('refund_fees'),this.$t('change_fees'),this.$t('total')]],
                        margin: { top: 150 },
                        body: data,
                        })
                        //Expedition fees
                        if(expeditionsCount>0){
                        doc.setFont('normal');
                        doc.setFontSize(fontSizes.SubTitleFontSize);
                        doc.text('Expeditions: ', 40,doc.autoTable.previous.finalY+15);
                        let j = 1;
                        expeditions.forEach(el =>{let obj = [
                            j,
                            el.id.numberOfPackages,  
                            el.id.transportMode,
                            el.id.details.length+' '+this.$t('products'),
                            el.id.carrier.name,
                            el.id.country,
                            el.fees,
                        ];
                        j++;
                        dataExp.push(obj);
                        });
                        doc.autoTable({ 
                    // headerStyles: {fillColor: [0, 0, 0]},
                        theme: 'striped',
                        head: [['#', this.$t('nb_packages'), this.$t('transport_mode'),this.$t('details'),this.$t('carrier'),this.$t('country'),this.$t('expedition_fees')]],
                        margin: { top: doc.autoTable.previous.finalY+30 },
                        body: dataExp,
                        })
                        }
                        let startX = 40;
                        const pageWidth = doc.internal.pageSize.width;
                        const endX =  pageWidth - startX;
                        doc.setFont("arial")
                        doc.setFontSize(fontSizes.SubTitleFontSize);

                        // set fix value for Y to bring title in alignment with folding marks
                        let startY = 50;
                        doc.text(this.$t('payement_no')+' ', startX, startY);

                        doc.setFont("bold");

                        startX += doc.getStringUnitWidth('Payement NO: ') * fontSizes.SubTitleFontSize;
                        doc.text(res.content.results[0].id, startX, startY);

                        doc.setFont('normal');
                        doc.text(this.$moment(res.content.results[0].date).format('yyyy-MM-DD'), endX, startY, 'right');
                        startY = 33;
                        startY += lineSpacing * 2;
                        startX = 40;
                        doc.text(this.$t('seller')+': '+res.content.results[0].seller.email, startX, startY += lineSpacing + 2);
                        doc.text(this.$t('payement_methode')+': '+res.content.results[0].method, startX, startY += lineSpacing * 2);

                        //total amount orders 
                        startY += lineSpacing * 2;
                        doc.text(this.$t('total')+': '+this.getTotalAmoutPayemnt(res.content.results[0].details), endX, doc.autoTable.previous.finalY+20, 'right');
                        doc.text(this.$t('tva')+': '+res.content.results[0].vat+" %", endX, doc.autoTable.previous.finalY+40, 'right');
                        doc.text(this.$t('transfert_fees')+': '+res.content.results[0].transfert_fees, endX, doc.autoTable.previous.finalY+60, 'right');
                        doc.text(this.$t('grand_total')+': '+res.content.results[0].amount, endX, doc.autoTable.previous.finalY+80, 'right');
                        doc.setLineWidth(0.5);
                        startY += 4;
                        doc.line(startX - 1, startY, endX + 1, startY);
                        startY += 2;
                        doc.line(startX -2 , startY, endX + 2, startY);
                        startY += 20;
                        doc.text(this.$t('orders')+': ', startX, startY);
                        // doc.line(450 , doc.autoTable.previous.finalY+30, endX + 2, doc.autoTable.previous.finalY+28);
                        doc.addPage();
                    }
                    //delte black page
                      var pageCount = doc.internal.getNumberOfPages();
                      doc.deletePage(pageCount)
                        doc.save('Payement.pdf')

                    }              
            }
            },
    };
</script>

<!--  ************  -->
<style>
.invoice-table .form-control{
    border: 1px solid #616364 !important;
}
.prime{
    background-color: rgb(147 234 107 / 13%);
}
.deductions{
    background-color: rgb(234 107 107 / 13%);
}
.app-theme .btn {
    font-size: 14px;
}
.w-100px{
  width: 90px;
}
.w-130px{
    width: 130px;  
}
.w-160px{
    max-width: 160px;  
}
.parent-warehouse{
    border-left: 1px solid #000;
    padding-left: 20px;
    position: relative;
    margin-left: 18px;
    line-height: 24px;
}
.primary-warehouse::before{
    height: 1px;
    background: #000;
    width: 15px;
    left: 0;
    top: 11px;
    display: inline-block;
    content: "";
    position: absolute;
}
.ware-name{
    color: #fff;
    background-color: #ff5b2d;
    border-color: #ff5b2d;
    box-shadow: 0 5px 10px rgb(0 184 217 / 30%);
}
.dropdown-limit{
    border: 1px solid rgba(0,0,0,.1);
    position: relative;
}
.label-accept {
    background: #e22ae6;
}
.label-paid {
    background: #c2a81b;
}
.jvectormap-tip {
    padding: 3px 6px 3px;
    font-size: 10px;
    border-radius: 50px;
    text-transform: capitalize;
    font-weight: 400;
    display: block;
    margin-top: 4px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}
.list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}
.mb-20{
  margin-bottom: 5.7rem;
}
.mb-16{
  margin-bottom: 4rem;
}

.vs-dropdown--limit--after {
    top: 0;
    right: 10px;
    position: absolute;
    width: 10px;
    height: 10px;
    display: block;
    background: #fff;
    transform: rotate( 
45deg
 ) translate(-7px);
    border-top: 1px solid rgba(0,0,0,.1);
    border-left: 1px solid rgba(0,0,0,.1);
    z-index: 10;
    box-sizing: border-box;
}

.prova:after {
  content: attr(data-ribbon);
  position: absolute;
  width: 90px;
  height: 40px;
  background: #428bca;
  color: white;
  top: -5px;
  text-align: center ;
  line-height: 50px;
  right: -34px;
  transform: rotate(45deg);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #63b3ed;
}
.empty-row{
   background: repeating-linear-gradient( 53deg , #bfc0c2 4%, #fcfcff 11px 6%, #ffffff 10px 7%, #9d9c9c 15px);
}
.dropdown-action{
    width: 30%  !important;
}
.border-teal {
    border-color: #4dc0b5;
}
.bg-teal-lightest {
    background-color: #e8fffe;
}
    .text-red {
        color: #e3342f;
    }
    i.tiny {
        font-size: 12px;
        line-height: 20px;
    }
    i.small {
        font-size: 2rem;
    }
    .table-shipp,.table-shipp  thead{
        border: 2px solid #2c84c7;
    }
     
    .bg-light-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(186,230,253,var(--tw-bg-opacity));
    border-bottom: 1px solid #fff;
    }
    #loading{
        position: relative;
    }
    dialog[open] {
        animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
    }
    dialog::backdrop {
        background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
        backdrop-filter: blur(3px);
    }
    @keyframes appear {
        from {
            opacity: 0;
            transform: translateX(-3rem);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
</style>
